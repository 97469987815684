import React, { useEffect, useState, useMemo } from 'react';
import InputMask from "react-input-mask";
import Functions from './Functions'
import { toast } from 'react-toastify';
import Api from '../services/Api'
import camera from '../assets/icons/camera.svg';

const ListItemUser = (props) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [formState, setFormState] = useState({
    _id: props._id,
    name: props.name,
    birthDate: props.birthDate,
    thumbnail: props.thumbnail,
    phone: props.phone,
    cpf: props.cpf,
    email: props.email,
    address: props.address,
    adminBusiness: props.adminBusiness,
    active: props.active
  });

  // Executa assim q a página é aberta;
  useEffect(() => {
    console.log(props);
  }, []);

  // useMemo: executa quando determinado estado for alterado, neste caso o estado 'formState.thumbnail'
  /*const preview = useMemo(() => {
    if(props.thumbnail !== "" && props.thumbnail === formState.thumbnail){
      return props.thumbnail;
    } else {
      return formState.thumbnail ? URL.createObjectURL(formState.thumbnail) : null;
    }
  }, [formState.thumbnail]);*/
  
  function onChangeThumbnailFile(e){
    if(e.target.files[0]){
      let file_size = e.target.files[0].size;
      //let file_name = e.target.files[0].name;
      //let file_type = e.target.files[0].type;
      //console.log('executou o file change: ' +  file_size);
      //Se maior que um 1mb(1048576)
      if(parseInt(file_size) >= 5240000) {
        toast.error("Imagem não suportada! A imagem deve ter no máximo 5MB", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }else{
        setStateThumbnail(e.target.files[0])
      }
    }
  };

  async function handleSubmit(e){
    e.preventDefault();
    if(formState.name !== ""){
      props.setLoading(true);
      var fileThumbnail = formState.thumbnail;
      //Cria um estrutura Multiform Data;
      const data = new FormData();
      data.append('id', props._id);
      data.append('name', formState.name);
      data.append('phone', formState.phone);
      data.append('cpf', formState.cpf);
      data.append('email', formState.email);
      if(props.view === "DashboardUser"){
        data.append('business', props.business._id);
        data.append('adminBusiness', formState.adminBusiness);
      }
      //data.append('birthDate', formState.birthDate);
      //data.append('address', JSON.stringify(formState.address));
      /*if(typeof fileThumbnail === "object" && props.thumbnail !== fileThumbnail.name){
        data.append('thumbnail', props.thumbnail);
        data.append('file', fileThumbnail);
      }*/
      //data.append('active', props.active);
      try {
        const response = await Api.post("/update-user", data);
        console.log(response);
        if(response.hasOwnProperty('_id') || response.hasOwnProperty('ok')){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          if(props.view === "editUser"){
            props.setEditUser(false);
          } else {
            setToggleItem([]);
          }
          const reloadItem = props.loadItem;
          reloadItem();
          props.setLoading(false);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          props.setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("(*) Preencha todos os campos obrigatórios!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }
  
  return (
      <li className={"itemDashboardList dashboardEditUser"} style={{listStyle: 'none'}}>
        {props.view === "DashboardUser" ?
          <div className="row v-center headList" onClick={() => {toggleItem[0] !== props._id ? setToggleItem([props._id]) : setToggleItem([])}}>
            <div className="col-10">
              <span>{formState.name}</span>
            </div>
            <div className="col-2 text-right">
              <span className={`iconItem ${toggleItem[0] === props._id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
            </div>
          </div>
        : <></>}
        {props.view !== "DashboardUser" || toggleItem[0] === props._id ?
          <>
            <hr/>
            <form onSubmit={handleSubmit}>
              <div className="row v-center">
                <div className="col-12">
                  <div className="row v-center">
                    {/*<div className="form-group col-md-4 col-12">
                      <label className="switch itemDashboardListSwitch">
                        <input type="checkbox" value={stateActive} checked={stateActive} onChange={() => setStateActive(!stateActive)} />
                        <div className="slprops.ider"></div>
                      </label>
                      <label className="labelImage">Imagem do Canal:</label>
                      <label id="thumbnailUser" style={{ backgroundImage: `url("${preview}")` }} className={formState.thumbnail ? 'has-thumbnail' : ''}>
                        <input type="file" onChange={onChangeThumbnailFile} />
                        <img src={camera} alt="Select img" />
                      </label>
                    </div>
                    */}
                    <div className="form-group col-md-6 col-12">
                      <label className="labelInput">Nome: *</label>
                      <input
                        required 
                        type="text" 
                        className="form-control" 
                        placeholder="Seu nome"
                        value={formState.name}
                        onChange={event => setFormState({...formState, name: event.target.value}) }
                      />
                    </div>
                    <div className="form-group col-md-6 col-12">
                      <label className="labelInput">Email: *</label>
                      <input
                        required 
                        disabled={props.view === "editUser" || props._id.toString().match(/^[0-9a-fA-F]{24}$/) ? "disabled" : ""} 
                        type="text" 
                        className="form-control" 
                        placeholder="Email de contato"
                        value={formState.email}
                        onChange={event => setFormState({...formState, email: event.target.value}) }
                      />
                    </div>
                    <div className="form-group col-md-6 col-6">
                      <label className="labelInput">CPF: </label>
                      <InputMask
                        mask="999.999.999-99" 
                        type="text" 
                        className="form-control" 
                        placeholder="CPF"
                        //value={Functions.cpfMask(formState.cpf)}
                        value={formState.cpf}
                        onChange={event => setFormState({...formState, cpf: event.target.value}) }
                      />
                    </div>
                    <div className="form-group col-md-6 col-6">
                      <label className="labelInput">WhatsApp: </label>
                      <InputMask 
                        mask="(99) 99999-9999" 
                        className="form-control" 
                        placeholder="Número de Telefone"
                        value={formState.phone}
                        onChange={event => setFormState({...formState, phone: event.target.value}) }
                      />
                    </div>
                    {props.view === "DashboardUser" ? 
                      <div className="form-group col-12">
                        <label className="labelInput">Usuário Administrador: </label>
                        <select className="form-control" value={formState.adminBusiness} onChange={event => setFormState({...formState, adminBusiness: event.target.value })} >
                          <option value={false}>Não</option>
                          <option value={true}>Sim</option>
                        </select>
                        {!props._id.toString().match(/^[0-9a-fA-F]{24}$/) ?
                          <span style={{fontSize: 14, color: "var(--colorText)"}}>* A senha de acesso é o próprio endereço de e-mail!</span> 
                        : <></>}
                      </div>
                    : <></> }
                    {/*<div className="form-group col-md-4 col-12">
                      <label className="labelInput">Data Nascimento: </label>
                      <InputMask
                        mask="99/99/9999" 
                        type="text" 
                        className="form-control" 
                        placeholder="Data de nascimento"
                        value={formState.birthDate}
                        onChange={event => setFormState({...formState, birthDate: event.target.value}) }
                      />
                    </div>
                    <div className="form-group col-md-8 col-8">
                      <label className="labelInput">Logradouro: </label>
                      <input
                        type="text" 
                        className="form-control" 
                        placeholder="Rua, avenida..."
                        value={formState.address.street}
                        onChange={event => setFormState({...formState, address: {...formState.address, street: event.target.value} }) }
                      />
                    </div>
                    <div className="form-group col-md-4 col-4">
                      <label className="labelInput">Número: </label>
                      <input
                        type="number" 
                        className="form-control" 
                        placeholder="Número"
                        value={formState.address.number}
                        onChange={event => setFormState({...formState, address: {...formState.address, number: event.target.value} }) }
                      />
                    </div>
                    <div className="form-group col-md-6 col-12">
                      <label className="labelInput">Bairro: </label>
                      <input
                        type="text" 
                        className="form-control" 
                        placeholder="Bairro"
                        value={formState.address.district}
                        onChange={event => setFormState({...formState, address: {...formState.address, district: event.target.value} }) }
                      />
                    </div>
                    <div className="form-group col-md-6 col-12">
                      <label className="labelInput">Complemento: </label>
                      <input
                        type="text"
                        className="form-control" 
                        placeholder="Ap, ponto de referência..."
                        value={formState.address.complement}
                        onChange={event => setFormState({...formState, address: {...formState.address, complement: event.target.value} }) }
                      />
                    </div>
                    <div className="form-group col-md-6 col-12">
                      <label className="labelInput">Cidade: </label>
                      <input
                        type="text" 
                        className="form-control" 
                        placeholder="Cidade"
                        value={formState.address.city}
                        onChange={event => setFormState({...formState, address: {...formState.address, city: event.target.value} }) }
                      />
                    </div>
                    <div className="form-group col-md-6 col-12">
                      <label className="labelInput">Estado: </label>
                      <select className="form-control" disabled={props.canEditUser ? "" : "disabled"} value={formState.address.state} onChange={event => setFormState({...formState, address: {...formState.address, state: event.target.value} })} >
                        {Functions.estadosBR.map((estado) => (
                          <option key={estado.uf} value={estado.uf}>{estado.nome}</option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group col-md-12 col-12">
                      <label className="labelInput">Meu Plano*</label>
                      <select className="form-control" value={JSON.stringify(statePlan)} style={{padding:'25px 15px 15px 10px'}} onChange={event => setStatePlan(JSON.parse(event.target.value))} disabled={transactionOpen ? "disabled" : ""}>
                        {plans.map((item, index) => (
                          <option key={index} value={JSON.stringify(item)}>{item.name} - {item.percentage}%</option>
                        ))}
                      </select>
                    </div>
                    {/*<div className="col-12">
                      <div className="row">
                        <div className="form-group col-12">
                          <label className="labelInput">Observações: </label>
                          <textarea 
                            className="form-control" 
                            rows="3" 
                            value={formState.observations}
                            onChange={event => setFormState({...formState, observations: event.target.value}) }
                          />
                        </div>
                      </div>
                    </div>*/}
                  </div>     
                </div>     
                <div className="col-12 text-right">
                  {props.view === "editUser" ?  
                    <div className="col-12 text-right">
                      <hr />
                      <button type="button" className="btn btn-delete" onClick={() => props.setEditUser(false)}> Fechar </button>
                        <span>&nbsp;&nbsp;&nbsp;</span>
                      <button type="submit" className="btn btn-default"> Salvar Alterações</button>
                      <br />
                      <br />
                    </div>
                  :  
                    <div className="col-12 text-right">
                      {props.user !== props._id ? 
                        <button type="button" className="btn btn-delete" onClick={props.onDelete}> Excluir </button>
                      : <></> }
                      <span>&nbsp;&nbsp;&nbsp;</span>
                      <button type="submit" className="btn btn-default"> Salvar </button>
                    </div>
                  }
                </div>
              </div>
            </form>
          </>
        : '' }
      </li>
  );
};

export default ListItemUser;
