import React, { useEffect, useState } from 'react';
import Routes from './routes';
import './App.css';
import UserContext from './contexts/UserContext';
import Api from './services/Api';
import { useReactPWAInstall } from "react-pwa-install";
import logoApp from "./assets/logo192.png";
//import { fetchToken, onMessageListener } from './firebase';

function App() {
  const [user, setUser] = useState([]);
  const [modalInstallApp, setModalInstallApp] = useState(false);
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [isTokenFound, setTokenFound] = useState(false);
  //fetchToken(setTokenFound);

  useEffect(() => {
    const user_id = localStorage.getItem('user');
    if(user_id) {
      validaLogin(user_id);
    }
    console.log(location);
    if(location.pathname.split('/').length === 2 && location.pathname.split('/')[1] === 'dashboard'){
      setModalInstallApp(true);
    } else {
      setModalInstallApp(false);
    }
  }, []);

  async function validaLogin(user_id) {
    const response = await Api.getUser(user_id);
    if(response !== null){
      setUser([response]);
    }
  }

  /*onMessageListener().then(payload => {
    setNotification({title: payload.notification.title, body: payload.notification.body})
    setShow(true);
    console.log(payload);
  }).catch(err => console.log('failed: ', err));

  const onShowNotificationClicked = () => {
    setNotification({title: "Notification", body: "This is a test notification"})
    setShow(true);
  }*/

  //PWA Install button
  const installApp = () => {
    pwaInstall({
      title: "Instalar Aplicativo",
      logo: logoApp,
      //features: (),
      //description: "Instale o aplicativo e tenha o acesso a experiência completa!",
    })
    .then(() => alert("Operação realizada com sucesso!"))
    .catch(() => console.log("Instalação cancelada..."));
  };

  return (
    <UserContext.Provider 
      value={{
        user: user,
        setUser: (usr) => {
          console.log(usr);
          setUser(usr);
        },
        logout: () => {},
      }}
    >
      <Routes />
      {/*supported() && !isInstalled() && modalInstallApp && (
        <div className="divBtnInstallApp">
          <button className="btnCloseInstallApp" onClick={() => setModalInstallApp(false)}>✘</button>
          <div className="row v-center">
            <div className="col-6 col-md-8">
              <h5>Instalar o App?</h5>
              <p>Instale o app e desfrute da experiência completa!</p>
            </div>
            <div className="col-6 col-md-4 text-center">
              <button className="btn-small btn-default" onClick={installApp}>
                Instalar Aplicativo
              </button>
            </div>
          </div>
        </div>
      )*/}  
    </UserContext.Provider>
  );
}

export default App;
