import React, { useEffect, useState, useMemo } from 'react';
import InputMask from "react-input-mask";
import Functions, { sendNotification } from './Functions'
import { toast } from 'react-toastify';
import Api from '../services/Api'
import camera from '../assets/icons/camera.svg';

const ListItemBusiness = ({ id, user, item, name, description, thumbnail, CNPJ, phone, email, address, razaoSocial, maps, plan, plans, openingHours, schedulingTime, active, onDelete, loadItem, setEditBusiness, setLoading }) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [stateName, setStateName] = useState(name);
  const [stateDescription, setStateDescription] = useState(description);
  const [stateThumbnail, setStateThumbnail] = useState(thumbnail);
  const [statePhone, setStatePhone] = useState(phone);
  const [stateEmail, setStateEmail] = useState(email);
  const [stateAddress, setStateAddress] = useState(address);
  const [stateCNPJ, setStateCNPJ] = useState(CNPJ);
  const [stateRazaoSocial, setStateRazaoSocial] = useState(razaoSocial);
  const [stateOpeningHours, setStateOpeningHours] = useState(openingHours);
  const [stateSchedulingTime, setStateSchedulingTime] = useState(schedulingTime);
  const [stateActive, setStateActive] = useState(active);
  const [stateMaps, setStateMaps] = useState(maps);

  // Executa assim q a página é aberta;
  useEffect(() => {
    console.log(thumbnail);
  }, []);

  // useMemo: executa quando determinado estado for alterado, neste caso o estado 'stateThumbnail'
  const preview = useMemo(() => {
    if(thumbnail !== "" && thumbnail === stateThumbnail){
      return encodeURI(thumbnail);
    } else {
      return stateThumbnail ? URL.createObjectURL(stateThumbnail) : null;
    }
  }, [stateThumbnail]);

  const previewMapsIrisRight = useMemo(() => {
    let arrImages = [];
    Array.from(stateMaps.irisRight).map(itemImg => {
      if(maps.irisRight.length > 0 && maps.irisRight.indexOf(itemImg) >= 0){
        var url = Api.URL_FILES_BUSINESS + '/' + id + '/'+ encodeURI(itemImg);
        //console.log(url);
        arrImages.push(url);
      } else {
        if(typeof itemImg === "object"){
          console.log(itemImg);
          let obj = URL.createObjectURL(itemImg);
          arrImages.push(obj); 
        }
      }
    });
    console.log(arrImages);
    return arrImages;
  }, [stateMaps.irisRight]);

  const previewMapsIrisLeft = useMemo(() => {
    let arrImages = [];
    Array.from(stateMaps.irisLeft).map(itemImg => {
      if(maps.irisLeft.length > 0 && maps.irisLeft.indexOf(itemImg) >= 0){
        var url = Api.URL_FILES_BUSINESS + '/' + id + '/'+ encodeURI(itemImg);
        //console.log(url);
        arrImages.push(url);
      } else {
        if(typeof itemImg === "object"){
          let obj = URL.createObjectURL(itemImg);
          arrImages.push(obj); 
        }
      }
    });
    console.log(arrImages);
    return arrImages;
  }, [stateMaps.irisLeft]);
  
  function onChangeThumbnailFile(e){
    if(e.target.files[0]){
      let file_size = e.target.files[0].size;
      //let file_name = e.target.files[0].name;
      //let file_type = e.target.files[0].type;
      //console.log('executou o file change: ' +  file_size);
      //Se maior que um 1mb(1048576)
      if(parseInt(file_size) >= 5240000) {
        toast.error("Imagem não suportada! A imagem deve ter no máximo 5MB", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }else{
        setStateThumbnail(e.target.files[0])
      }
    }
  };

  function onChangeMapsRight(e){
    let arrImages = Array.from(stateMaps.irisRight);
    if(e.target.files.length > 0){    
      for(let i = 0; i < e.target.files.length; i++){
        if(parseInt(e.target.files[i].size) >= 5240000) {
          toast.error("A imagem " +e.target.files[i].name + " não pode ser enviada! A imagem deve ter no máximo 5MB", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else { 
          arrImages.push(e.target.files[i]);
          console.log(arrImages);
        }
      }
      setStateMaps({...stateMaps, irisRight: arrImages } );
    }
  };

  function onChangeMapsLeft(e){
    let arrImages = Array.from(stateMaps.irisLeft);
    if(e.target.files.length > 0){    
      for(let i = 0; i < e.target.files.length; i++){
        if(parseInt(e.target.files[i].size) >= 5240000) {
          toast.error("A imagem " +e.target.files[i].name + " não pode ser enviada! A imagem deve ter no máximo 5MB", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else { 
          arrImages.push(e.target.files[i]);
          console.log(arrImages);
        }
      }
      setStateMaps({...stateMaps, irisLeft: arrImages } );
    }
  };

  function deleteMapRight(index){
    var arrImages = Array.from(stateMaps.irisRight);
    arrImages.splice(index, 1)
    setStateMaps({...stateMaps, irisRight: arrImages });
  }

  function deleteMapLeft(index){
    var arrImages = Array.from(stateMaps.irisLeft);
    arrImages.splice(index, 1)
    setStateMaps({...stateMaps, irisLeft: arrImages });
  }

  function getTableOpeningHours(arr){
    const iterateItem = (item) => {
       return item.map(function (nextItem, index) {
         return (
            <tr key={nextItem.id}>
               <td>
                <input
                  type="time"
                  step="300"
                  style={{border: 0, paddingLeft: 15, cursor: "text"}}
                  name={"hour-"+nextItem.id}
                  value={nextItem.hour}
                  onChange={onChangeOpeningHours} 
                />
                </td>
                <td>
                  <input
                    type="checkbox"
                    name={"seg-"+nextItem.id}
                    checked={nextItem.seg}
                    onChange={onChangeOpeningHours} 
                    style={{cursor: "pointer"}}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    name={"ter-"+nextItem.id}
                    checked={nextItem.ter}
                    onChange={onChangeOpeningHours} 
                    style={{cursor: "pointer"}}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    name={"qua-"+nextItem.id}
                    checked={nextItem.qua}
                    onChange={onChangeOpeningHours} 
                    style={{cursor: "pointer"}}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    name={"qui-"+nextItem.id}
                    checked={nextItem.qui}
                    onChange={onChangeOpeningHours} 
                    style={{cursor: "pointer"}}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    name={"sex-"+nextItem.id}
                    checked={nextItem.sex}
                    onChange={onChangeOpeningHours} 
                    style={{cursor: "pointer"}}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    name={"sab-"+nextItem.id}
                    checked={nextItem.sab}
                    onChange={onChangeOpeningHours} 
                    style={{cursor: "pointer"}}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    name={"dom-"+nextItem.id}
                    checked={nextItem.dom}
                    onChange={onChangeOpeningHours}
                    style={{cursor: "pointer"}} 
                  />
                </td>
                <td>
                  <span className="deleteOpeningHours" style={{cursor: "pointer"}} onClick={() => deleteOpeningHours(index)}> <i className="fas fa-trash"> </i></span>
                </td>
            </tr>
         );
       })
    }
    return (
        <table className="tableOpeningHours">
          <thead>
            <tr>
              <th>Horário</th>
              <th>Segunda</th>
              <th>Terça</th>
              <th>Quarta</th>
              <th>Quinta</th>
              <th>Sexta</th>
              <th>Sábado</th>
              <th>Domingo</th>
              <th>Ações</th>
            </tr>
          </thead>
            <tbody>
              {iterateItem(arr)}
            </tbody>
        </table>
    );
  };

  function onChangeOpeningHours (event) {
    const { value, maxLength, name} = event.target;
    let arr = Array.from(stateOpeningHours);
    //var oldValue = name.split("-")[1].substr(0,5);
    var nameLabel = name.split("-")[0];
    let index = name.split("-")[1].substr(0,5); //arr.findIndex(x => x["hour"] === oldValue);
    if(index !== -1) {
      if(nameLabel === "hour"){
        arr[index].hour = value;
      } else if(nameLabel === "seg"){
        arr[index].seg = arr[index].seg ? false : true;
      } else if(nameLabel === "ter"){
        arr[index].ter = arr[index].ter ? false : true;
      } else if(nameLabel === "qua"){
        arr[index].qua = arr[index].qua ? false : true;
      } else if(nameLabel === "qui"){
        arr[index].qui = arr[index].qui ? false : true;
      } else if(nameLabel === "sex"){
        arr[index].sex = arr[index].sex ? false : true;
      } else if(nameLabel === "sab"){
        arr[index].sab = arr[index].sab ? false : true;
      } else if(nameLabel === "dom"){
        arr[index].dom = arr[index].dom ? false : true;
      }
    }
    console.log(arr);
    setStateOpeningHours([...arr]);
  };

  function addOpeningHours(){
    var arr = Array.from(stateOpeningHours);
    arr = [
      ...arr, 
      {id: arr.length, hour: "", seg: true, ter: true, qua: true, qui: true, sex: true, sab: false, dom: false}
    ]
    console.log(arr);
    setStateOpeningHours([...arr]);
  }

  function deleteOpeningHours(index){
    var arr = Array.from(stateOpeningHours);
    arr.splice(index, 1)
    setStateOpeningHours([...arr]);
  }

  async function handleSubmit(e){
    e.preventDefault();
    if(stateName !== ""){
      setLoading(true);
      var fileThumbnail = stateThumbnail;
      //Cria um estrutura Multiform Data;
      const data = new FormData();
      data.append('id', id);
      data.append('user', user);
      data.append('name', stateName);
      data.append('address', JSON.stringify(stateAddress));
      data.append('schedulingTime', parseInt(stateSchedulingTime));
      if(typeof fileThumbnail === "object" && thumbnail !== fileThumbnail.name){
        data.append('thumbnail', thumbnail);
        data.append('newThumbnail', fileThumbnail.name);
        data.append('files', fileThumbnail);
      }
      if(statePhone && statePhone !== "")
        data.append('phone', statePhone);
      if(stateEmail && stateEmail !== "")
        data.append('email', stateEmail);
      if(stateDescription && stateDescription !== "")
        data.append('description', stateDescription);
      if(stateCNPJ && stateCNPJ !== "")
        data.append('CNPJ', stateCNPJ);
      if(stateRazaoSocial && stateRazaoSocial !== "")
        data.append('razaoSocial', stateRazaoSocial);
      //ADD MAPAS
      var arrMaps = stateMaps, arrMapsRight = Array.from(stateMaps.irisRight), arrMapsLeft = Array.from(stateMaps.irisLeft);
      //Maps IRIS RIGHT
      console.log(arrMapsRight);
      arrMapsRight.length > 0 && arrMapsRight.map(function(image, index) {
        console.log(image);
        if(typeof image === "object"){
          if(index === 0) {
            arrMaps.irisRight = [];
          }
          if(arrMaps.irisRight.indexOf(image.name) === -1){
            //arrMaps.irisRight = [...arrMaps.irisRight, image.name];
            arrMaps.irisRight[index] = image.name;
            data.append('files', image);
          }
        } else {
          arrMaps.irisRight[index] = image;
        }
      });
      //Maps IRIS LEFT
      console.log(arrMapsLeft);
      arrMapsLeft.length > 0 && arrMapsLeft.map(function(image, index) {
        console.log(image);
        if(typeof image === "object"){
          if(index === 0) {
            arrMaps.irisLeft = [];
          }
          if(arrMaps.irisLeft.indexOf(image.name) === -1){
            arrMaps.irisLeft[index] = image.name;
            data.append('files', image);
          }
        } else {
          arrMaps.irisLeft[index] = image;
        }
      });
      console.log(arrMaps);
      data.append('maps', JSON.stringify(arrMaps));
      data.append('openingHours', JSON.stringify(stateOpeningHours));
      //data.append('oldMaps', JSON.stringify(maps)); // FOR DELETE
      data.append('active', stateActive);
      try {
        const response = await Api.post("/update-business", data);
        console.log(response);
        if(response.hasOwnProperty('_id')){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setToggleItem([]);
          const reloadItem = loadItem;
          reloadItem();
          setLoading(false);
          if(item === "editBusiness" || !id.toString().match(/^[0-9a-fA-F]{24}$/)){
            setEditBusiness(false);
          }
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("(*) Preencha todos os campos obrigatórios!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }
  
  return (
      <li className={item === "Business" ? toggleItem[0] === id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList": "dashboardEditBusiness"} >
        {item === "Business" ?
          <div className="row v-center headList" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>
            <div className="col-10">
              <span>{stateName}</span>
            </div>
            <div className="col-2 text-right">
              <span className={`iconItem ${toggleItem[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
            </div>
          </div>
        : <></>}
        {item === "editBusiness" || toggleItem[0] === id ?
          <>
            <hr/>
            <form onSubmit={handleSubmit}>
              <div className="row v-center">
                <div className="col-12">
                  <div className="row v-center">
                    <div className="form-group col-md-4 col-12">
                      <label className="labelImage">Logo da Clínica:</label>
                      <label id="thumbnailBusiness" style={{ backgroundImage: `url("${preview}")` }} className={stateThumbnail ? 'has-thumbnail' : ''}>
                        <input type="file" accept="image/*" onChange={onChangeThumbnailFile} />
                        <img src={camera} alt="Select img" />
                      </label>
                    </div>
                    <div className="col-md-8 col-12">
                      <div className="form-group">
                        <label className="labelInput">Nome da Clínica: *</label>
                        <input
                          //disabled={item === "Business" ? "" : "disabled"}
                          required 
                          type="text" 
                          className="form-control" 
                          placeholder="Informe o nome da sua clinica"
                          value={stateName}
                          onChange={event => setStateName(event.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label className="labelInput">CNPJ: </label>
                        <InputMask
                          mask="99.999.999/9999-99" 
                          type="text" 
                          className="form-control" 
                          placeholder="CNPJ da clínica"
                          value={stateCNPJ}
                          onChange={event => setStateCNPJ(event.target.value)}
                        />
                      </div>
                      <div className="row">
                        <div className="form-group col-md-9 col-12">
                          <label className="labelInput">Logradouro: </label>
                          <input
                            type="text" 
                            className="form-control" 
                            placeholder="Rua, avenida..."
                            value={stateAddress.street}
                            onChange={event => setStateAddress({...stateAddress, street: event.target.value}) }
                          />
                        </div>
                        <div className="form-group col-md-3 col-12">
                          <label className="labelInput">Número: </label>
                          <input
                            type="number" 
                            className="form-control" 
                            placeholder="Número"
                            value={stateAddress.number}
                            onChange={event => setStateAddress({...stateAddress, number: event.target.value}) }
                          />
                        </div>
                      </div>
                      
                      {/*<div className="form-group">
                        <label className="labelInput">Endereço: </label>
                        <input
                          type="text" 
                          className="form-control" 
                          placeholder="Endereço"
                          value={stateAddress}
                          onChange={event => setStateAddress(event.target.value)}
                        />
                      </div>*/}
                    </div>
                    <div className="form-group col-md-6 col-12">
                      <label className="labelInput">Bairro: </label>
                      <input
                        type="text" 
                        className="form-control" 
                        placeholder="Bairro"
                        value={stateAddress.district}
                        onChange={event => setStateAddress({...stateAddress, district: event.target.value}) }
                      />
                    </div>
                    <div className="form-group col-md-6 col-12">
                      <label className="labelInput">Complemento: </label>
                      <input
                        type="text"
                        className="form-control" 
                        placeholder="Ap, ponto de referência..."
                        value={stateAddress.complement}
                        onChange={event => setStateAddress({...stateAddress, complement: event.target.value}) }
                      />
                    </div>
                    <div className="form-group col-md-6 col-12">
                      <label className="labelInput">Cidade: </label>
                      <input
                        type="text" 
                        className="form-control" 
                        placeholder="Cidade"
                        value={stateAddress.city}
                        onChange={event => setStateAddress({...stateAddress, city: event.target.value}) }
                      />
                    </div>
                    <div className="form-group col-md-6 col-12">
                      <label className="labelInput">Estado: </label>
                      <select className="form-control" value={stateAddress.state} onChange={event => setStateAddress({...stateAddress, state: event.target.value})} >
                        {Functions.estadosBR.map((estado) => (
                          <option key={estado.uf} value={estado.uf}>{estado.nome}</option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group col-md-6 col-12">
                      <label className="labelInput">WhatsApp: </label>
                      <InputMask 
                        mask="(99) 99999-9999" 
                        className="form-control" 
                        placeholder="Telefone de contato"
                        value={statePhone}
                        onChange={event => setStatePhone(event.target.value) }
                      />
                    </div>
                    <div className="form-group col-md-6 col-12">
                      <label className="labelInput">Email: </label>
                      <input
                        required 
                        type="text" 
                        className="form-control" 
                        placeholder="Email de contato"
                        value={stateEmail}
                        onChange={event => setStateEmail(event.target.value)}
                      />
                    </div>
                    <div className="form-group col-12">
                      <label className="labelInput">Descrição: </label>
                      <textarea className="form-control" value={stateDescription} rows="3" onChange={event => setStateDescription(event.target.value)} />
                    </div>
                    <div className="col-12 form-group">
                      <p style={{marginBottom: 5, marginLeft: 5}}>Horários disponíveis para agendamento: </p>
                      <div className="table">
                        {getTableOpeningHours(stateOpeningHours)}
                      </div>
                      <p style={{fontSize: 14, marginLeft: 10, marginTop: -8}}><span className="link" style={{color: "var(--colorDefaultHover)"}} onClick={() => addOpeningHours()}>Adicionar novo Horário</span></p>  
                      <div className="row">
                        <div className="form-group col-md-8 col-12">
                          <label className="labelInput">Link para Agendamentos: </label>
                          <input
                            disabled="disabled"
                            type="text" 
                            className="form-control" 
                            value={"https://e-iris.com.br/booking/"+id} //https://e-iris.com.br/booking?business=
                          />
                        </div>
                        <div className="form-group col-md-4 col-12">
                          <label className="labelInput">Tempo de Agendamento: </label>
                          <select className="form-control" value={stateSchedulingTime} onChange={event => setStateSchedulingTime(event.target.value)} >
                            <option value="30">30 Minutos</option>
                            <option value="40">40 Minutos</option>
                            <option value="50">50 Minutos</option>
                            <option value="60">1 Hora</option>
                            <option value="75">1 e 15 min</option>
                            <option value="90">1 e 30 min</option>
                            <option value="120">2 Horas</option>
                            <option value="180">3 Horas</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 form-group">
                      <p style={{marginBottom: 5, marginLeft: 5}}>Mapas Padrão - Íris Direita: </p>
                      <div className="listViewIris">
                        {previewMapsIrisRight.map((item, index) => {
                          return (
                            <div key={index} className="divImgListViewIris">
                              <label style={{ backgroundImage: `url("${item}")`, border: "4px solid #ced4da" }} className={'imgListViewIris'}>
                              </label>
                              <span className="deleteViewIris" onClick={() => deleteMapRight(index)}> <i className="fas fa-trash"> </i></span>
                            </div>
                          )
                        })}
                        <label className={'imgListViewIris addImgViewIris'}>
                          <input type="file" multiple accept="image/*" onChange={onChangeMapsRight} />
                          <span>
                            <img src={camera} alt="Select img" />
                            <p>Adicionar Mapas</p>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="col-6 form-group">
                      <p style={{marginBottom: 5, marginLeft: 5}}>Mapas Padrão - Íris Esquerda: </p>
                      <div className="listViewIris">
                        {previewMapsIrisLeft.map((item, index) => {
                          return (
                            <div key={index} className="divImgListViewIris">
                              <label style={{ backgroundImage: `url("${item}")`, border: "4px solid #ced4da" }} className={'imgListViewIris'}>
                              </label>
                              <span className="deleteViewIris" onClick={() => deleteMapLeft(index)}> <i className="fas fa-trash"> </i></span>
                            </div>
                          )
                        })}
                        <label className={'imgListViewIris addImgViewIris'}>
                          <input type="file" multiple accept="image/*" onChange={onChangeMapsLeft} />
                          <span>
                            <img src={camera} alt="Select img" />
                            <p>Adicionar Mapas</p>
                          </span>
                        </label>
                      </div>
                    </div>
                    {/*<div className="form-group col-md-12 col-12">
                      <label className="labelInput">Meu Plano*</label>
                      <select className="form-control" value={JSON.stringify(statePlan)} style={{padding:'25px 15px 15px 10px'}} onChange={event => setStatePlan(JSON.parse(event.target.value))} disabled={transactionOpen ? "disabled" : ""}>
                        {plans.map((item, index) => (
                          <option key={index} value={JSON.stringify(item)}>{item.name} - {item.percentage}%</option>
                        ))}
                      </select>
                    </div>*/}
                  </div>     
                </div>     
                {item === "editBusiness" ?  
                  <div className="col-12 text-right">
                    <hr />
                    <button type="button" className="btn btn-delete" onClick={() => setEditBusiness(false)}> Fechar </button>
                      <span>&nbsp;&nbsp;&nbsp;</span>
                    <button type="submit" className="btn btn-default"> Salvar Alterações</button>
                    <br />
                    <br />
                  </div>
                :  
                  <div className="col-12 text-right">
                    <button type="submit" className="btn btn-default"> Salvar </button>
                  </div>
                }
              </div>
            </form>
          </>
        : '' }
      </li>
  );
};

export default ListItemBusiness;
