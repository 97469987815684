import React, { useState, useEffect, useContext } from 'react';
import logo from '../../assets/logo-home.png';
import './styles.css';
import '../../App.css';
import Lottie from 'react-lottie';
import iconLoading from '../../assets/icons/loading.json';

function RegisterSuccess({ history }) {
  const [category, setCategory] = useState("");
  const [buttonVisible, setButtonVisible] = useState(true);

  //Ao abrir a tela
  useEffect(() => {
    /*setTimeout(() => {
      setButtonVisible(true);
      //history.push('/creator/dashboard');
    }, 10000);*/
    //SEND EVENT CONVERSION TO GOOGLE ADS
    //window.gtag('event', 'conversion', {'send_to': 'AW-10868882288/T6hiCPyf_KkDEPD2174o'});
    //SEND EVENT CONVERSION TO GOOGLE Tag Manegar
    dataLayer.push({event: "conversion"});
  }, []);

  return (
  <>
    <div className="container v-center">
      <div id="cadastro" className="col-12 col-md-8 offset-md-2">
        <div className="text-center">
          <br/> <br/> <br/> <br/>
          <div className="col-12">
            <h1 id="logo">e-iris.</h1>  
          </div> 
          <br/>
          <h3>Plano Adquirido com Sucesso!</h3>
          <br/><br/>
          <p>Para acessar a sua conta, basta clicar no botão abaixo e efetuar o login com o e-mail utilizado na hora da aquisição do plano e no campo 'senha' informar seu CPF/CNPJ.</p>
          <p>Ao efetuar o login você deverá alterar a sua senha, criando alguma de sua preferência!</p>
          <p>Você também receberá as instruções de acesso via e-mail.</p>
          <p>Qualquer dúvida entre em através do e-mail: contato@tweezer.com.br</p>
        </div> 
        <div className="">
          {/*<div className="col-12">
            <div className="text-center">
              <br/>
              <Lottie options={{
                loop: true,
                autoplay: true, 
                animationData: iconLoading,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }}}
                height={70}
                width={70}
                style={{}}
              />
            </div>
          </div>*/}
          <br /> <br />
          { buttonVisible ?
            <div className="row">
              <div className="col-12 text-center">
                <a href="./dashboard" target="_Blank" className="btn btn-default">
                  <i className="fa fa-external-link-alt fa-lg"> </i>
                  <span style={{marginLeft: 5}}> Acessar o Painel </span>
                </a>
              </div>
            </div>
          : <></> }
          <br/> <br/>
        </div>
      </div>
    </div>
</>);
}

export default RegisterSuccess;
