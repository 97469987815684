//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState, useContext } from 'react';
import { FaUsers, FaUserFriends, FaBuilding, FaSignOutAlt, FaFileInvoiceDollar, FaClipboardList } from "react-icons/fa";
import { useLocation, useHistory } from 'react-router-dom';
import { useReactPWAInstall } from "react-pwa-install";
//import Api from '../../services/api'
import UserContext from '../../contexts/UserContext';
import ListItemBusiness from '../../components/ListItemBusiness';
import blank from '../../assets/icons/blank.png';
import iconBusiness from '../../assets/icons/business.png';
import './styles.css';
import Api from '../../services/Api';
import iconProfileWhite from '../../assets/icons/icon-profile-white.png';
import logoApp from "../../assets/logo192.png";

export default function DashboardHome(props) {
  const [loading, setLoading] = useState(true);
  const [editBusiness, setEditBusiness] = useState(false);
  const {user, setUser} = useContext(UserContext);
  const [modalInstallApp, setModalInstallApp] = useState(true);
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  const history = useHistory();

  // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(false);
    if(history.location.search.indexOf('editBusiness') !== -1){
      setTimeout(() => {
        setEditBusiness(true);
      }, 500);
    }
    if(!user[0].business?.name || user[0].business.name === "") {
      setEditBusiness(true);
    }
  }, []);

  //PWA Install button
  const installApp = () => {
    pwaInstall({
      title: "Instalar Aplicativo",
      logo: logoApp,
      //features: (),
      //description: "Instale o aplicativo e tenha o acesso a experiência completa!",
    })
    .then(() => alert("Operação realizada com sucesso!"))
    .catch(() => console.log("Instalação cancelada..."));
  };

  return (
  <>
    <div className="bodyDashboard">
      <div className="itensHome">
        <div className="col-12">
          <br />
          <div className="v-center"> 
            <label id="imgUser" style={user[0].hasOwnProperty("thumbnail") ? { backgroundImage: `url("${encodeURI(user[0].thumbnail)}")` } : { backgroundImage: `url("${iconProfileWhite}")`}}></label>
            <div style={{marginLeft: 15, paddingTop: 12, paddingBottom: 12}}>
              <div><b style={{fontSize: 20}}>{user[0].name}</b></div>
              <div>{user[0].email}</div>
              <button type="button" onClick={() => props.setModalPassword(!props.modalPassword)} className="btnLink">Alterar Senha?</button>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="itemHome" style={{padding: 0, cursor: "initial"}}>
            {editBusiness ?
              <div className="col-12">
                <div className="text-center" style={{paddingTop: 20}}>
                  <h3>Minha Clínica</h3>  
                </div>
                <ul className="dashboardLists"> 
                  <ListItemBusiness
                    key={user[0].business._id}
                    item="editBusiness"
                    id={user[0].business._id}
                    user={user[0]._id}
                    name={user[0].business?.name ? user[0].business.name : ""}
                    CNPJ={user[0].business?.CNPJ ? user[0].business.CNPJ : ""}
                    description={user[0].business?.description ? user[0].business.description : ""}
                    thumbnail={user[0].business?.thumbnail ? user[0].business.thumbnail : ""} 
                    //address={user[0].business?.address ? user[0].business.address : ""} 
                    address={user[0].business?.address && user[0].business.address?.street ? user[0].business.address : {street: "", number:"", district: "", complement: "", city: "", state: ""}} 
                    maps={user[0].business?.maps ? user[0].business.maps : { irisLeft: [], irisRight: [] }} 
                    phone={user[0].business?.phone ? user[0].business.phone : ""} 
                    email={user[0].business?.email ? user[0].business.email : ""} 
                    openingHours={user[0].business?.openingHours ? user[0].business.openingHours : []}
                    schedulingTime={user[0].business?.schedulingTime ? user[0].business.schedulingTime : 59}
                    //cities={cities}
                    //whatsapp={user[0].business.whatsapp} 
                    //instagram={user[0].business.instagram} 
                    //facebook={user[0].business.facebook} 
                    //website={user[0].business.website} 
                    active={user[0].business.active} 
                    //onDelete={() => removeBusiness(user[0].business._id)}
                    setEditBusiness={(value) => setEditBusiness(value)}
                    loadItem={() => props.reloadUser()}
                    setLoading={props.setLoading}
                    //ref={childRef}
                  />
                </ul>
              </div>
            : <>
              <div className="v-center"> 
                <div className="col-12 col-md-3 text-center"> 
                  <label id="imgBusiness" style={{ backgroundImage: `url("${user[0].business?.thumbnail ? encodeURI(user[0].business.thumbnail) : iconBusiness}")` }}></label>
                </div>
                <div className="col-12 col-md-9" style={{paddingTop: 12, paddingBottom: 12}}>
                  <div><b style={{fontSize: 20}}>{user[0].business?.name ? user[0].business.name : "Informe os dados da sua clínica!"}</b></div>
                  <div>{user[0].business.address?.street && user[0].business.address.street !== "" ? user[0].business.address.street + ", " + user[0].business.address.number + ", " + user[0].business.address.district + ", " + user[0].business.address.city + " - " + user[0].business.address.state: ""}</div>
                  <div style={{marginTop: 12}}>
                    <b>Link para Agendamentos: </b>
                    <a href={"https://e-iris.com.br/booking/"+user[0].business?._id} target='_Blank'> {"https://e-iris.com.br/booking/"+user[0].business?._id} </a>
                  </div>
                  {user[0]?.adminBusiness && user[0].adminBusiness ?
                    <button type="button" onClick={() => setEditBusiness(!editBusiness)} className="btn btn-default" style={{marginTop: 20}}>Editar Minha Clínica</button>
                  : <></>}
                </div>
              </div>
            </>}
          </div>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-md-6 text-center">
              <div className="itemHome" onClick={() => props.history.push('/dashboard/calls')}>
                <FaClipboardList size="5em" className="itemHomeIcon"/>
                <div className="title"> Atendimentos </div>
              </div>
            </div>
            <div className="col-12 col-md-6 text-center">
              <div className="itemHome" onClick={() => props.history.push('/dashboard/customers')} >
                <FaUsers size="5em" className="itemHomeIcon"/>
                <div className="title"> Pacientes </div>
              </div>
            </div>
            <div className="col-12 col-md-6 text-center">
              <div className="itemHome" onClick={() => props.history.push('/dashboard/business')}>
                <FaBuilding size="5em" className="itemHomeIcon"/>
                <div className="title"> Minha Clínica </div>
              </div>
            </div>
            {user[0]?.adminBusiness && user[0].adminBusiness ?
              <div className="col-12 col-md-6 text-center">
                <div className="itemHome" onClick={() => props.history.push('/dashboard/users')} >
                  <FaUserFriends size="5em" className="itemHomeIcon"/>
                  <div className="title"> Usuários </div>
                </div>
              </div>
            :
              <div className="col-12 col-md-6 text-center">
                <div className="itemHome" onClick={() => props.callbackParent(4)}>
                  <FaSignOutAlt size="5em" className="itemHomeIcon"/>
                  <div className="title"> Sair </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
    {supported() && !isInstalled() && modalInstallApp && (
      <div className="divBtnInstallApp">
        <button className="btnCloseInstallApp" onClick={() => setModalInstallApp(false)}>✘</button>
        <div className="row v-center">
          <div className="col-6 col-md-8">
            <h5>Instalar o App?</h5>
            <p>Instale o app e desfrute da experiência completa!</p>
          </div>
          <div className="col-6 col-md-4 text-center">
            <button className="btn-small btn-default" onClick={installApp}>
              Instalar Aplicativo
            </button>
          </div>
        </div>
      </div>
    )}  
  </>)
}