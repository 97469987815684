//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState, useContext} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import Functions from '../../components/Functions'
import ListItemUser from '../../components/ListItemUser'
import './styles.css';
import '../../App.css';
import UserContext from '../../contexts/UserContext';

export default function DashboardUsers({setLoading}) {
  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [plans, setPlans] = useState([]);
  const {user, setUser} = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();

  // Executa assim q a página é aberta;
  useEffect(() => {
    if(user[0]?.adminBusiness && user[0].adminBusiness) {
      setLoading(true);
    } else {
      history.push('/dashboard');
    }
    loadUsers();
  }, []);

  useEffect(() => {
    onSearch();
  }, [search]);

  useEffect(() => {
    if(history.location.search.indexOf('add') !== -1){
      setTimeout(() => {
        addUser();
      }, 500);
    }
  }, [location]);

  async function loadUsers() {
    try {
      const response = await Api.get(`/get-users-business/${user[0].business._id}`);
      console.log(response);
      setUsers(response);
      setAllUsers(response);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  function onToggleSearch(){
    if(openSearch){
      setSearch("")
      setOpenSearch(!openSearch);
    } else {
      setOpenSearch(!openSearch);
    }
  }

  function onSearch(){
    var arr = [...allUsers];
    var result = arr.filter(function(item){
      return item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 
      || item.cpf.replaceAll(".", "").replaceAll("-", "").toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });
    setUsers(result);
  }

  function addUser(){
    if(parseInt(user[0].business.plan.numberUsers) > 1){
      if(parseInt(user[0].business.plan.numberUsers) > allUsers.length){
        const userCopy = Array.from(users);
        // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
        userCopy.unshift({
          _id: userCopy.length +1,
          user: user[0]._id,
          item: "User",
          name: "",
          birthDate: "",
          thumbnail: "",
          gender: "",
          phone: "",
          cpf: "",
          email: "",
          height: "",
          weight: "",
          bloodType: "",
          address: {street: "", number:"", district: "", complement: "", city: "", state: ""},
          observations: "",
          active: true
        });
        setUsers(userCopy);
        //Gambiarra
        setTimeout(() => {
          document.getElementsByClassName("headList")[0].click();
        }, 100);
        //childRef.current.getAlert(userCopy.length)
      } else {
        toast.success("Você atingiu o limite de usuários do seu plano!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } else {
      toast.success("Para adicionar novos usuários faça um upgrade para um plano de equipes!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  async function removeUser(id){
    setLoading(true);
    const userCopy = Array.from(users);
    var index = userCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.post('/delete-user', {'id': id});
        console.log(response);
        if(Number(response.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          userCopy.splice(index, 1);
          setUsers(userCopy);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      userCopy.splice(index, 1);
      setUsers(userCopy);
    }
    setLoading(false);
  }

  return (
    <div id="userList" className="bodyDashboard">
      <br />
        <div className="v-center">
          {openSearch ? 
            <div className="col-md-8 col-7">
              <input
                autoFocus
                type="text" 
                className="form-control" 
                placeholder="Pesquise pelo nome ou CPF..."
                style={{height: "auto", borderRadius: 30, padding: 15}}
                value={search}
                onChange={event => setSearch(event.target.value)}
              />
              <i className="fa fa-search fa-lg iconSearchInput"></i>
            </div>
          :
          <div className="col-md-8 col-7">
            <span className="title">Usuários</span>
          </div>
          }
          <div className="col-md-4 col-5 text-right">
            <button type="button" onClick={() => onToggleSearch()} className="btnNew" style={{marginRight: 8}}><i className={openSearch ? "fa fa-times fa-lg" : "fa fa-search fa-lg"}></i></button>
            <button type="button" onClick={addUser} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>
            {/*<button type="button" onClick={addUser} className="btn btn-default"> Adicionar Cliente </button>*/}
          </div>
        </div>
        <div className="col-12">
          <ul className="dashboardList">
          {
            users.length > 0 ?
            users.map(item => (
              <ListItemUser
                key={item._id}
                view="DashboardUser"
                user={user[0]._id}
                business={user[0].business}
                _id={item._id}
                name={item.name}
                thumbnail={item.thumbnail} 
                email={item?.email ? item.email : ""}
                phone={item?.phone ? item.phone : ""}
                cpf={item?.cpf ? item.cpf : ""}
                birthDate={item?.birthDate ? item.birthDate : ""}
                address={item?.address ? item.address : {street: "", number:"", district: "", complement: "", city: "", state: ""}} 
                adminBusiness={item?.adminBusiness ? item.adminBusiness : false} 
                active={item.active} 
                canEditUser={true}
                setCanEditUser={() => {}}
                onDelete={() => removeUser(item._id)}
                loadItem={() => loadUsers()}
                setLoading={setLoading}
              />
            )) :
            <div className="col-12 text-center">
              <br /><br /><br />
              {search !== "" ?
                <span className="labelFilter">Usuário não encontrado!</span>
              :
                <span className="labelFilter">Você ainda não possui usuários cadastrados!</span>
              }
            </div>
          }
          </ul>
      </div>
    </div>
  )
}