import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Rnd } from "react-rnd";
import Magnifier from "./Magnifier";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { FaExpandAlt, FaCompressAlt, FaFileAlt } from "react-icons/fa";
import { Editor } from '@tinymce/tinymce-react';
import Functions from './Functions'
import ListItemCustomer from './ListItemCustomer'
import Api from '../services/Api'
import camera from '../assets/icons/camera.svg';
import Templates from './Templates';
import Modal from 'react-modal';

Modal.setAppElement(document.getElementById('root'));

const EvaluateIris = (props) => {
  const handleFullScreenIrisRight = useFullScreenHandle();
  const handleFullScreenIrisLeft = useFullScreenHandle();
  const [tabIndex, setTabIndex] = useState(0);
  const [canEditCustomer, setCanEditCustomer] = useState(false);
  const [viewIrisRight, setViewIrisRight] = useState("");
  const [viewMapIrisRight, setViewMapIrisRight] = useState("");
  const [invertMapIrisRight, setInvertMapIrisRight] = useState(false);
  const [opacityMapIrisRight, setOpacityMapIrisRight] = useState(1);
  const [openMagnifierIrisRight, setOpenMagnifierIrisRight] = useState(false);
  const [backgroundSizeRight, setBackgroundSizeRight] = useState("cover");
  const [viewIrisLeft, setViewIrisLeft] = useState("");
  const [viewMapIrisLeft, setViewMapIrisLeft] = useState("");
  const [invertMapIrisLeft, setInvertMapIrisLeft] = useState(false);
  const [opacityMapIrisLeft, setOpacityMapIrisLeft] = useState(1);
  const [openMagnifierIrisLeft, setOpenMagnifierIrisLeft] = useState(false);
  const [backgroundSizeLeft, setBackgroundSizeLeft] = useState("cover");
  const [nameTemplate, setNameTemplate] = useState('');
  const [templates, setTemplates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formState, setFormState] = useState({
    call: { _id: props.call._id, dateStart: props.call.dateStart, hourStart: props.call.hourStart, recommendations: props.call.recommendations, anamnese: props.call.anamnese, observations: props.call.observations, modality: props.call.modality, reports: props.call.reports },
    customer: props.call.customer,
    business: props.business,
    user: props.call.user,
    iris: props.call.iris,
    maps: props.business?.maps ? props.business.maps : { irisLeft: [], irisRight: [] }
  });
  const editorAnamneseRef = useRef(null);
  const editorRecommendationsRef = useRef(null);
  const editorReportsRef = useRef(null);
  const history = useHistory();
  //console.log(formState);

  const customStylesModal = {
    overlay: {
      background: 'rgba(0,0,0,0.5)',
      zIndex: 999
    }, 
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '95%',
      maxWidth: '550px',
      border: 0,
      padding: '15px',
      borderRadius: '15px',
      boxShadow: '0px 0px 5px -2px #bbbbbb'
    }
  };

  // Executa assim q a página é aberta;
  useEffect(() => {
    //console.log(props);
    setTimeout(() => {
      loadTemplates();
    }, 1000);

  }, []);

  useEffect(() => {
    var menu = new URLSearchParams(location.search).get("menu")
    if(menu && parseInt(menu)){
      setTabIndex(parseInt(menu));
    }
  }, [location]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    //console.log(params.toString());
    if(parseInt(tabIndex)) {
      params.delete("menu");
      params.append("menu", tabIndex);
    } else {
      params.delete("menu");
    }
    history.push({search: params.toString()})
  }, [tabIndex, history])
  
  useEffect(() => {
    if(!formState.business?.subscription || moment(formState.business.subscription.expiration_date) < moment()){
      props.setLoading(true);
      console.log("Plano expirado!");
      toast.error("Seu plano expirou! Efetue a renovação para acessar essa página!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setTimeout(() => {
        history.push('/dashboard/business?expiration_plan=true');
      }, 5000);
    }
  }, []);

  const previewImagesIrisRight = useMemo(() => {
    let arrImages = [];
    Array.from(formState.iris.irisRight.images).map(itemImg => {
      if(parseInt(formState.call._id) !== formState.call._id && props.call.iris.irisRight.images.length > 0 && props.call.iris.irisRight.images.indexOf(itemImg) >= 0){
        var url = Api.URL_FILES_BUSINESS + '/' + props.business._id + '/' + formState.call._id + '/'+ encodeURI(itemImg);
        //console.log(url);
        arrImages.push(url);
      } else {
        if(typeof itemImg === "object"){
          let obj = URL.createObjectURL(itemImg);
          arrImages.push(obj); 
        }
      }
    });
    //console.log(arrImages);
    if(arrImages.length > 0){
      setViewIrisRight(arrImages[0]);
    }
    return arrImages;
  }, [formState.iris.irisRight.images]);

  const previewImagesIrisLeft = useMemo(() => {
    let arrImages = [];
    Array.from(formState.iris.irisLeft.images).map(itemImg => {
      if(parseInt(formState.call._id) !== formState.call._id && props.call.iris.irisLeft.images.length > 0 && props.call.iris.irisLeft.images.indexOf(itemImg) >= 0){
        var url = Api.URL_FILES_BUSINESS + '/' + props.business._id + '/' + formState.call._id + '/'+ encodeURI(itemImg);
        //console.log(url);
        arrImages.push(url);
      } else {
        if(typeof itemImg === "object"){
          let obj = URL.createObjectURL(itemImg);
          arrImages.push(obj); 
        }
      }
    });
    //console.log(arrImages);
    if(arrImages.length > 0){
      setViewIrisLeft(arrImages[0]);
    }
    return arrImages;
  }, [formState.iris.irisLeft.images]);

  const previewMapsIrisRight = useMemo(() => {
    let arrImages = ['https://storage.googleapis.com/e-iris-businesses/map-iris-right.gif'];
    Array.from(formState.maps.irisRight).map(itemImg => {
      if(props.business?.maps && props.business.maps.irisRight.length > 0 && props.business.maps.irisRight.indexOf(itemImg) >= 0){
        var url = Api.URL_FILES_BUSINESS + '/' + props.business._id + '/'+ encodeURI(itemImg);
        //console.log(url);
        arrImages.push(url);
      } else {
        if(typeof itemImg === "object"){
          console.log(itemImg);
          let obj = URL.createObjectURL(itemImg);
          arrImages.push(obj); 
        }
      }
    });
    console.log(arrImages);
    return arrImages;
  }, [formState.maps.irisRight]);

  const previewMapsIrisLeft = useMemo(() => {
    let arrImages = ['https://storage.googleapis.com/e-iris-businesses/map-iris-left.gif'];
    Array.from(formState.maps.irisLeft).map(itemImg => {
      if(props.business?.maps && props.business.maps.irisLeft.length > 0 && props.business.maps.irisLeft.indexOf(itemImg) >= 0){
        var url = Api.URL_FILES_BUSINESS + '/' + props.business._id + '/'+ encodeURI(itemImg);
        //console.log(url);
        arrImages.push(url);
      } else {
        if(typeof itemImg === "object"){
          let obj = URL.createObjectURL(itemImg);
          arrImages.push(obj); 
        }
      }
    });
    //console.log(arrImages);
    return arrImages;
  }, [formState.maps.irisLeft]);
  
  async function onChangeImagesRight(e){
    /*if(e.target.files[0]){
      let file_size = e.target.files[0].size;
      let file_name = e.target.files[0].name;
      console.log(file_size);
      const image = await Functions.resizeFile(e.target.files[0]);
      console.log(image);
      if(parseInt(file_size) >= 5240000) {
        toast.error("Imagem não suportada! A imagem deve ter no máximo 5MB", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        var imageFile = e.target.files[0];
        var imageUrl = URL.createObjectURL(image);
        //console.log(file_name);
        //console.log(imageFile);
        setFormState({...formState, iris: {...formState.iris, irisRight: {...formState.iris.irisRight, images: [...formState.iris.irisRight.images, imageFile] } } })
        setViewIrisRight(imageUrl);
      }
    }*/
    let arrImages = Array.from(formState.iris.irisRight.images);
    if(e.target.files.length > 0){    
      for(let i = 0; i < e.target.files.length; i++){
        if(parseInt(e.target.files[i].size) >= 5240000) {
          toast.error("A imagem " +e.target.files[i].name + " não pode ser enviada! A imagem deve ter no máximo 5MB", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else { 
          arrImages.push(e.target.files[i]);
          console.log(arrImages);
        }
      }
      setViewIrisRight(URL.createObjectURL(e.target.files[0]));
      setFormState({...formState, iris: {...formState.iris, irisRight: {...formState.iris.irisRight, images: arrImages } } })
    }
    console.log(formState);
  };

  function onChangeImagesLeft(e){
    let arrImages = Array.from(formState.iris.irisLeft.images);
    if(e.target.files.length > 0){    
      for(let i = 0; i < e.target.files.length; i++){
        if(parseInt(e.target.files[i].size) >= 5240000) {
          toast.error("A imagem " +e.target.files[i].name + " não pode ser enviada! A imagem deve ter no máximo 5MB", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else { 
          arrImages.push(e.target.files[i]);
          console.log(arrImages);
        }
      }
      setViewIrisLeft(URL.createObjectURL(e.target.files[0]));
      setFormState({...formState, iris: {...formState.iris, irisLeft: {...formState.iris.irisLeft, images: arrImages } } })
    }
    console.log(formState);
  };

  function onChangeMapsRight(e){
    if(e.target.files[0]){
      let file_size = e.target.files[0].size;
      if(parseInt(file_size) >= 5240000) {
        toast.error("Imagem não suportada! A imagem deve ter no máximo 5MB", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else{
        var imageFile = e.target.files[0];
        var imageUrl = URL.createObjectURL(e.target.files[0]);
        setFormState({...formState, maps: {...formState.maps,  irisRight: [...formState.maps.irisRight, imageUrl] } });
        setViewMapIrisRight(imageUrl);
        console.log(formState);
      }
    }
  };

  function onChangeMapsLeft(e){
    if(e.target.files[0]){
      let file_size = e.target.files[0].size;
      if(parseInt(file_size) >= 5240000) {
        toast.error("Imagem não suportada! A imagem deve ter no máximo 5MB", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else{
        var imageFile = e.target.files[0];
        var imageUrl = URL.createObjectURL(e.target.files[0]);
        setFormState({...formState, maps: {...formState.maps,  irisLeft: [...formState.maps.irisLeft, imageUrl] } });
        setViewMapIrisLeft(imageUrl);
      }
    }
  };

  function deleteIrisRight(index){
    var arrImages = Array.from(formState.iris.irisRight.images);
    arrImages.splice(index, 1)
    console.log(arrImages);
    setFormState({...formState, iris: {...formState.iris, irisRight: {...formState.iris.irisRight, images: arrImages } } })
    if(arrImages.length === 0){
      setViewIrisRight("");
    }
  }

  function deleteIrisLeft(index){
    var arrImages = Array.from(formState.iris.irisLeft.images);
    arrImages.splice(index, 1);
    console.log(arrImages);
    setFormState({...formState, iris: {...formState.iris, irisLeft: {...formState.iris.irisLeft, images: arrImages } } })
    if(arrImages.length === 0){
      setViewIrisLeft("");
    }
  }

  function loadTemplates(){
    var templatesDefault = [
      {name: "Modelo em Branco", type: "anamnese", default: true, template: Templates.templateWhite("Ficha de Anamnese", formState.business, formState.customer, formState.user, moment(formState.call.dateStart).format("DD/MM/YYYY"))},
      {name: "Modelo Padrão", type: "anamnese", default: true, template: Templates.templateAnamneseDefault("Ficha de Anamnese", formState.business, formState.customer, formState.user, moment(formState.call.dateStart).format("DD/MM/YYYY"))},
      {name: "Modelo em Branco", type: "recommendations", default: true, template: Templates.templateWhite("Recomendação Terapêutica", formState.business, formState.customer,  formState.user, moment(formState.call.dateStart).format("DD/MM/YYYY"))},
      {name: "Modelo em Branco", type: "reports", default: true, template: Templates.templateWhite("Laudos", formState.business, formState.customer,  formState.user, moment(formState.call.dateStart).format("DD/MM/YYYY"))},
    ];
    if(props.business?.templates){
      setTemplates([...templatesDefault, ...props.business.templates])
    } else {
      setTemplates([...templatesDefault])
    }
  }

  function onSaveNewTemplate() {
    setNameTemplate("");
    setShowModal(true);
  }

  function saveTemplate(e){
    e.preventDefault();
    var template = {}, type = "";
    if(tabIndex === 1){ 
      type = "anamnese"
      template = { name: nameTemplate || "Modelo Personalizado", type: type, default: false, template: formState.call.anamnese };
    } else if(tabIndex === 3){ 
      type = "recommendations"
      template = { name: nameTemplate || "Modelo Personalizado", type: type, default: false, template: formState.call.recommendations };
    } else if(tabIndex === 4){ 
      type = "reports"
      template = { name: nameTemplate || "Modelo Personalizado", type: type, default: false, template: formState.call.reports };
    }
    var arrTemplates = [...templates]
    var arrFilter = arrTemplates.filter((item) => {
      return item.default === false && item.template !== template.template;
    });
    arrTemplates = arrTemplates.filter((item) => {
      return item.template !== template.template;
    });
    arrTemplates = [...arrTemplates, template]
    arrFilter = [...arrFilter, template]
    setTemplates(arrTemplates);
    updateTemplates(arrFilter, "insert");
    setNameTemplate("");
    setShowModal(false);
  }

  function deleteTemplate(index){
    if(window.confirm('Deseja realmente deletar este modelo?')){
      var arrTemplates = Array.from(templates);
      arrTemplates.splice(index, 1);
      //console.log(arrTemplates);
      var arrFilter = arrTemplates.filter((el) => {
        return el.default === false;
      });
      //console.log(arrFilter);
      setTemplates(arrTemplates);
      updateTemplates(arrFilter, "delete");
    }
  }

  function editTemplate(item){
    setNameTemplate(item.name)
    setShowModal(true)
  }

  async function updateTemplates(templates, operation){
    props.setLoading(true);
    //Máximo 15 templates personalizados
    if(templates.length <= 15){
      const response = await Api.post("/update-business-templates", {
        id: props.business._id,
        templates: JSON.stringify(templates)
      });
      if(response.hasOwnProperty('_id') || response.hasOwnProperty('ok')){
        if(operation === "insert"){
          toast.success("Modelo salvo com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else if(operation === "delete"){
          toast.success("Modelo excluído com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        setFormState({...formState, business: {...formState.business, templates: templates} })
        props.setLoading(false);
      } else {
        toast.error("Falha ao salvar o modelo!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        props.setLoading(false);
      }
    } else {
      toast.error("Limite de modelos atingido! Você só pode armazenar no máximo 15 modelos", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      props.setLoading(false);
    }
  }

  function onClose() {
    //document.getElementById('titleMenu').textContent = "Atendimentos";
    history.push('/dashboard/calls');
    props.onClose();
  }

  async function saveCall(){
    if(formState.customer.name === ""){
      toast.error("Por favor, selecione um paciente!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setTabIndex(0);
      return;
    } else {
      props.setLoading(true);
      //Cria um estrutura Multiform Data;
      var iris = formState.iris, arrImagesIrisRight = Array.from(formState.iris.irisRight.images), arrImagesIrisLeft = Array.from(formState.iris.irisLeft.images);
      const data = new FormData();
      data.append('id', formState.call._id);
      data.append('user', formState.user._id);
      data.append('business', props.business._id);
      data.append('customer', formState.customer._id);
      //data.append('dateStart', formState.call.dateStart);
      data.append('dateStart', formState.call.dateStart.substr(0,10) + "T" + formState.call.dateStart.substr(11,5) + ":00.000Z");
      data.append('schedulingTime', props.business?.schedulingTime ? props.business.schedulingTime : 60);
      data.append('modality', formState.call.modality);
      data.append('observations', formState.call.observations);
      //Add images IRIS RIGHT
      console.log(arrImagesIrisRight);
      arrImagesIrisRight.length > 0 && arrImagesIrisRight.map(function(image, index) {
        if(typeof image === "object"){
          //New Call
          //if(parseInt(formState.call._id) === formState.call._id){
            if(index === 0) {
              iris.irisRight.images = [];
            }
            if(iris.irisRight.images.indexOf(image.name) === -1){
              //iris.irisRight.images = [...iris.irisRight.images, image.name];
              iris.irisRight.images[index] = image.name;
              data.append('files', image);
            }
          /*} else {
            iris.irisRight.images.splice(index, 1);
            //console.log(image.name);
            //console.log(iris.irisRight.images);
            if(iris.irisRight.images.indexOf(image.name) === -1){
              iris.irisRight.images = [...iris.irisRight.images, image.name];
              data.append('files', image);
            }
          }*/
        } else {
          iris.irisRight.images[index] = image;
        }
      });
      //Add images IRIS LEFT
      console.log(arrImagesIrisLeft);
      arrImagesIrisLeft.length > 0 && arrImagesIrisLeft.map(function(image, index) {
        if(typeof image === "object"){
          //New Call
          //if(parseInt(formState.call._id) === formState.call._id){
            if(index === 0) {
              iris.irisLeft.images = [];
            }
            if(iris.irisLeft.images.indexOf(image.name) === -1){
              //iris.irisLeft.images = [...iris.irisLeft.images, image.name];
              iris.irisLeft.images[index] = image.name;
              data.append('files', image);
            }
          /*} else {
            iris.irisLeft.images.splice(index, 1);
            //console.log(image.name);
            //console.log(iris.irisLeft.images);
            if(iris.irisLeft.images.indexOf(image.name) === -1){
              iris.irisLeft.images = [...iris.irisLeft.images, image.name];
              data.append('files', image);
            }
          }*/
        } else {
          iris.irisLeft.images[index] = image;
        }
      });
      if(iris.irisRight.images.length > 5 || iris.irisLeft.images.length > 5) {
        toast.error("Você pode salvar no máximo 5 imagens de cada íris!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        props.setLoading(false);
        return;
      }
      console.log(iris);
      data.append('iris', JSON.stringify(iris));
      data.append('anamnese', formState.call?.anamnese && formState.call.anamnese !== "" ? formState.call.anamnese : "");
      data.append('recommendations', formState.call?.recommendations && formState.call.recommendations !== "" ? formState.call.recommendations : ""); //editorRecommendationsRef.current.getContent()
      data.append('reports', formState.call?.reports && formState.call.reports !== "" ? formState.call.reports : "");
      try {
        const response = await Api.post("/update-call", data);
        console.log(response);
        if(response.hasOwnProperty('_id') || response.hasOwnProperty('ok')){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          if(response.hasOwnProperty('_id')){
            history.push(`/dashboard/calls/call?id=${response._id}&menu=${tabIndex}`);
          }
          window.location.reload();
          //props.setLoading(false);
          //props.onClose();
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          props.setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  
  return (
    <div className="col-12">
      <br />
      <div className="row v-center">
        {/*<div className="form-group col-md-2 col-4 order-1 order-md-1">
          <label className="labelInput">Código: </label>
          <input
            disabled="disabled"
            type="text" 
            className="form-control" 
            placeholder="Nome do paciente"
            value={formState.call._id}
          />
        </div>*/}
        <div className="form-group col-md-6 col-6 order-3 order-md-2">
          <label className="labelInput">Paciente: </label>
          <input
            disabled="disabled"
            type="text" 
            className="form-control" 
            placeholder="Nome do paciente"
            value={formState.customer.name}
          />
        </div>
        <div className="form-group col-md-3 col-6 order-4 order-md-3">
          <label className="labelInput">Data: </label>
          <input
            disabled="disabled"
            type="text" 
            className="form-control" 
            placeholder="Data da avalição"
            value={formState.call.dateStart.substr(0,10).split("-").reverse().join("/") + " às " + formState.call.dateStart.substr(11,5) + "h"}
          />
        </div>
        <div className="form-group col-md-3 col-12 text-right order-2 order-md-4">
          <button type="button" className="btn btn-default" onClick={() => saveCall()} style={{marginRight: 15}}> Salvar Avaliação </button>
          <button type="button" className="btn btn-delete" onClick={() => onClose()}> ✘ </button>
        </div>
      </div>
      {/*<h3>Examinar Íris</h3>*/}
      <div className="tabs">
        <div className="tabMenu">
          <div className={tabIndex === 0 ? "tabMenuItem active" : "tabMenuItem"} onClick={() => setTabIndex(0)}><i className="fas fa-address-card fa-lg"> </i> <span className="titleTab">Informações</span></div>
          <div className={tabIndex === 1 ? "tabMenuItem active" : "tabMenuItem"} onClick={() => setTabIndex(1)}><i className="fas fa-notes-medical fa-lg"> </i> <span className="titleTab">Anamnese</span></div>
          <div className={tabIndex === 2 ? "tabMenuItem active" : "tabMenuItem"} onClick={() => setTabIndex(2)}><i className="fas fa-eye fa-lg"> </i> <span className="titleTab">Íris</span></div>
          <div className={tabIndex === 3 ? "tabMenuItem active" : "tabMenuItem"} onClick={() => setTabIndex(3)}><i className="fas fa-scroll fa-lg"> </i> <span className="titleTab">Recomendações</span></div>
          <div className={tabIndex === 4 ? "tabMenuItem active" : "tabMenuItem"} onClick={() => setTabIndex(4)}><i className="fas fa-file-medical-alt fa-lg"> </i> <span className="titleTab">Laudos</span></div>
          <div className={tabIndex === 5 ? "tabMenuItem active" : "tabMenuItem"} onClick={() => setTabIndex(5)}><i className="fa fa-file-alt fa-lg"> </i> <span className="titleTab">Observações</span></div>
        </div>
        {/*<button className="btnCloseModal" onClick={() => onClose()} style={{fontSize: 30, margin:12, color: "#212529"}}>✘</button>*/}
        {/* Informações */}
        {tabIndex === 0 ?
          <div className="container" style={{marginTop: 25}}>
            <div className="row v-center">
              <div className="col-8">
                <h3 style={{marginBottom: 0}}>Dados do Paciente</h3>
              </div>  
              <div className="col-4 text-right">
                {formState?.customer?._id?.toString().match(/^[0-9a-fA-F]{24}$/) ?
                  <button type="button" onClick={() => setCanEditCustomer(!canEditCustomer)} className={canEditCustomer ? "btn btn-delete" : "btn btn-default"}>{canEditCustomer ? "Fechar Edição" : "Editar Cadastro"}</button>
                : <></>} 
              </div> 
              {console.log('formState', formState)}
              <ListItemCustomer
                view="EvaluateIris"
                user={props.call.user._id}
                business={props.business._id}
                customers={props.customers}
                _id={formState.customer._id}
                name={formState.customer.name}
                thumbnail={formState.customer?.thumbnail ? formState.customer.thumbnail : ""} 
                email={formState.customer?.email ? formState.customer.email : ""}
                phone={formState.customer?.phone ? formState.customer.phone : ""}
                cpf={formState.customer?.cpf ? formState.customer.cpf : ""}
                birthDate={formState.customer?.birthDate ? formState.customer.birthDate : ""}
                height={formState.customer?.height ? formState.customer.height : ""}
                weight={formState.customer?.weight ? formState.customer.weight : ""}
                gender={formState.customer?.gender ? formState.customer.gender : ""}
                bloodType={formState.customer?.bloodType ? formState.customer.bloodType : ""}
                observations={formState.customer?.observations ? formState.customer.observations : ""}
                address={formState.customer?.address ? formState.customer.address : {street: "", number:"", district: "", complement: "", city: "", state: ""}} 
                active={formState.customer?.active ? formState.customer.active : true} 
                onChangeCustomer={(customerSelected) => { console.log(customerSelected); setFormState({...formState, customer: customerSelected}); } }
                canEditCustomer={canEditCustomer}
                setCanEditCustomer={(value) => setCanEditCustomer(value)}
                //onDelete={() => removeCustomer(formState.customer._id)}
                loadItem={() => {}}
                setLoading={props.setLoading}
              />
            </div>
            <br />
          </div>
        :<></>}

        {/* Anamnese */}
        {tabIndex === 1 ?
          <div className="container" style={{marginTop: 15}}>
            <div className="row">
              {/*<div className="col-6">
                <h3 style={{marginBottom: 0}}>Sintomas do Paciente</h3>
              </div>  
              <div className="col-6 text-right">
                {/*<button type="button" onClick={() => {}} className="btn btn-default"></button>*}
              </div> */}
              <div className="col-12" style={{marginBottom: 10}}>
                <p style={{marginBottom: 0}}>Modelos:</p>
                <div className="row">
                  {templates.map((item, index) => {
                    if(item.type === "anamnese"){
                      return (
                        <div key={index+""} className="col-md-2 col-4" style={{padding: "8px 5px"}} onClick={() => setFormState({...formState,  call: {...formState.call, anamnese: item.template }}) }>
                          <div className="itemTemplate text-center">
                            <FaFileAlt size="3em" style={{marginBottom: 8, marginTop: 8, color: "#777"}}/>
                            <p style={{marginBottom: 0, fontSize: 14}}>{item.name}</p>
                            {!item?.default || !item.default ?
                              <>
                                <span className="editItemTemplate" onClick={() => editTemplate(item)}> <i className="fas fa-pen"> </i></span>
                                <span className="deleteItemTemplate" onClick={() => deleteTemplate(index)}> <i className="fas fa-trash"> </i></span>
                              </>
                            : <></>}
                          </div>
                        </div>
                      )
                    }
                  })}
                </div> 
              </div> 
              <Editor
                apiKey="8sgies4ow7n30jtd3r5hdhcgfxk4fprpkyo4ah22182lmotb"
                onInit={(evt, editor) => editorAnamneseRef.current = editor}
                //initialValue={formState.call?.anamnese ? formState.call.anamnese : "<p>Informe aqui os sintomas do seu paciente...</p>"}
                init={{
                  height: 600,
                  width: '100%',
                  menubar: true,
                  language: 'pt_BR',
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
                value={formState.call.anamnese}
                onEditorChange={(value, editor) => { 
                  setFormState({...formState,  call: {...formState.call, anamnese: value }}) 
                }}
              />
            </div>  
            <br />
            {formState.call?.anamnese && formState.call.anamnese !== "" ?
              <div className="row">
                <div className="col-12 text-right">
                  <button type="button" onClick={() => onSaveNewTemplate()} className="btn btn-default"> Salvar Modelo Personalizado</button>
                  <br /><br />
                </div> 
              </div> 
            : <></>}
          </div>  
        :<></>}

        {/* Íris */}
        {tabIndex === 2 ?
        <>
          <div className="row">
            {/* Íris Right*/}
            <div className="col-12 col-md-6">
              <div className="irisView text-center">
                <span className="labelIris">D</span>
                <TransformWrapper>
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <>
                      {openMagnifierIrisRight ?
                        <Magnifier src={viewIrisRight} width={"100%"} zoomImgSrc={viewIrisRight}/>
                      : 
                        <TransformComponent>
                          <label id="imgIrisRight" style={{ backgroundImage: `url("${viewIrisRight}")`, backgroundSize: backgroundSizeRight }} className={'imgIrisView'}></label>
                        </TransformComponent>
                      } 
                      {viewMapIrisRight !== "" ?
                        <Rnd
                          style={{}}
                          className="divImgMapIrisView"
                          default={{
                            x: 250,
                            y: 120,
                            width: 300,
                            height: 300
                          }}
                        >
                          <span className="btnInvertMapColor" onClick={() => setViewMapIrisRight("")} title="Fechar o mapa">
                            <i className="fas fa-times"></i>
                          </span>
                          <span className="btnInvertMapColor" onClick={() => setInvertMapIrisRight(!invertMapIrisRight)} title="Inverter a cor do mapa" style={{marginTop: 55}}>
                            <i className="fas fa-fill-drip"></i>
                          </span>
                          <label style={invertMapIrisRight ? { backgroundImage: `url("${viewMapIrisRight}")`, filter: "invert(1)", opacity: opacityMapIrisRight } : { backgroundImage: `url("${viewMapIrisRight}")`, opacity: opacityMapIrisRight }} className={'imgMapIrisView'}></label>
                          <input type="range" min="0" max="1" step="0.1" onChange={(e) => setOpacityMapIrisRight(e.target.value)}></input>
                        </Rnd>
                      : <></>}
                      <div className="irisViewControls">
                        <span onClick={() => zoomIn()} title="Mais Zoom">
                        <i className="fas fa-plus-circle fa-lg"></i>
                        </span>
                        <span onClick={() => zoomOut()} title="Menos Zoom">
                          <i className="fas fa-minus-circle fa-lg"></i>
                        </span>
                        <span onClick={() => resetTransform()} title="Resetar Zoom">
                          <i className="fa fa-undo-alt fa-lg"></i>
                        </span>
                        <span onClick={() => setBackgroundSizeRight(backgroundSizeRight === "contain" ? "cover" : "contain")} title={backgroundSizeRight === "contain" ? "Centralizar Imagem": "Expandir Imagem"}>
                          {backgroundSizeRight === "contain" ? <FaCompressAlt style={{marginTop: -3}}/> : <FaExpandAlt style={{marginTop: -3}} />}
                        </span>
                        {/*<span onClick={() => setOpenMagnifierIrisRight(!openMagnifierIrisRight)} title="Lupa">
                          <i className={openMagnifierIrisRight ? "fa fa-search-plus fa-lg active" : "fa fa-search-plus fa-lg"}></i>
                        </span>*/}
                        <span onClick={handleFullScreenIrisRight.enter} title="Abri em Tela Cheia">
                          <i className="fa fa-expand fa-lg"></i>
                        </span>
                      </div>
                    </>
                  )}
                </TransformWrapper>
              </div>
              <div className="row">
                <div className="form-group col-12">
                  <br /><br />
                  <p style={{marginBottom: 5, marginTop: 5, marginLeft: 5}}>Observações Íris Direita: </p>
                  <textarea 
                    className="form-control" 
                    style={{borderRadius: 10, paddingTop: 8}}
                    rows="4" 
                    /*defaultValue={formState.iris.irisRight.observations}
                    onBlur={event => { 
                      console.log(formState); 
                      setFormState({...formState,  iris: {...formState.iris, irisRight: {...formState.iris.irisRight, observations: event.target.value }}}) 
                    }}*/
                    value={formState.iris.irisRight.observations}
                    onChange={event => {
                      setFormState({...formState,  iris: {...formState.iris, irisRight: {...formState.iris.irisRight, observations: event.target.value }}}) 
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p style={{marginBottom: 5, marginLeft: 5}}>Imagens Íris Direita: </p>
                  <div className="listViewIris">
                    {previewImagesIrisRight.map((item, index) => {
                      return (
                        <div key={item.toString()} className="divImgListViewIris">
                          <label onClick={() => setViewIrisRight(item)} style={{ backgroundImage: `url("${item}")` }} className={viewIrisRight === item ? 'imgListViewIris active' : 'imgListViewIris'}>
                          </label>
                          <span className="deleteViewIris" onClick={() => deleteIrisRight(index)}> <i className="fas fa-trash"> </i></span>
                        </div>
                      )
                    })}
                    <label className={'imgListViewIris addImgViewIris'}>
                      <input type="file" multiple accept="image/*" onChange={onChangeImagesRight} />
                      <span>
                        <img src={camera} alt="Select img" />
                        <p>Adicionar Imagens</p>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p style={{marginBottom: 5, marginLeft: 5}}>Mapas Íris Direita: </p>
                  {previewMapsIrisRight.map((item, index) => {
                    return (
                      <label key={item.toString()} onClick={() => setViewMapIrisRight(item)} style={{ backgroundImage: `url("${item}")` }} className={viewMapIrisRight === item ? 'imgListViewIris active' : 'imgListViewIris'}></label>
                    )
                  })}
                  <label className={'imgListViewIris addImgViewIris'}>
                    <input type="file" multiple accept="image/*" onChange={onChangeMapsRight} />
                    <span>
                      <img src={camera} alt="Select img" />
                      <p>Adicionar Mapas</p>
                    </span>
                  </label>
                  <p style={{fontSize: 12, marginTop: -10}}>* Para salvar seus mapas <a href="../dashboard/business?editBusiness" target="_Blank">clique aqui</a></p>
                </div>
              </div>
              <br /><br />
            </div>
            {/* Íris Left*/}
            <div className="col-12 col-md-6">
              <div className="irisView text-center">
                <span className="labelIris">E</span>
                <TransformWrapper>
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <>
                      {openMagnifierIrisLeft ?
                        <Magnifier src={viewIrisLeft} width={"100%"} zoomImgSrc={viewIrisLeft}/>
                      : 
                        <TransformComponent>
                          <label id="imgIrisLeft" style={{ backgroundImage: `url("${viewIrisLeft}")`, backgroundSize: backgroundSizeLeft }} className={'imgIrisView'}></label>
                        </TransformComponent>
                      } 
                      {viewMapIrisLeft !== "" ?
                        <Rnd
                          style={{}}
                          className="divImgMapIrisView"
                          default={{
                            x: 250,
                            y: 120,
                            width: 300,
                            height: 300
                          }}
                        >
                          <span className="btnInvertMapColor" onClick={() => setViewMapIrisLeft("")} title="Fechar o mapa">
                            <i className="fas fa-times"></i>
                          </span>
                          <span className="btnInvertMapColor" onClick={() => setInvertMapIrisLeft(!invertMapIrisLeft)} title="Inverter a cor do mapa" style={{marginTop: 55}}>
                            <i className="fas fa-fill-drip"></i>
                          </span>
                          <label style={invertMapIrisLeft ? { backgroundImage: `url("${viewMapIrisLeft}")`, filter: "invert(1)", opacity: opacityMapIrisLeft } : { backgroundImage: `url("${viewMapIrisLeft}")`, opacity: opacityMapIrisLeft }} className={'imgMapIrisView'}></label>
                          <input type="range" min="0" max="1" step="0.1" onChange={(e) => setOpacityMapIrisLeft(e.target.value)}></input>
                        </Rnd>
                      : <></>}
                      <div className="irisViewControls">
                        <span onClick={() => zoomIn()} title="Mais Zoom">
                        <i className="fas fa-plus-circle fa-lg"></i>
                        </span>
                        <span onClick={() => zoomOut()} title="Menos Zoom">
                          <i className="fas fa-minus-circle fa-lg"></i>
                        </span>
                        <span onClick={() => resetTransform()} title="Resetar Zoom">
                          <i className="fa fa-undo-alt fa-lg"></i>
                        </span>
                        <span onClick={() => setBackgroundSizeLeft(backgroundSizeLeft === "contain" ? "cover" : "contain")} title={backgroundSizeLeft === "contain" ? "Centralizar Imagem": "Expandir Imagem"}>
                          {backgroundSizeLeft === "contain" ? <FaCompressAlt style={{marginTop: -3}}/> : <FaExpandAlt style={{marginTop: -3}} />}
                        </span>
                        {/*<span onClick={() => setOpenMagnifierIrisLeft(!openMagnifierIrisLeft)} title="Lupa">
                          <i className={openMagnifierIrisLeft ? "fa fa-search-plus fa-lg active" : "fa fa-search-plus fa-lg"}></i>
                        </span>*/}
                        <span onClick={handleFullScreenIrisLeft.enter} title="Abri em Tela Cheia">
                          <i className="fa fa-expand fa-lg"></i>
                        </span>
                      </div>
                    </>
                  )}
                </TransformWrapper>
              </div>
              <div className="row">
                <div className="form-group col-12">
                  <br /><br />
                  <p style={{marginBottom: 5, marginTop: 5, marginLeft: 5}}>Observações Íris Esquerda: </p>
                  <textarea 
                    className="form-control" 
                    style={{borderRadius: 10, paddingTop: 8}}
                    rows="4" 
                    value={formState.iris.irisLeft.observations}
                    onChange={event => {
                      setFormState({...formState,  iris: {...formState.iris, irisLeft: {...formState.iris.irisLeft, observations: event.target.value }}}) 
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p style={{marginBottom: 5, marginLeft: 5}}>Imagens Íris Esquerda: </p>
                  <div className="listViewIris">
                    {previewImagesIrisLeft.map((item, index) => {
                      return (
                        <div key={item.toString()} className="divImgListViewIris">
                          <label onClick={() => setViewIrisLeft(item)} style={{ backgroundImage: `url("${item}")` }} className={viewIrisLeft === item ? 'imgListViewIris active' : 'imgListViewIris'}>
                          </label>
                          <span className="deleteViewIris" onClick={() => deleteIrisLeft(index)}> <i className="fas fa-trash"> </i></span>
                        </div>
                      )
                    })}
                    <label className={'imgListViewIris addImgViewIris'}>
                      <input type="file" multiple accept="image/*" onChange={onChangeImagesLeft} />
                      <span>
                        <img src={camera} alt="Select img" />
                        <p>Adicionar Imagens</p>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p style={{marginBottom: 5, marginLeft: 5}}>Mapas Íris Esquerda: </p>
                  {previewMapsIrisLeft.map((item, index) => {
                    return (
                      <label key={item.toString()} onClick={() => setViewMapIrisLeft(item)} style={{ backgroundImage: `url("${item}")` }} className={viewMapIrisLeft === item ? 'imgListViewIris active' : 'imgListViewIris'}></label>
                    )
                  })}
                  <label className={'imgListViewIris addImgViewIris'}>
                    <input type="file" multiple accept="image/*" onChange={onChangeMapsLeft} />
                    <span>
                      <img src={camera} alt="Select img" />
                      <p>Adicionar Mapas</p>
                    </span>
                  </label>
                  <p style={{fontSize: 12, marginTop: -10}}>* Para salvar seus mapas <a href="../dashboard/business?editBusiness" target="_Blank">clique aqui</a></p>
                </div>
              </div>
              <br /><br />
            </div>
          </div>
          {/* FULL SCREEN IRIS RIGHT*/}
          <FullScreen handle={handleFullScreenIrisRight}>
            <TransformWrapper>
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                  <TransformComponent>
                    <label style={{ backgroundImage: `url("${viewIrisRight}"`, backgroundSize: backgroundSizeRight }} className={'imgIrisView'}></label>
                  </TransformComponent>
                  {viewMapIrisRight !== "" ?
                    <Rnd
                      style={{}}
                      className="divImgMapIrisView"
                      default={{
                        x: 350,
                        y: 180,
                        width: 500,
                        height: 500
                      }}
                    >
                      <span className="btnInvertMapColor" onClick={() => setViewMapIrisRight("")} title="Fechar o mapa">
                        <i className="fas fa-times"></i>
                      </span>
                      <span className="btnInvertMapColor" onClick={() => setInvertMapIrisRight(!invertMapIrisRight)} title="Inverter a cor do mapa" style={{marginTop: 60}}>
                        <i className="fas fa-fill-drip"></i>
                      </span>
                      <label style={invertMapIrisRight ? { backgroundImage: `url("${viewMapIrisRight}")`, filter: "invert(1)", opacity: opacityMapIrisRight } : { backgroundImage: `url("${viewMapIrisRight}")`, opacity: opacityMapIrisRight }} className={'imgMapIrisView'}></label>
                      <div className="text-center">
                        <input type="range" min="0" max="1" step="0.1" style={{minWidth: 200}} onChange={(e) => setOpacityMapIrisRight(e.target.value)}></input>
                      </div>
                    </Rnd>
                : <></>}
                  <div className="irisViewControls text-center" style={{marginTop: -45}}>
                    <span onClick={() => zoomIn()} title="Mais Zoom">
                      <i className="fas fa-plus-circle fa-lg"></i>
                    </span>
                    <span onClick={() => zoomOut()} title="Menos Zoom">
                      <i className="fas fa-minus-circle fa-lg"></i>
                    </span>
                    <span onClick={() => resetTransform()} title="Resetar Zoom">
                      <i className="fa fa-undo-alt fa-lg"></i>
                    </span>
                    <span onClick={() => setBackgroundSizeRight(backgroundSizeRight === "contain" ? "cover" : "contain")} title={backgroundSizeRight === "contain" ? "Centralizar Imagem": "Expandir Imagem"}>
                      {backgroundSizeRight === "contain" ? <FaCompressAlt style={{marginTop: -3}}/> : <FaExpandAlt style={{marginTop: -3}} />}
                    </span>
                    <span onClick={handleFullScreenIrisRight.exit} title="Fechar Tela Cheia">
                      <i className="fa fa-compress fa-lg"></i>
                    </span>
                  </div>
                </>
              )}
            </TransformWrapper>
            <button className="btnNew btnExitFullScreen" onClick={handleFullScreenIrisRight.exit}>
              <i className="fa fa-times fa-lg"></i>
            </button>
          </FullScreen>
          {/* FULL SCREEN IRIS LEFT*/}
          <FullScreen handle={handleFullScreenIrisLeft}>
            <TransformWrapper>
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                  <TransformComponent>
                    <label style={{ backgroundImage: `url("${viewIrisLeft}")`, backgroundSize: backgroundSizeLeft }} className={'imgIrisView'}></label>
                  </TransformComponent>
                  {viewMapIrisLeft !== "" ?
                    <Rnd
                      style={{}}
                      className="divImgMapIrisView"
                      default={{
                        x: 350,
                        y: 180,
                        width: 500,
                        height: 500
                      }}
                    >
                      <span className="btnInvertMapColor" onClick={() => setViewMapIrisLeft("")} title="Fechar o mapa">
                        <i className="fas fa-times"></i>
                      </span>
                      <span className="btnInvertMapColor" onClick={() => setInvertMapIrisLeft(!invertMapIrisLeft)} title="Inverter a cor do mapa" style={{marginTop: 60}}>
                        <i className="fas fa-fill-drip"></i>
                      </span>
                      <label style={invertMapIrisLeft ? { backgroundImage: `url("${viewMapIrisLeft}")`, filter: "invert(1)", opacity: opacityMapIrisLeft } : { backgroundImage: `url("${viewMapIrisLeft}")`, opacity: opacityMapIrisLeft }} className={'imgMapIrisView'}></label>
                      <div className="text-center">
                        <input type="range" min="0" max="1" step="0.1" style={{minWidth: 200}} onChange={(e) => setOpacityMapIrisLeft(e.target.value)}></input>
                      </div>
                    </Rnd>
                : <></>}
                  <div className="irisViewControls text-center" style={{marginTop: -45}}>
                    <span onClick={() => zoomIn()} title="Mais Zoom">
                      <i className="fas fa-plus-circle fa-lg"></i>
                    </span>
                    <span onClick={() => zoomOut()} title="Menos Zoom">
                      <i className="fas fa-minus-circle fa-lg"></i>
                    </span>
                    <span onClick={() => resetTransform()} title="Resetar Zoom">
                      <i className="fa fa-undo-alt fa-lg"></i>
                    </span>
                    <span onClick={() => setBackgroundSizeLeft(backgroundSizeLeft === "contain" ? "cover" : "contain")} title={backgroundSizeLeft === "contain" ? "Centralizar Imagem": "Expandir Imagem"}>
                      {backgroundSizeLeft === "contain" ? <FaCompressAlt style={{marginTop: -3}}/> : <FaExpandAlt style={{marginTop: -3}} />}
                    </span>
                    <span onClick={handleFullScreenIrisLeft.exit} title="Fechar Tela Cheia">
                      <i className="fa fa-compress fa-lg"></i>
                    </span>
                  </div>
                </>
              )}
            </TransformWrapper>
            <button className="btnNew btnExitFullScreen" onClick={handleFullScreenIrisLeft.exit}>
              <i className="fa fa-times fa-lg"></i>
            </button>
          </FullScreen>
        </>
        :<></>}

        {/* Recomendações */}
        {tabIndex === 3 ?
          <div className="container" style={{marginTop: 15}}>
            <div className="row v-center">
              {/*<div className="col-8">
                <h3 style={{marginBottom: 0}}>Recomendações Terapêutica</h3>
              </div>  
              <div className="col-4 text-right">
                {/*<button type="button" onClick={() => {}} className="btn btn-default"></button>*}
              </div>*/}
              <div className="col-12" style={{marginBottom: 10}}>
                <p style={{marginBottom: 0}}>Modelos:</p>
                <div className="row">
                  {templates.map((item, index) => {
                    if(item.type === "recommendations"){
                      return (
                        <div key={index+""} className="col-md-2 col-4" style={{padding: "8px 5px"}} onClick={() => setFormState({...formState,  call: {...formState.call, recommendations: item.template }}) }>
                          <div className="itemTemplate text-center">
                            <FaFileAlt size="3em" style={{marginBottom: 8, marginTop: 8, color: "#777"}}/>
                            <p style={{marginBottom: 0, fontSize: 14}}>{item.name}</p>
                            {!item?.default || !item.default ?
                              <>
                                <span className="editItemTemplate" onClick={() => editTemplate(item)}> <i className="fas fa-pen"> </i></span>
                                <span className="deleteItemTemplate" onClick={() => deleteTemplate(index)}> <i className="fas fa-trash"> </i></span>
                              </>
                            : <></>}
                          </div>
                        </div>
                      )
                    }
                  })}
                </div> 
              </div> 
              <Editor
                apiKey="8sgies4ow7n30jtd3r5hdhcgfxk4fprpkyo4ah22182lmotb"
                onInit={(evt, editor) => editorRecommendationsRef.current = editor}
                //initialValue={formState.call?.recommendations ? formState.call.recommendations : "<p>Informe aqui as recomendações para o seu paciente...</p>"}
                init={{
                  height: 600,
                  width: '100%',
                  menubar: true,
                  language: 'pt_BR',
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
                value={formState.call.recommendations}
                onEditorChange={(value, editor) => { 
                  setFormState({...formState,  call: {...formState.call, recommendations: value }}) 
                }}
              />
            </div>  
            <br />
            {formState.call?.recommendations && formState.call.recommendations !== "" ?
              <div className="row">
                <div className="col-12 text-right">
                  <button type="button" onClick={() => onSaveNewTemplate()} className="btn btn-default"> Salvar Modelo Personalizado</button>
                  <br /><br />
                </div> 
              </div> 
            : <></>}
          </div>  
        : <></>}

        {/* Laudos */}
        {tabIndex === 4 ?
          <div className="container" style={{marginTop: 15}}>
            <div className="row v-center">
              {/*<div className="col-8">
                <h3 style={{marginBottom: 0}}>Recomendações Terapêutica</h3>
              </div>  
              <div className="col-4 text-right">
                {/*<button type="button" onClick={() => {}} className="btn btn-default"></button>*}
              </div>*/}
              <div className="col-12" style={{marginBottom: 10}}>
                <p style={{marginBottom: 0}}>Modelos:</p>
                <div className="row">
                  {templates.map((item, index) => {
                    if(item.type === "reports"){
                      return (
                        <div key={index+""} className="col-md-2 col-4" style={{padding: "8px 5px"}} onClick={() => setFormState({...formState,  call: {...formState.call, reports: item.template }}) }>
                          <div className="itemTemplate text-center">
                            <FaFileAlt size="3em" style={{marginBottom: 8, marginTop: 8, color: "#777"}}/>
                            <p style={{marginBottom: 0, fontSize: 14}}>{item.name}</p>
                            {!item?.default || !item.default ?
                              <>
                                <span className="editItemTemplate" onClick={() => editTemplate(item)}> <i className="fas fa-pen"> </i></span>
                                <span className="deleteItemTemplate" onClick={() => deleteTemplate(index)}> <i className="fas fa-trash"> </i></span>
                              </>
                            : <></>}
                          </div>
                        </div>
                      )
                    }
                  })}
                </div> 
              </div> 
              <Editor
                apiKey="8sgies4ow7n30jtd3r5hdhcgfxk4fprpkyo4ah22182lmotb"
                onInit={(evt, editor) => editorReportsRef.current = editor}
                init={{
                  height: 600,
                  width: '100%',
                  menubar: true,
                  language: 'pt_BR',
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
                value={formState.call.reports}
                onEditorChange={(value, editor) => { 
                  setFormState({...formState,  call: {...formState.call, reports: value }}) 
                }}
              />
            </div>  
            <br />
            {formState.call?.reports && formState.call.reports !== "" ?
              <div className="row">
                <div className="col-12 text-right">
                  <button type="button" onClick={() => onSaveNewTemplate()} className="btn btn-default"> Salvar Modelo Personalizado</button>
                  <br /><br />
                </div> 
              </div> 
            : <></>}
          </div>  
        : <></>}

        {/* Observações */}
        {tabIndex === 5 ?
          <div className="container" style={{marginTop: 25}}>
            <div className="row v-center">
              <div className="col-12">
                <h3 style={{marginBottom: 0}}>Observações Gerais</h3>
              </div>  
              <div className="form-group col-12">
                <hr />
                <textarea 
                  className="form-control" 
                  style={{borderRadius: 10, paddingTop: 8}}
                  rows="10" 
                  value={formState.call.observations}
                  onChange={event => {
                    setFormState({...formState, call: {...formState.call, observations: event.target.value }}) 
                  }}
                />
              </div>
            </div>
            <br />
          </div>
        :<></>}
      </div>

      <Modal
        isOpen={showModal}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => {setShowModal(!showModal)}}
        style={customStylesModal}
        contentLabel="Alterar Minha Senha"
        >
          <button className="btnCloseModal" onClick={() => {setShowModal(!showModal)}}>✘</button>
          <div className="form-group col-12 text-center">
            <br />
            <h4 style={{marginBottom: '1.5rem'}}><b>Novo Template</b></h4>
          </div> 
          <form onSubmit={saveTemplate}>
            <div className="form-group col-12">
              <label className="labelInput">Nome do Template: </label>
              <input 
                type="text" 
                className="form-control" 
                placeholder="Defina um nome para o novo template"
                value={nameTemplate}
                onChange={event => setNameTemplate(event.target.value)}
              />
            </div>
            <br />
            <div className="form-group col-12 text-center">
              <button type="submit" className="btn btn-default"> Salvar Template </button>
            </div>
          </form>
        </Modal>
    </div>
  );
};

export default EvaluateIris;
