function templateWhite(title, business, customer, user, date){
    var img = business?.thumbnail ? business.thumbnail : "https://storage.googleapis.com/e-iris-businesses/logo.png";
    var address = business.address?.street && business.address.street !== "" ? business.address.street + ", " + business.address.number + ", " + business.address.district + ", " + business.address.city + " - " + business.address.state : "";
    return `
    <div style="display: flex; flex-wrap: wrap; align-items: center; align-content: center; padding: 5px;">
      <div style="width: 15%; text-align: center;">
        <img src="`+ img +`" width="100%"/>
      </div>
      <div style="width: 70%; text-align: center;">
        <h2 style="margin-bottom: 15px;">`+title+`</h2>
        <p style="margin: 0px;">Clínica `+business.name+`</p>
        <p style="margin: 0px;">Naturopata `+user.name+`</p>
      </div>
      <div style="width: 15%; text-align: center;">
        <h3 style="margin-bottom: 0px;"> `+date+` </h3>
      </div>
    </div>
    <hr style="margin: 10px 0px; border: 0;border-top: 1px solid rgba(0,0,0,.1);"/>
    <div style="display: flex; flex-wrap: wrap; align-items: center; align-content: center; padding: 5px;">
      <div style="width: 80%;">Nome: <b>`+customer.name+`</b></div>
      <div style="width: 20%; text-align: right;"> </div>
    </div>
    <hr style="margin: 10px 0px; border: 0;border-top: 1px solid rgba(0,0,0,.1);"/>
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <hr style="margin: 10px 0px; border: 0; border-top: 1px solid rgba(0,0,0,.1);"/>
    <div style="display: flex; flex-wrap: wrap; align-items: center; align-content: center; padding: 5px;">
      <div style="width: 100%; text-align: center;">
        <p style="margin: 0px;">Clínica `+business.name+`</p>
        <p style="margin: 0px;">`+business.phone+` - `+business.email+`</p>
        <p style="margin: 0px;">`+ address + `</p>
      </div>
    </div>
    `
}

function templateAnamneseDefault(title, business, customer, user, date){
    var img = business?.thumbnail ? business.thumbnail : "https://storage.googleapis.com/e-iris-businesses/logo.png";
    var address = business.address?.street && business.address.street !== "" ? business.address.street + ", " + business.address.number + ", " + business.address.district + ", " + business.address.city + " - " + business.address.state : "";
    return `
    <div style="display: flex; flex-wrap: wrap; align-items: center; align-content: center; padding: 5px;">
      <div style="width: 15%; text-align: center;">
        <img src="`+ img +`" width="100%"/>
      </div>
      <div style="width: 70%; text-align: center;">
        <h2 style="margin-bottom: 15px;">`+title+`</h2>
        <p style="margin: 0px;">Clínica `+business.name+`</p>
        <p style="margin: 0px;">Naturopata `+user.name+`</p>
      </div>
      <div style="width: 15%; text-align: center;">
        <h3 style="margin-bottom: 0px;"> `+date+` </h3>
      </div>
    </div>
    <hr style="margin: 10px 0px; border: 0;border-top: 1px solid rgba(0,0,0,.1);"/>
    <div style="display: flex; flex-wrap: wrap; align-items: center; align-content: center; padding: 5px;">
      <div style="width: 80%;">Nome: <b>`+customer.name+`</b></div>
      <div style="width: 20%; text-align: right;"> </div>
    </div>
    <hr style="margin: 10px 0px; border: 0;border-top: 1px solid rgba(0,0,0,.1);"/>
    <table style="width: 100%; height: 296.75px;">
    <tbody>
    <tr style="height: 47.5938px;">
    <td style="width: 98.607%; height: 47.5938px;">
    <p><strong>Queixas:</strong></p>
    </td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 98.607%; height: 47.5938px;">
    <p>&nbsp;</p>
    </td>
    </tr>
    <tr style="height: 67.1875px;">
    <td style="width: 98.607%; height: 67.1875px;">
    <p><strong>Medicamentos e Suplementos </strong><strong>(Vitaminais, minerais) de Uso atual, tratamentos anteriores, outras doen&ccedil;as em tratamento, foi submetido a alguma Cirurgia, se sim h&aacute; quanto tempo.</strong></p>
    </td>
    </tr>
    <tr style="height: 134.375px;">
    <td style="width: 98.607%; height: 134.375px;"><br />
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    </td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <p><strong><em>Responda as Quest&otilde;es abaixo, o Melhor que puder</em></strong><em><span style="font-weight: 400;">,&nbsp;</span></em></p>
    <p><em><span style="font-weight: 400;">Segundo o esquema:&nbsp;</span></em></p>
    <p><em><span style="font-weight: 400;">Sp = S</span></em><strong><em>empre</em></strong><em><span style="font-weight: 400;">;</span></em></p>
    <p><em><span style="font-weight: 400;">Fq = </span></em><strong><em>Frequente</em></strong><em><span style="font-weight: 400;">;</span></em></p>
    <p><em><span style="font-weight: 400;">Av = </span></em><strong><em>As vezes</em></strong><em><span style="font-weight: 400;">;</span></em></p>
    <p><em><span style="font-weight: 400;">Rr = </span></em><strong><em>Raro</em></strong><em><span style="font-weight: 400;">;</span></em></p>
    <p><em><span style="font-weight: 400;">Nc = </span></em><strong><em>Nunca</em></strong><em><span style="font-weight: 400;">.</span></em></p>
    <p><strong>S&Iacute;NDROMES DE FADIGA CR&Ocirc;NICA</strong></p>
    <table style="width: 99.8115%;">
    <tbody>
    <tr>
    <td style="width: 66.6029%;">
    <p><em><span style="font-weight: 400;">Voc&ecirc; tem </span></em><strong><em>Fadiga ou Cansa&ccedil;o</em></strong><em><span style="font-weight: 400;">?</span></em></p>
    <p><span style="font-weight: 400;">(&nbsp; x&nbsp; )Sim</span><span style="font-weight: 400;"> </span><span style="font-weight: 400;"> </span></p>
    <p><span style="font-weight: 400;">(&nbsp; &nbsp; )N&atilde;o </span><strong><em>[passe adiante]</em></strong></p>
    <p><strong>Defici&ecirc;ncia de Qi do Pulm&atilde;o e Ba&ccedil;o-p&acirc;ncreas</strong></p>
    </td>
    <td style="width: 6.98565%; text-align: center;">
    <p><strong>Sp</strong></p>
    </td>
    <td style="width: 6.60287%; text-align: center;">
    <p><strong>Fq</strong></p>
    </td>
    <td style="width: 6.69856%; text-align: center;">
    <p><strong>Av</strong></p>
    </td>
    <td style="width: 6.1244%; text-align: center;">
    <p><strong>Rr</strong></p>
    </td>
    <td style="width: 6.98565%; text-align: center;">
    <p><strong>Nc</strong></p>
    </td>
    </tr>
    <tr>
    <td style="width: 66.6029%;">
    <p><span style="font-weight: 400;">Fadiga ou cansa&ccedil;o?</span></p>
    </td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    <td style="width: 6.60287%; text-align: center;">&nbsp;</td>
    <td style="width: 6.69856%; text-align: center;">&nbsp;</td>
    <td style="width: 6.1244%; text-align: center;">&nbsp;</td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 66.6029%;">
    <p><span style="font-weight: 400;">Leve falta de ar?</span></p>
    </td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    <td style="width: 6.60287%; text-align: center;">&nbsp;</td>
    <td style="width: 6.69856%; text-align: center;">&nbsp;</td>
    <td style="width: 6.1244%; text-align: center;">&nbsp;</td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 66.6029%;">
    <p><span style="font-weight: 400;">Voz fraca?</span></p>
    </td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    <td style="width: 6.60287%; text-align: center;">&nbsp;</td>
    <td style="width: 6.69856%; text-align: center;">&nbsp;</td>
    <td style="width: 6.1244%; text-align: center;">&nbsp;</td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 66.6029%;">
    <p><span style="font-weight: 400;">Rosto P&aacute;lido ou branco?</span></p>
    </td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    <td style="width: 6.60287%; text-align: center;">&nbsp;</td>
    <td style="width: 6.69856%; text-align: center;">&nbsp;</td>
    <td style="width: 6.1244%; text-align: center;">&nbsp;</td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 66.6029%;">
    <p><span style="font-weight: 400;">Transpira&ccedil;&atilde;o espont&acirc;nea?</span></p>
    </td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    <td style="width: 6.60287%; text-align: center;">&nbsp;</td>
    <td style="width: 6.69856%; text-align: center;">&nbsp;</td>
    <td style="width: 6.1244%; text-align: center;">&nbsp;</td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 66.6029%;">
    <p><span style="font-weight: 400;">Resfriados frequentes?</span></p>
    </td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    <td style="width: 6.60287%; text-align: center;">&nbsp;</td>
    <td style="width: 6.69856%; text-align: center;">&nbsp;</td>
    <td style="width: 6.1244%; text-align: center;">&nbsp;</td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 66.6029%;">
    <p><span style="font-weight: 400;">Timidez?</span></p>
    </td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    <td style="width: 6.60287%; text-align: center;">&nbsp;</td>
    <td style="width: 6.69856%; text-align: center;">&nbsp;</td>
    <td style="width: 6.1244%; text-align: center;">&nbsp;</td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 66.6029%;">
    <p><span style="font-weight: 400;">L&iacute;ngua levemente p&aacute;lida?</span></p>
    </td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    <td style="width: 6.60287%; text-align: center;">&nbsp;</td>
    <td style="width: 6.69856%; text-align: center;">&nbsp;</td>
    <td style="width: 6.1244%; text-align: center;">&nbsp;</td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 66.6029%;">
    <p><span style="font-weight: 400;">Cansa&ccedil;o piora pela manh&atilde;?</span></p>
    </td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    <td style="width: 6.60287%; text-align: center;">&nbsp;</td>
    <td style="width: 6.69856%; text-align: center;">&nbsp;</td>
    <td style="width: 6.1244%; text-align: center;">&nbsp;</td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 66.6029%;">
    <p><span style="font-weight: 400;">Dor muscular?</span></p>
    </td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    <td style="width: 6.60287%; text-align: center;">&nbsp;</td>
    <td style="width: 6.69856%; text-align: center;">&nbsp;</td>
    <td style="width: 6.1244%; text-align: center;">&nbsp;</td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 66.6029%;">
    <p><span style="font-weight: 400;">Falta de apetite?</span></p>
    </td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    <td style="width: 6.60287%; text-align: center;">&nbsp;</td>
    <td style="width: 6.69856%; text-align: center;">&nbsp;</td>
    <td style="width: 6.1244%; text-align: center;">&nbsp;</td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 66.6029%;">
    <p><span style="font-weight: 400;">Distens&atilde;o ou &ldquo; incha&ccedil;o da barriga&rdquo;?</span></p>
    </td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    <td style="width: 6.60287%; text-align: center;">&nbsp;</td>
    <td style="width: 6.69856%; text-align: center;">&nbsp;</td>
    <td style="width: 6.1244%; text-align: center;">&nbsp;</td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 66.6029%;">
    <p><span style="font-weight: 400;">Fezes amolecidas?</span></p>
    </td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    <td style="width: 6.60287%; text-align: center;">&nbsp;</td>
    <td style="width: 6.69856%; text-align: center;">&nbsp;</td>
    <td style="width: 6.1244%; text-align: center;">&nbsp;</td>
    <td style="width: 6.98565%; text-align: center;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="width: 100.094%;">
    <tbody>
    <tr>
    <td style="width: 66.2252%;">
    <p><strong><em>Defici&ecirc;ncia de Yang do Ba&ccedil;o e Rim e (Hipotireoidismo)</em></strong></p>
    </td>
    <td style="width: 7.19016%; text-align: center;">
    <p><strong>Sp</strong></p>
    </td>
    <td style="width: 6.62252%; text-align: center;">
    <p><strong>Fq</strong></p>
    </td>
    <td style="width: 6.52791%; text-align: center;">
    <p><strong>Av</strong></p>
    </td>
    <td style="width: 6.05487%; text-align: center;">
    <p><strong>Rr</strong></p>
    </td>
    <td style="width: 7.37938%; text-align: center;">
    <p><strong>Nc</strong></p>
    </td>
    </tr>
    <tr>
    <td style="width: 66.2252%;">
    <p><span style="font-weight: 400;">Dor nas costas, regi&atilde;o lombar?</span></p>
    </td>
    <td style="width: 7.19016%; text-align: center;">&nbsp;</td>
    <td style="width: 6.62252%; text-align: center;">&nbsp;</td>
    <td style="width: 6.52791%; text-align: center;">&nbsp;</td>
    <td style="width: 6.05487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.37938%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 66.2252%;">
    <p><span style="font-weight: 400;">Dor ou fraqueza nos joelhos?</span></p>
    </td>
    <td style="width: 7.19016%; text-align: center;">&nbsp;</td>
    <td style="width: 6.62252%; text-align: center;">&nbsp;</td>
    <td style="width: 6.52791%; text-align: center;">&nbsp;</td>
    <td style="width: 6.05487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.37938%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 66.2252%;">
    <p><span style="font-weight: 400;">Urina p&aacute;lida?</span></p>
    </td>
    <td style="width: 7.19016%; text-align: center;">&nbsp;</td>
    <td style="width: 6.62252%; text-align: center;">&nbsp;</td>
    <td style="width: 6.52791%; text-align: center;">&nbsp;</td>
    <td style="width: 6.05487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.37938%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 66.2252%;">
    <p><span style="font-weight: 400;">Vontade de Urinar frequente?</span></p>
    </td>
    <td style="width: 7.19016%; text-align: center;">&nbsp;</td>
    <td style="width: 6.62252%; text-align: center;">&nbsp;</td>
    <td style="width: 6.52791%; text-align: center;">&nbsp;</td>
    <td style="width: 6.05487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.37938%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 66.2252%;">
    <p><span style="font-weight: 400;">Tontura?</span></p>
    </td>
    <td style="width: 7.19016%; text-align: center;">&nbsp;</td>
    <td style="width: 6.62252%; text-align: center;">&nbsp;</td>
    <td style="width: 6.52791%; text-align: center;">&nbsp;</td>
    <td style="width: 6.05487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.37938%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 66.2252%;">
    <p><span style="font-weight: 400;">Zumbido no ouvido?</span></p>
    </td>
    <td style="width: 7.19016%; text-align: center;">&nbsp;</td>
    <td style="width: 6.62252%; text-align: center;">&nbsp;</td>
    <td style="width: 6.52791%; text-align: center;">&nbsp;</td>
    <td style="width: 6.05487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.37938%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 66.2252%;">
    <p><span style="font-weight: 400;">Sensa&ccedil;&atilde;o de frio</span></p>
    </td>
    <td style="width: 7.19016%; text-align: center;">&nbsp;</td>
    <td style="width: 6.62252%; text-align: center;">&nbsp;</td>
    <td style="width: 6.52791%; text-align: center;">&nbsp;</td>
    <td style="width: 6.05487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.37938%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 66.2252%;">
    <p><span style="font-weight: 400;">P&eacute;s frios</span></p>
    </td>
    <td style="width: 7.19016%; text-align: center;">&nbsp;</td>
    <td style="width: 6.62252%; text-align: center;">&nbsp;</td>
    <td style="width: 6.52791%; text-align: center;">&nbsp;</td>
    <td style="width: 6.05487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.37938%; text-align: center;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="width: 100.283%; height: 380.75px;">
    <tbody>
    <tr style="height: 47.5938px;">
    <td style="width: 66.2252%; height: 47.5938px;">
    <p><strong><em>Defici&ecirc;ncia de Sangue do Gan F&iacute;gado</em></strong></p>
    </td>
    <td style="width: 7.00095%; height: 47.5938px; text-align: center;">
    <p><strong>Sp</strong></p>
    </td>
    <td style="width: 6.71712%; height: 47.5938px; text-align: center;">
    <p><strong>Fq</strong></p>
    </td>
    <td style="width: 6.71712%; height: 47.5938px; text-align: center;">
    <p><strong>Av</strong></p>
    </td>
    <td style="width: 6.33869%; height: 47.5938px; text-align: center;">
    <p><strong>Rr</strong></p>
    </td>
    <td style="width: 7.00095%; height: 47.5938px; text-align: center;">
    <p><strong>Nc</strong></p>
    </td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 66.2252%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Cansa&ccedil;o muscular depois de exerc&iacute;cio leve?</span></p>
    </td>
    <td style="width: 7.00095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.71712%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.71712%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.33869%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.00095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 66.2252%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Vis&atilde;o turva?</span></p>
    </td>
    <td style="width: 7.00095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.71712%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.71712%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.33869%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.00095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 66.2252%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Formigamento ou dorm&ecirc;ncia nas m&atilde;os ou p&eacute;s?</span></p>
    </td>
    <td style="width: 7.00095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.71712%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.71712%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.33869%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.00095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 66.2252%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Enxerga pontos brilhantes</span></p>
    </td>
    <td style="width: 7.00095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.71712%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.71712%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.33869%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.00095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 66.2252%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Tonturas?</span></p>
    </td>
    <td style="width: 7.00095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.71712%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.71712%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.33869%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.00095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 66.2252%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Mem&oacute;ria Fraca?</span></p>
    </td>
    <td style="width: 7.00095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.71712%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.71712%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.33869%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.00095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 66.2252%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Anemia?</span></p>
    </td>
    <td style="width: 7.00095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.71712%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.71712%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.33869%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.00095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="width: 100.377%;">
    <tbody>
    <tr>
    <td style="width: 65.6883%;">
    <p><strong><em>Defici&ecirc;ncia de Yin do Fei Pulm&atilde;o</em></strong></p>
    </td>
    <td style="width: 7.13887%; text-align: center;">
    <p><strong>Sp</strong></p>
    </td>
    <td style="width: 7.13887%; text-align: center;">
    <p><strong>Fq</strong></p>
    </td>
    <td style="width: 6.28647%; text-align: center;">
    <p><strong>Av</strong></p>
    </td>
    <td style="width: 7.03232%; text-align: center;">
    <p><strong>Rr</strong></p>
    </td>
    <td style="width: 6.71267%; text-align: center;">
    <p><strong>Nc</strong></p>
    </td>
    </tr>
    <tr>
    <td style="width: 65.6883%;">
    <p><span style="font-weight: 400;">Garganta seca?</span></p>
    </td>
    <td style="width: 7.13887%; text-align: center;">&nbsp;</td>
    <td style="width: 7.13887%; text-align: center;">&nbsp;</td>
    <td style="width: 6.28647%; text-align: center;">&nbsp;</td>
    <td style="width: 7.03232%; text-align: center;">&nbsp;</td>
    <td style="width: 6.71267%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 65.6883%;">
    <p><span style="font-weight: 400;">Tosse seca?</span></p>
    </td>
    <td style="width: 7.13887%; text-align: center;">&nbsp;</td>
    <td style="width: 7.13887%; text-align: center;">&nbsp;</td>
    <td style="width: 6.28647%; text-align: center;">&nbsp;</td>
    <td style="width: 7.03232%; text-align: center;">&nbsp;</td>
    <td style="width: 6.71267%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 65.6883%;">
    <p><span style="font-weight: 400;">Cansa&ccedil;o extremo e exaust&atilde;o ?</span></p>
    </td>
    <td style="width: 7.13887%; text-align: center;">&nbsp;</td>
    <td style="width: 7.13887%; text-align: center;">&nbsp;</td>
    <td style="width: 6.28647%; text-align: center;">&nbsp;</td>
    <td style="width: 7.03232%; text-align: center;">&nbsp;</td>
    <td style="width: 6.71267%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 65.6883%;">
    <p><span style="font-weight: 400;">Falta de ar?</span></p>
    </td>
    <td style="width: 7.13887%; text-align: center;">&nbsp;</td>
    <td style="width: 7.13887%; text-align: center;">&nbsp;</td>
    <td style="width: 6.28647%; text-align: center;">&nbsp;</td>
    <td style="width: 7.03232%; text-align: center;">&nbsp;</td>
    <td style="width: 6.71267%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 65.6883%;">
    <p><span style="font-weight: 400;">Voz rouca?</span></p>
    </td>
    <td style="width: 7.13887%; text-align: center;">&nbsp;</td>
    <td style="width: 7.13887%; text-align: center;">&nbsp;</td>
    <td style="width: 6.28647%; text-align: center;">&nbsp;</td>
    <td style="width: 7.03232%; text-align: center;">&nbsp;</td>
    <td style="width: 6.71267%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 65.6883%;">
    <p><span style="font-weight: 400;">Sensa&ccedil;&atilde;o de Calor no final da tarde?</span></p>
    </td>
    <td style="width: 7.13887%; text-align: center;">&nbsp;</td>
    <td style="width: 7.13887%; text-align: center;">&nbsp;</td>
    <td style="width: 6.28647%; text-align: center;">&nbsp;</td>
    <td style="width: 7.03232%; text-align: center;">&nbsp;</td>
    <td style="width: 6.71267%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 65.6883%;">
    <p><span style="font-weight: 400;">Transpira&ccedil;&atilde;o noturna?</span></p>
    </td>
    <td style="width: 7.13887%; text-align: center;">&nbsp;</td>
    <td style="width: 7.13887%; text-align: center;">&nbsp;</td>
    <td style="width: 6.28647%; text-align: center;">&nbsp;</td>
    <td style="width: 7.03232%; text-align: center;">&nbsp;</td>
    <td style="width: 6.71267%; text-align: center;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="width: 100.283%; height: 618.719px;">
    <tbody>
    <tr style="height: 47.5938px;">
    <td style="width: 65.2869%; height: 47.5938px;">
    <p><strong><em>Defici&ecirc;ncia de Yin do Shen Rim</em></strong></p>
    </td>
    <td style="width: 6.96143%; height: 47.5938px; text-align: center;">
    <p><strong>Sp</strong></p>
    </td>
    <td style="width: 6.96187%; height: 47.5938px; text-align: center;">
    <p><strong>Fq</strong></p>
    </td>
    <td style="width: 6.77284%; height: 47.5938px; text-align: center;">
    <p><strong>Av</strong></p>
    </td>
    <td style="width: 7.14958%; height: 47.5938px; text-align: center;">
    <p><strong>Rr</strong></p>
    </td>
    <td style="width: 6.86736%; height: 47.5938px; text-align: center;">
    <p><strong>Nc</strong></p>
    </td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 65.2869%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Dor ou desconforto nas costas, lombar?</span></p>
    </td>
    <td style="width: 6.96143%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.96187%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.77284%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.14958%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.86736%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 65.2869%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Cansa&ccedil;o extremo ou exaust&atilde;o?</span></p>
    </td>
    <td style="width: 6.96143%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.96187%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.77284%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.14958%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.86736%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 65.2869%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Depress&atilde;o ?</span></p>
    </td>
    <td style="width: 6.96143%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.96187%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.77284%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.14958%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.86736%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 65.2869%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Falta de dire&ccedil;&atilde;o ou for&ccedil;a de vontade?</span></p>
    </td>
    <td style="width: 6.96143%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.96187%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.77284%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.14958%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.86736%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 65.2869%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Fraqueza em pernas e joelhos?</span></p>
    </td>
    <td style="width: 6.96143%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.96187%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.77284%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.14958%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.86736%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 65.2869%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Tontura?</span></p>
    </td>
    <td style="width: 6.96143%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.96187%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.77284%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.14958%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.86736%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 65.2869%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Zumbido no ouvido?</span></p>
    </td>
    <td style="width: 6.96143%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.96187%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.77284%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.14958%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.86736%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 65.2869%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Surdez?</span></p>
    </td>
    <td style="width: 6.96143%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.96187%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.77284%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.14958%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.86736%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 65.2869%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Boca e garganta secas?</span></p>
    </td>
    <td style="width: 6.96143%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.96187%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.77284%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.14958%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.86736%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 65.2869%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Sono agitado?</span></p>
    </td>
    <td style="width: 6.96143%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.96187%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.77284%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.14958%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.86736%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 65.2869%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Acorda durante &agrave; noite?</span></p>
    </td>
    <td style="width: 6.96143%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.96187%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.77284%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.14958%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.86736%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 65.2869%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Transpira&ccedil;&atilde;o noturna?</span></p>
    </td>
    <td style="width: 6.96143%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.96187%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.77284%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.14958%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.86736%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="width: 100%;">
    <tbody>
    <tr>
    <td style="width: 64.1509%;">
    <p><strong><em>Defici&ecirc;ncia de Yin do Wei Est&ocirc;mago</em></strong></p>
    </td>
    <td style="width: 6.50943%; text-align: center;">
    <p><strong>Sp</strong></p>
    </td>
    <td style="width: 7.73585%; text-align: center;">
    <p><strong>Fq</strong></p>
    </td>
    <td style="width: 7.16928%; text-align: center;">
    <p><strong>Av</strong></p>
    </td>
    <td style="width: 6.88733%; text-align: center;">
    <p><strong>Rr</strong></p>
    </td>
    <td style="width: 7.54717%; text-align: center;">
    <p><strong>Nc</strong></p>
    </td>
    </tr>
    <tr>
    <td style="width: 64.1509%;">
    <p><span style="font-weight: 400;">Boca seca?</span></p>
    </td>
    <td style="width: 6.50943%; text-align: center;">&nbsp;</td>
    <td style="width: 7.73585%; text-align: center;">&nbsp;</td>
    <td style="width: 7.16928%; text-align: center;">&nbsp;</td>
    <td style="width: 6.88733%; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.1509%;">
    <p><span style="font-weight: 400;">Falta de apetite?</span></p>
    </td>
    <td style="width: 6.50943%; text-align: center;">&nbsp;</td>
    <td style="width: 7.73585%; text-align: center;">&nbsp;</td>
    <td style="width: 7.16928%; text-align: center;">&nbsp;</td>
    <td style="width: 6.88733%; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.1509%;">
    <p><span style="font-weight: 400;">Cansa&ccedil;o?</span></p>
    </td>
    <td style="width: 6.50943%; text-align: center;">&nbsp;</td>
    <td style="width: 7.73585%; text-align: center;">&nbsp;</td>
    <td style="width: 7.16928%; text-align: center;">&nbsp;</td>
    <td style="width: 6.88733%; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.1509%;">
    <p><span style="font-weight: 400;">Fezes secas?</span></p>
    </td>
    <td style="width: 6.50943%; text-align: center;">&nbsp;</td>
    <td style="width: 7.73585%; text-align: center;">&nbsp;</td>
    <td style="width: 7.16928%; text-align: center;">&nbsp;</td>
    <td style="width: 6.88733%; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.1509%;">
    <p><span style="font-weight: 400;">Dor de est&ocirc;mago moderada?</span></p>
    </td>
    <td style="width: 6.50943%; text-align: center;">&nbsp;</td>
    <td style="width: 7.73585%; text-align: center;">&nbsp;</td>
    <td style="width: 7.16928%; text-align: center;">&nbsp;</td>
    <td style="width: 6.88733%; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.1509%;">
    <p><span style="font-weight: 400;">&ldquo;Ma&ccedil;a&rdquo; do rosto vermelha?</span></p>
    </td>
    <td style="width: 6.50943%; text-align: center;">&nbsp;</td>
    <td style="width: 7.73585%; text-align: center;">&nbsp;</td>
    <td style="width: 7.16928%; text-align: center;">&nbsp;</td>
    <td style="width: 6.88733%; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.1509%;">
    <p><span style="font-weight: 400;">Toma &aacute;gua em pequenos goles?</span></p>
    </td>
    <td style="width: 6.50943%; text-align: center;">&nbsp;</td>
    <td style="width: 7.73585%; text-align: center;">&nbsp;</td>
    <td style="width: 7.16928%; text-align: center;">&nbsp;</td>
    <td style="width: 6.88733%; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; text-align: center;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <p><strong>CALOR CHEIO / VAZIO</strong></p>
    <table style="width: 100%; height: 762.501px;">
    <tbody>
    <tr style="height: 48.5938px;">
    <td style="width: 63.3019%; height: 48.5938px;">
    <p><em><span style="font-weight: 400;">Sobre o seu </span></em><strong><em>Calor </em></strong><em><span style="font-weight: 400;">(res&iacute;duos de energia quente no organismo)</span></em></p>
    </td>
    <td style="width: 7.07547%; text-align: center; height: 48.5938px;">
    <p><strong>Sp</strong></p>
    </td>
    <td style="width: 7.45283%; text-align: center; height: 48.5938px;">
    <p><strong>Fq</strong></p>
    </td>
    <td style="width: 7.64169%; text-align: center; height: 48.5938px;">
    <p><strong>Av</strong></p>
    </td>
    <td style="width: 6.98095%; text-align: center; height: 48.5938px;">
    <p><strong>Rr</strong></p>
    </td>
    <td style="width: 7.54717%; text-align: center; height: 48.5938px;">
    <p><strong>Nc</strong></p>
    </td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 63.3019%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Boca/ Garganta SECA?</span></p>
    </td>
    <td style="width: 7.07547%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.45283%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.64169%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.98095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 63.3019%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Sente muita SEDE?</span></p>
    </td>
    <td style="width: 7.07547%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.45283%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.64169%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.98095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 63.3019%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Prefere l&iacute;quido GELADO?</span></p>
    </td>
    <td style="width: 7.07547%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.45283%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.64169%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.98095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 63.3019%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Toma GRANDES QTDES</span></p>
    </td>
    <td style="width: 7.07547%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.45283%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.64169%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.98095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 63.3019%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Olhos Vermelhos?</span></p>
    </td>
    <td style="width: 7.07547%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.45283%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.64169%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.98095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 63.3019%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Boca AMARGA?</span></p>
    </td>
    <td style="width: 7.07547%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.45283%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.64169%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.98095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 63.3019%; height: 47.5938px;">
    <p><span style="font-weight: 400;">QUEIMA&Ccedil;&Atilde;O Est&ocirc;mago</span></p>
    </td>
    <td style="width: 7.07547%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.45283%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.64169%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.98095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 63.3019%; height: 47.5938px;">
    <p><span style="font-weight: 400;">AFTAS na l&iacute;ngua</span></p>
    </td>
    <td style="width: 7.07547%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.45283%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.64169%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.98095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 63.3019%; height: 47.5938px;">
    <p><span style="font-weight: 400;">AFTAS na bochecha</span></p>
    </td>
    <td style="width: 7.07547%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.45283%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.64169%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.98095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 63.3019%; height: 47.5938px;">
    <p><span style="font-weight: 400;">SANGRAMENTO gengival</span></p>
    </td>
    <td style="width: 7.07547%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.45283%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.64169%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.98095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 63.3019%; height: 47.5938px;">
    <p><span style="font-weight: 400;">FEZES secas</span></p>
    </td>
    <td style="width: 7.07547%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.45283%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.64169%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.98095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 63.3019%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Urina CONCENTRADA?</span></p>
    </td>
    <td style="width: 7.07547%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.45283%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.64169%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.98095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 63.3019%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Urina CHEIRO FORTE?</span></p>
    </td>
    <td style="width: 7.07547%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.45283%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.64169%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.98095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 63.3019%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Les&otilde;es em PELE?</span></p>
    </td>
    <td style="width: 7.07547%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.45283%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.64169%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.98095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 63.3019%; height: 47.5938px;">
    <p><span style="font-weight: 400;">&Eacute; CALORENTO?</span></p>
    </td>
    <td style="width: 7.07547%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.45283%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.64169%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.98095%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.54717%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="width: 99.3402%;">
    <tbody>
    <tr>
    <td style="width: 64.1033%;">
    <p><em><span style="font-weight: 400;">Sobre o seu</span></em><strong><em> Frio</em></strong><em><span style="font-weight: 400;"> (res&iacute;duos de energia fria no organismo)</span></em></p>
    </td>
    <td style="width: 7.88152%; text-align: center;">
    <p><strong>Sp</strong></p>
    </td>
    <td style="width: 7.21747%; text-align: center;">
    <p><strong>Fq</strong></p>
    </td>
    <td style="width: 6.45774%; text-align: center;">
    <p><strong>Av</strong></p>
    </td>
    <td style="width: 6.93257%; text-align: center;">
    <p><strong>Rr</strong></p>
    </td>
    <td style="width: 7.40741%; text-align: center;">
    <p><strong>Nc</strong></p>
    </td>
    </tr>
    <tr>
    <td style="width: 64.1033%;">
    <p><span style="font-weight: 400;">INCHA&Ccedil;O</span><span style="font-weight: 400;"> nos p&eacute;s?</span></p>
    </td>
    <td style="width: 7.88152%; text-align: center;">&nbsp;</td>
    <td style="width: 7.21747%; text-align: center;">&nbsp;</td>
    <td style="width: 6.45774%; text-align: center;">&nbsp;</td>
    <td style="width: 6.93257%; text-align: center;">&nbsp;</td>
    <td style="width: 7.40741%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.1033%;">
    <p><span style="font-weight: 400;">M&atilde;os / p&eacute;s </span><span style="font-weight: 400;">FRIOS</span><span style="font-weight: 400;">?</span></p>
    </td>
    <td style="width: 7.88152%; text-align: center;">&nbsp;</td>
    <td style="width: 7.21747%; text-align: center;">&nbsp;</td>
    <td style="width: 6.45774%; text-align: center;">&nbsp;</td>
    <td style="width: 6.93257%; text-align: center;">&nbsp;</td>
    <td style="width: 7.40741%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.1033%;">
    <p><span style="font-weight: 400;">Toma &aacute;gua em Pequenos GOLES?</span></p>
    </td>
    <td style="width: 7.88152%; text-align: center;">&nbsp;</td>
    <td style="width: 7.21747%; text-align: center;">&nbsp;</td>
    <td style="width: 6.45774%; text-align: center;">&nbsp;</td>
    <td style="width: 6.93257%; text-align: center;">&nbsp;</td>
    <td style="width: 7.40741%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.1033%;">
    <p><span style="font-weight: 400;">Frio no </span><span style="font-weight: 400;">CORPO TODO</span><span style="font-weight: 400;">?</span></p>
    </td>
    <td style="width: 7.88152%; text-align: center;">&nbsp;</td>
    <td style="width: 7.21747%; text-align: center;">&nbsp;</td>
    <td style="width: 6.45774%; text-align: center;">&nbsp;</td>
    <td style="width: 6.93257%; text-align: center;">&nbsp;</td>
    <td style="width: 7.40741%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.1033%;">
    <p><span style="font-weight: 400;">DIARR&Eacute;IA</span><span style="font-weight: 400;">?</span></p>
    </td>
    <td style="width: 7.88152%; text-align: center;">&nbsp;</td>
    <td style="width: 7.21747%; text-align: center;">&nbsp;</td>
    <td style="width: 6.45774%; text-align: center;">&nbsp;</td>
    <td style="width: 6.93257%; text-align: center;">&nbsp;</td>
    <td style="width: 7.40741%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.1033%;">
    <p><span style="font-weight: 400;">GASES</span><span style="font-weight: 400;">?</span></p>
    </td>
    <td style="width: 7.88152%; text-align: center;">&nbsp;</td>
    <td style="width: 7.21747%; text-align: center;">&nbsp;</td>
    <td style="width: 6.45774%; text-align: center;">&nbsp;</td>
    <td style="width: 6.93257%; text-align: center;">&nbsp;</td>
    <td style="width: 7.40741%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.1033%;">
    <p><span style="font-weight: 400;">URINA FREQ&Uuml;ENTE</span><span style="font-weight: 400;">?</span></p>
    </td>
    <td style="width: 7.88152%; text-align: center;">&nbsp;</td>
    <td style="width: 7.21747%; text-align: center;">&nbsp;</td>
    <td style="width: 6.45774%; text-align: center;">&nbsp;</td>
    <td style="width: 6.93257%; text-align: center;">&nbsp;</td>
    <td style="width: 7.40741%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.1033%;">
    <p><span style="font-weight: 400;">Urina </span><span style="font-weight: 400;">CLARA</span><span style="font-weight: 400;"> como &Aacute;gua?&nbsp;</span></p>
    </td>
    <td style="width: 7.88152%; text-align: center;">&nbsp;</td>
    <td style="width: 7.21747%; text-align: center;">&nbsp;</td>
    <td style="width: 6.45774%; text-align: center;">&nbsp;</td>
    <td style="width: 6.93257%; text-align: center;">&nbsp;</td>
    <td style="width: 7.40741%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.1033%;">
    <p><span style="font-weight: 400;">&eacute; FRIORENTO?</span></p>
    </td>
    <td style="width: 7.88152%; text-align: center;">&nbsp;</td>
    <td style="width: 7.21747%; text-align: center;">&nbsp;</td>
    <td style="width: 6.45774%; text-align: center;">&nbsp;</td>
    <td style="width: 6.93257%; text-align: center;">&nbsp;</td>
    <td style="width: 7.40741%; text-align: center;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="width: 99.4345%;">
    <tbody>
    <tr>
    <td style="width: 64.5161%;">
    <p><em><span style="font-weight: 400;">Sobre suas </span></em><strong><em>EMO&Ccedil;&Otilde;ES E SENTIMENTOS</em></strong></p>
    </td>
    <td style="width: 7.30487%; text-align: center;">
    <p><strong>Sp</strong></p>
    </td>
    <td style="width: 7.49589%; text-align: center;">
    <p><strong>Fq</strong></p>
    </td>
    <td style="width: 6.83112%; text-align: center;">
    <p><strong>Av</strong></p>
    </td>
    <td style="width: 6.54649%; text-align: center;">
    <p><strong>Rr</strong></p>
    </td>
    <td style="width: 7.3055%; text-align: center;">
    <p><strong>Nc</strong></p>
    </td>
    </tr>
    <tr>
    <td style="width: 64.5161%;">
    <p><span style="font-weight: 400;">&eacute; ANSIOSO?</span></p>
    </td>
    <td style="width: 7.30487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.49589%; text-align: center;">&nbsp;</td>
    <td style="width: 6.83112%; text-align: center;">&nbsp;</td>
    <td style="width: 6.54649%; text-align: center;">&nbsp;</td>
    <td style="width: 7.3055%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.5161%;">
    <p><span style="font-weight: 400;">&eacute; AGITADO?</span></p>
    </td>
    <td style="width: 7.30487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.49589%; text-align: center;">&nbsp;</td>
    <td style="width: 6.83112%; text-align: center;">&nbsp;</td>
    <td style="width: 6.54649%; text-align: center;">&nbsp;</td>
    <td style="width: 7.3055%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.5161%;">
    <p><span style="font-weight: 400;">&eacute; TRISTE?</span></p>
    </td>
    <td style="width: 7.30487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.49589%; text-align: center;">&nbsp;</td>
    <td style="width: 6.83112%; text-align: center;">&nbsp;</td>
    <td style="width: 6.54649%; text-align: center;">&nbsp;</td>
    <td style="width: 7.3055%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.5161%;">
    <p><span style="font-weight: 400;">tem CHORO F&Aacute;CIL?</span></p>
    </td>
    <td style="width: 7.30487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.49589%; text-align: center;">&nbsp;</td>
    <td style="width: 6.83112%; text-align: center;">&nbsp;</td>
    <td style="width: 6.54649%; text-align: center;">&nbsp;</td>
    <td style="width: 7.3055%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.5161%;">
    <p><span style="font-weight: 400;">DESANIMADO?</span></p>
    </td>
    <td style="width: 7.30487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.49589%; text-align: center;">&nbsp;</td>
    <td style="width: 6.83112%; text-align: center;">&nbsp;</td>
    <td style="width: 6.54649%; text-align: center;">&nbsp;</td>
    <td style="width: 7.3055%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.5161%;">
    <p><span style="font-weight: 400;">Sente-se MAGOADO?</span></p>
    </td>
    <td style="width: 7.30487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.49589%; text-align: center;">&nbsp;</td>
    <td style="width: 6.83112%; text-align: center;">&nbsp;</td>
    <td style="width: 6.54649%; text-align: center;">&nbsp;</td>
    <td style="width: 7.3055%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.5161%;">
    <p><span style="font-weight: 400;">guarda RANCORES E SENTE RAIVA?</span></p>
    </td>
    <td style="width: 7.30487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.49589%; text-align: center;">&nbsp;</td>
    <td style="width: 6.83112%; text-align: center;">&nbsp;</td>
    <td style="width: 6.54649%; text-align: center;">&nbsp;</td>
    <td style="width: 7.3055%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.5161%;">
    <p><span style="font-weight: 400;">&eacute; PREOCUPADO?</span></p>
    </td>
    <td style="width: 7.30487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.49589%; text-align: center;">&nbsp;</td>
    <td style="width: 6.83112%; text-align: center;">&nbsp;</td>
    <td style="width: 6.54649%; text-align: center;">&nbsp;</td>
    <td style="width: 7.3055%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.5161%;">
    <p><span style="font-weight: 400;">Passa muito tempo AVALIANDO as situa&ccedil;&otilde;es?</span></p>
    </td>
    <td style="width: 7.30487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.49589%; text-align: center;">&nbsp;</td>
    <td style="width: 6.83112%; text-align: center;">&nbsp;</td>
    <td style="width: 6.54649%; text-align: center;">&nbsp;</td>
    <td style="width: 7.3055%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.5161%;">
    <p><span style="font-weight: 400;">&eacute; INSEGURO?</span></p>
    </td>
    <td style="width: 7.30487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.49589%; text-align: center;">&nbsp;</td>
    <td style="width: 6.83112%; text-align: center;">&nbsp;</td>
    <td style="width: 6.54649%; text-align: center;">&nbsp;</td>
    <td style="width: 7.3055%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.5161%;">
    <p><span style="font-weight: 400;">Entra em P&Acirc;NICO?</span></p>
    </td>
    <td style="width: 7.30487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.49589%; text-align: center;">&nbsp;</td>
    <td style="width: 6.83112%; text-align: center;">&nbsp;</td>
    <td style="width: 6.54649%; text-align: center;">&nbsp;</td>
    <td style="width: 7.3055%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.5161%;">
    <p><span style="font-weight: 400;">Tem FOBIAS (MEDOS)?</span></p>
    </td>
    <td style="width: 7.30487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.49589%; text-align: center;">&nbsp;</td>
    <td style="width: 6.83112%; text-align: center;">&nbsp;</td>
    <td style="width: 6.54649%; text-align: center;">&nbsp;</td>
    <td style="width: 7.3055%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.5161%;">
    <p><span style="font-weight: 400;">Compuls&atilde;o por COMER?</span></p>
    </td>
    <td style="width: 7.30487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.49589%; text-align: center;">&nbsp;</td>
    <td style="width: 6.83112%; text-align: center;">&nbsp;</td>
    <td style="width: 6.54649%; text-align: center;">&nbsp;</td>
    <td style="width: 7.3055%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.5161%;">
    <p><span style="font-weight: 400;">Desejo DOCES?</span></p>
    </td>
    <td style="width: 7.30487%; text-align: center;">&nbsp;</td>
    <td style="width: 7.49589%; text-align: center;">&nbsp;</td>
    <td style="width: 6.83112%; text-align: center;">&nbsp;</td>
    <td style="width: 6.54649%; text-align: center;">&nbsp;</td>
    <td style="width: 7.3055%; text-align: center;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="width: 99.623%;">
    <tbody>
    <tr>
    <td style="width: 64.3002%;">
    <p><em><span style="font-weight: 400;">Sobre o </span></em><strong><em>Ba&ccedil;o- P&acirc;ncreas</em></strong><em><span style="font-weight: 400;">&nbsp;</span></em></p>
    <p><em><span style="font-weight: 400;">(a energia/funcionamento do &oacute;rg&atilde;o)</span></em></p>
    </td>
    <td style="width: 7.57587%; text-align: center;">
    <p><strong>Sp</strong></p>
    </td>
    <td style="width: 7.00768%; text-align: center;">
    <p><strong>Fq</strong></p>
    </td>
    <td style="width: 7.00768%; text-align: center;">
    <p><strong>Av</strong></p>
    </td>
    <td style="width: 6.72358%; text-align: center;">
    <p><strong>Rr</strong></p>
    </td>
    <td style="width: 7.29177%; text-align: center;">
    <p><strong>Nc</strong></p>
    </td>
    </tr>
    <tr>
    <td style="width: 64.3002%;">
    <p><span style="font-weight: 400;">SENTE-SE ESTUFADO</span><span style="font-weight: 400;"> (ap&oacute;s as refei&ccedil;&otilde;es)</span></p>
    </td>
    <td style="width: 7.57587%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.72358%; text-align: center;">&nbsp;</td>
    <td style="width: 7.29177%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.3002%;">
    <p><span style="font-weight: 400;">Perda do PALADAR / Gosto?</span></p>
    </td>
    <td style="width: 7.57587%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.72358%; text-align: center;">&nbsp;</td>
    <td style="width: 7.29177%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.3002%;">
    <p><span style="font-weight: 400;">Falta de APETITE?</span></p>
    </td>
    <td style="width: 7.57587%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.72358%; text-align: center;">&nbsp;</td>
    <td style="width: 7.29177%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.3002%;">
    <p><span style="font-weight: 400;">GASES sem cheiro?</span></p>
    </td>
    <td style="width: 7.57587%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.72358%; text-align: center;">&nbsp;</td>
    <td style="width: 7.29177%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.3002%;">
    <p><span style="font-weight: 400;">Gases COM cheiro?</span></p>
    </td>
    <td style="width: 7.57587%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.72358%; text-align: center;">&nbsp;</td>
    <td style="width: 7.29177%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.3002%;">
    <p><span style="font-weight: 400;">Pernas Pesadas?</span></p>
    </td>
    <td style="width: 7.57587%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.72358%; text-align: center;">&nbsp;</td>
    <td style="width: 7.29177%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.3002%;">
    <p><span style="font-weight: 400;">Sonol&ecirc;ncia (ap&oacute;s a refei&ccedil;&atilde;o)?</span></p>
    </td>
    <td style="width: 7.57587%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.72358%; text-align: center;">&nbsp;</td>
    <td style="width: 7.29177%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.3002%;">
    <p><span style="font-weight: 400;">Diarreia FEZES L&Iacute;QUIDAS</span></p>
    </td>
    <td style="width: 7.57587%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.72358%; text-align: center;">&nbsp;</td>
    <td style="width: 7.29177%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.3002%;">
    <p><span style="font-weight: 400;">Diarreia FEZES MOLES</span></p>
    </td>
    <td style="width: 7.57587%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.72358%; text-align: center;">&nbsp;</td>
    <td style="width: 7.29177%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.3002%;">
    <p><span style="font-weight: 400;">Diarreia ODOR MUITO MUITO FORTE?</span></p>
    </td>
    <td style="width: 7.57587%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.72358%; text-align: center;">&nbsp;</td>
    <td style="width: 7.29177%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.3002%;">
    <p><span style="font-weight: 400;">Diarreia com QUEIMA&Ccedil;&Atilde;O NO ANUS?</span></p>
    </td>
    <td style="width: 7.57587%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 7.00768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.72358%; text-align: center;">&nbsp;</td>
    <td style="width: 7.29177%; text-align: center;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="width: 99.3402%;">
    <tbody>
    <tr>
    <td style="width: 64.0994%;">
    <p><em><span style="font-weight: 400;">Sobre o seu </span></em><strong><em>Est&ocirc;mago </em></strong><em><span style="font-weight: 400;">(a energia/funcionamento da v&iacute;scera)</span></em></p>
    </td>
    <td style="width: 7.57764%; text-align: center;">
    <p><strong>Sp</strong></p>
    </td>
    <td style="width: 7.08075%; text-align: center;">
    <p><strong>Fq</strong></p>
    </td>
    <td style="width: 6.8323%; text-align: center;">
    <p><strong>Av</strong></p>
    </td>
    <td style="width: 7.32919%; text-align: center;">
    <p><strong>Rr</strong></p>
    </td>
    <td style="width: 7.08075%; text-align: center;">
    <p><strong>Nc</strong></p>
    </td>
    </tr>
    <tr>
    <td style="width: 64.0994%;">
    <p><span style="font-weight: 400;">QUEIMA&Ccedil;&Atilde;O / AZIA?</span></p>
    </td>
    <td style="width: 7.57764%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    <td style="width: 6.8323%; text-align: center;">&nbsp;</td>
    <td style="width: 7.32919%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.0994%;">
    <p><span style="font-weight: 400;">ERUCTA&Ccedil;&Atilde;O &ldquo;arroboto&rdquo; frequente?</span></p>
    </td>
    <td style="width: 7.57764%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    <td style="width: 6.8323%; text-align: center;">&nbsp;</td>
    <td style="width: 7.32919%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.0994%;">
    <p><span style="font-weight: 400;">DOR no est&ocirc;mago</span></p>
    </td>
    <td style="width: 7.57764%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    <td style="width: 6.8323%; text-align: center;">&nbsp;</td>
    <td style="width: 7.32919%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.0994%;">
    <p><span style="font-weight: 400;">Sintomas </span><span style="font-weight: 400;">MELHORAM </span><span style="font-weight: 400;">quando come?</span></p>
    </td>
    <td style="width: 7.57764%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    <td style="width: 6.8323%; text-align: center;">&nbsp;</td>
    <td style="width: 7.32919%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.0994%;">
    <p><span style="font-weight: 400;">Sintomas </span><span style="font-weight: 400;">PIORAM </span><span style="font-weight: 400;">quando come?</span></p>
    </td>
    <td style="width: 7.57764%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    <td style="width: 6.8323%; text-align: center;">&nbsp;</td>
    <td style="width: 7.32919%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.0994%;">
    <p><span style="font-weight: 400;">Possui SOLU&Ccedil;OS?</span></p>
    </td>
    <td style="width: 7.57764%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    <td style="width: 6.8323%; text-align: center;">&nbsp;</td>
    <td style="width: 7.32919%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.0994%;">
    <p><span style="font-weight: 400;">NAUSEAS / enjoo frequentes?</span></p>
    </td>
    <td style="width: 7.57764%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    <td style="width: 6.8323%; text-align: center;">&nbsp;</td>
    <td style="width: 7.32919%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.0994%;">
    <p><span style="font-weight: 400;">NAUSEAS matinais?</span></p>
    </td>
    <td style="width: 7.57764%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    <td style="width: 6.8323%; text-align: center;">&nbsp;</td>
    <td style="width: 7.32919%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.0994%;">
    <p><span style="font-weight: 400;">REFLUXO g&aacute;strico?</span></p>
    </td>
    <td style="width: 7.57764%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    <td style="width: 6.8323%; text-align: center;">&nbsp;</td>
    <td style="width: 7.32919%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.0994%;">
    <p><span style="font-weight: 400;">Digest&atilde;o Lenta?</span></p>
    </td>
    <td style="width: 7.57764%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    <td style="width: 6.8323%; text-align: center;">&nbsp;</td>
    <td style="width: 7.32919%; text-align: center;">&nbsp;</td>
    <td style="width: 7.08075%; text-align: center;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="width: 99.246%; height: 951.876px;">
    <tbody>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><em><span style="font-weight: 400;">Sobre o seu</span></em><strong><em> Pulm&atilde;o </em></strong><em><span style="font-weight: 400;">(energia/funcionamento do &oacute;rg&atilde;o)</span></em></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">
    <p><strong>Sp</strong></p>
    </td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">
    <p><strong>Fq</strong></p>
    </td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">
    <p><strong>Av</strong></p>
    </td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">
    <p><strong>Rr</strong></p>
    </td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">
    <p><strong>Nc</strong></p>
    </td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Resfriado/gripe frequente?</span></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Otite/ Amigdalite?</span></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Rinite ou Sinusite?</span></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Prurido (coceira) Nasal?</span></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Congest&atilde;o / &ldquo;entope&rdquo; Nasal?</span></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Espirros com frequ&ecirc;ncia?</span></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><span style="font-weight: 400;">O nariz ESCORRE?</span></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Coriza FLUIDA e CLARA?</span></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Coriza ESPESSA? (catarro)</span></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Catarro CLARO/BRANCO</span></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Catarro VERDE?</span></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Catarro AMARELO?</span></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Catarro c/ SANGUE?</span></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Dor na Face?</span></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Quando esses sintomas atacam, vc tem FEBRE?</span></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Asma? (falta de ar)</span></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Com chiado no Peito?</span></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Piora quando deita?</span></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3836%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Rouquid&atilde;o?</span></p>
    </td>
    <td style="width: 7.97011%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.09838%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.72478%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.84932%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.97385%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="width: 99.4345%;">
    <tbody>
    <tr>
    <td style="width: 64.347%;">
    <p><em><span style="font-weight: 400;">Sobre o seu </span></em><strong><em>Rim</em></strong><em><span style="font-weight: 400;"> (energia/funcionamento do &oacute;rg&atilde;o)</span></em></p>
    </td>
    <td style="width: 8.00763%; text-align: center;">
    <p><strong>Sp</strong></p>
    </td>
    <td style="width: 6.95901%; text-align: center;">
    <p><strong>Fq</strong></p>
    </td>
    <td style="width: 6.67302%; text-align: center;">
    <p><strong>Av</strong></p>
    </td>
    <td style="width: 6.57769%; text-align: center;">
    <p><strong>Rr</strong></p>
    </td>
    <td style="width: 7.43565%; text-align: center;">
    <p><strong>Nc</strong></p>
    </td>
    </tr>
    <tr>
    <td style="width: 64.347%;">
    <p><span style="font-weight: 400;">Dor </span><span style="font-weight: 400;">LOMBAR/JOELHOS/ P&Eacute;S</span></p>
    </td>
    <td style="width: 8.00763%; text-align: center;">&nbsp;</td>
    <td style="width: 6.95901%; text-align: center;">&nbsp;</td>
    <td style="width: 6.67302%; text-align: center;">&nbsp;</td>
    <td style="width: 6.57769%; text-align: center;">&nbsp;</td>
    <td style="width: 7.43565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.347%;">
    <p><span style="font-weight: 400;">EJACULA&Ccedil;&Atilde;O</span><span style="font-weight: 400;"> Precoce</span></p>
    </td>
    <td style="width: 8.00763%; text-align: center;">&nbsp;</td>
    <td style="width: 6.95901%; text-align: center;">&nbsp;</td>
    <td style="width: 6.67302%; text-align: center;">&nbsp;</td>
    <td style="width: 6.57769%; text-align: center;">&nbsp;</td>
    <td style="width: 7.43565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.347%;">
    <p><span style="font-weight: 400;">FRIO</span><span style="font-weight: 400;"> nas costas</span></p>
    </td>
    <td style="width: 8.00763%; text-align: center;">&nbsp;</td>
    <td style="width: 6.95901%; text-align: center;">&nbsp;</td>
    <td style="width: 6.67302%; text-align: center;">&nbsp;</td>
    <td style="width: 6.57769%; text-align: center;">&nbsp;</td>
    <td style="width: 7.43565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.347%;">
    <p><span style="font-weight: 400;">ZUMBIDO</span><span style="font-weight: 400;"> no Ouvido</span></p>
    </td>
    <td style="width: 8.00763%; text-align: center;">&nbsp;</td>
    <td style="width: 6.95901%; text-align: center;">&nbsp;</td>
    <td style="width: 6.67302%; text-align: center;">&nbsp;</td>
    <td style="width: 6.57769%; text-align: center;">&nbsp;</td>
    <td style="width: 7.43565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.347%;">
    <p><span style="font-weight: 400;">Perda Auditiva</span></p>
    </td>
    <td style="width: 8.00763%; text-align: center;">&nbsp;</td>
    <td style="width: 6.95901%; text-align: center;">&nbsp;</td>
    <td style="width: 6.67302%; text-align: center;">&nbsp;</td>
    <td style="width: 6.57769%; text-align: center;">&nbsp;</td>
    <td style="width: 7.43565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.347%;">
    <p><span style="font-weight: 400;">Sensa&ccedil;&atilde;o </span><span style="font-weight: 400;">CABE&Ccedil;A VAZIA</span></p>
    </td>
    <td style="width: 8.00763%; text-align: center;">&nbsp;</td>
    <td style="width: 6.95901%; text-align: center;">&nbsp;</td>
    <td style="width: 6.67302%; text-align: center;">&nbsp;</td>
    <td style="width: 6.57769%; text-align: center;">&nbsp;</td>
    <td style="width: 7.43565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.347%;">
    <p><span style="font-weight: 400;">Acorda a </span><span style="font-weight: 400;">NOITE</span><span style="font-weight: 400;"> para </span><span style="font-weight: 400;">URINAR</span></p>
    </td>
    <td style="width: 8.00763%; text-align: center;">&nbsp;</td>
    <td style="width: 6.95901%; text-align: center;">&nbsp;</td>
    <td style="width: 6.67302%; text-align: center;">&nbsp;</td>
    <td style="width: 6.57769%; text-align: center;">&nbsp;</td>
    <td style="width: 7.43565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.347%;">
    <p><span style="font-weight: 400;">INFERTILIDADE</span></p>
    </td>
    <td style="width: 8.00763%; text-align: center;">&nbsp;</td>
    <td style="width: 6.95901%; text-align: center;">&nbsp;</td>
    <td style="width: 6.67302%; text-align: center;">&nbsp;</td>
    <td style="width: 6.57769%; text-align: center;">&nbsp;</td>
    <td style="width: 7.43565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.347%;">
    <p><span style="font-weight: 400;">Altera&ccedil;&atilde;o </span><span style="font-weight: 400;">DESEJO SEXUAL</span><span style="font-weight: 400;">&nbsp;</span></p>
    </td>
    <td style="width: 8.00763%; text-align: center;">&nbsp;</td>
    <td style="width: 6.95901%; text-align: center;">&nbsp;</td>
    <td style="width: 6.67302%; text-align: center;">&nbsp;</td>
    <td style="width: 6.57769%; text-align: center;">&nbsp;</td>
    <td style="width: 7.43565%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.347%;">
    <p><span style="font-weight: 400;">DENTES FRACOS</span></p>
    </td>
    <td style="width: 8.00763%; text-align: center;">&nbsp;</td>
    <td style="width: 6.95901%; text-align: center;">&nbsp;</td>
    <td style="width: 6.67302%; text-align: center;">&nbsp;</td>
    <td style="width: 6.57769%; text-align: center;">&nbsp;</td>
    <td style="width: 7.43565%; text-align: center;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="width: 99.4345%; height: 475.938px;">
    <tbody>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3678%; height: 47.5938px;">
    <p><em><span style="font-weight: 400;">Sobre o seu </span></em><strong><em>F&iacute;gado </em></strong><em><span style="font-weight: 400;">(energia/funcionamento do &oacute;rg&atilde;o)</span></em></p>
    </td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">
    <p><strong>Sp</strong></p>
    </td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">
    <p><strong>Fq</strong></p>
    </td>
    <td style="width: 6.89655%; height: 47.5938px; text-align: center;">
    <p><strong>Av</strong></p>
    </td>
    <td style="width: 7.37548%; height: 47.5938px; text-align: center;">
    <p><strong>Rr</strong></p>
    </td>
    <td style="width: 6.41762%; height: 47.5938px; text-align: center;">
    <p><strong>Nc</strong></p>
    </td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3678%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Sua boca &eacute; AMARGA?</span></p>
    </td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.89655%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.37548%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.41762%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3678%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Olhos AVERMELHADOS?</span></p>
    </td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.89655%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.37548%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.41762%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3678%; height: 47.5938px;">
    <p><span style="font-weight: 400;">FORMIGAMENTOS nos membros ou no corpo?</span></p>
    </td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.89655%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.37548%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.41762%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3678%; height: 47.5938px;">
    <p><span style="font-weight: 400;">DOR embaixo das costelas?</span></p>
    </td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.89655%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.37548%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.41762%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3678%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Bocejos ou SUSPIROS frequentes?</span></p>
    </td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.89655%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.37548%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.41762%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3678%; height: 47.5938px;">
    <p><span style="font-weight: 400;">C&Acirc;IMBRAS</span><span style="font-weight: 400;">?</span></p>
    </td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.89655%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.37548%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.41762%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3678%; height: 47.5938px;">
    <p><span style="font-weight: 400;">TREMORES</span><span style="font-weight: 400;">?</span></p>
    </td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.89655%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.37548%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.41762%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3678%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Pontos Brilhantes?</span></p>
    </td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.89655%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.37548%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.41762%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    <tr style="height: 47.5938px;">
    <td style="width: 64.3678%; height: 47.5938px;">
    <p><span style="font-weight: 400;">Vista </span><span style="font-weight: 400;">EMBA&Ccedil;ADA</span><span style="font-weight: 400;">?</span></p>
    </td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.47126%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.89655%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 7.37548%; height: 47.5938px; text-align: center;">&nbsp;</td>
    <td style="width: 6.41762%; height: 47.5938px; text-align: center;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="width: 99.5287%;">
    <tbody>
    <tr>
    <td style="width: 64.7882%;">
    <p><em><span style="font-weight: 400;">Sobre a sua </span></em><strong><em>Bexiga</em></strong><em><span style="font-weight: 400;"> (energia/funcionamento da v&iacute;scera)</span></em></p>
    </td>
    <td style="width: 7.39363%; text-align: center;">
    <p><strong>Sp</strong></p>
    </td>
    <td style="width: 7.39363%; text-align: center;">
    <p><strong>Fq</strong></p>
    </td>
    <td style="width: 7.01768%; text-align: center;">
    <p><strong>Av</strong></p>
    </td>
    <td style="width: 6.76705%; text-align: center;">
    <p><strong>Rr</strong></p>
    </td>
    <td style="width: 6.51642%; text-align: center;">
    <p><strong>Nc</strong></p>
    </td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">DOR para urinar?</span></p>
    </td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.01768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.76705%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">QUEIMA&Ccedil;&Atilde;O ao urinar?</span></p>
    </td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.01768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.76705%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">DOR em baixo Ventre (abaixo do umbigo)</span></p>
    </td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.01768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.76705%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">Jato de urina ENFRAQUECIDO?</span></p>
    </td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.01768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.76705%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">ESPUMA na urina?</span></p>
    </td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.01768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.76705%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">SANGUE na URINA?</span></p>
    </td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.01768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.76705%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">Urina CONCENTRADA?</span></p>
    </td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.01768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.76705%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">Urina clara como AGUA?</span></p>
    </td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.01768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.76705%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">Urina em EXCESSO?</span></p>
    </td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.01768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.76705%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">Acorda a noite para URINAR?</span></p>
    </td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.01768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.76705%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">Tem PEDRAS nos RINS?</span></p>
    </td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.39363%; text-align: center;">&nbsp;</td>
    <td style="width: 7.01768%; text-align: center;">&nbsp;</td>
    <td style="width: 6.76705%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="width: 99.7172%;">
    <tbody>
    <tr>
    <td style="width: 64.7882%;">
    <p><em><span style="font-weight: 400;">Sobre a sua </span></em><strong><em>Menstrua&ccedil;&atilde;o</em></strong><em><span style="font-weight: 400;"> </span></em><em><span style="font-weight: 400;">(caso seja homem, passe adiante)</span></em><em><span style="font-weight: 400;">. </span></em></p>
    <p><em><span style="font-weight: 400;">Caso esteja em menopausa, responda somente </span></em><em><span style="font-weight: 400;">o cab&iacute;vel </span></em><em><span style="font-weight: 400;">para o momento.)</span></em></p>
    </td>
    <td style="width: 7.143%; text-align: center;">
    <p><strong>Sp</strong></p>
    </td>
    <td style="width: 7.64426%; text-align: center;">
    <p><strong>Fq</strong></p>
    </td>
    <td style="width: 7.143%; text-align: center;">
    <p><strong>Av</strong></p>
    </td>
    <td style="width: 6.64173%; text-align: center;">
    <p><strong>Rr</strong></p>
    </td>
    <td style="width: 6.51642%; text-align: center;">
    <p><strong>Nc</strong></p>
    </td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">Tem TPM?</span></p>
    </td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 7.64426%; text-align: center;">&nbsp;</td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 6.64173%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">Menstrua&ccedil;&atilde;o ADIANTA?</span></p>
    </td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 7.64426%; text-align: center;">&nbsp;</td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 6.64173%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">Menstrua&ccedil;&atilde;o ATRASA?</span></p>
    </td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 7.64426%; text-align: center;">&nbsp;</td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 6.64173%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">Menstrua&ccedil;&atilde;o IRREGULAR?</span></p>
    </td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 7.64426%; text-align: center;">&nbsp;</td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 6.64173%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">Fluxo AUMENTADO?</span></p>
    </td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 7.64426%; text-align: center;">&nbsp;</td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 6.64173%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">Fluxo DIMINUIDO?</span></p>
    </td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 7.64426%; text-align: center;">&nbsp;</td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 6.64173%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">MUITOS dias menstruada?</span></p>
    </td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 7.64426%; text-align: center;">&nbsp;</td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 6.64173%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">POUCOS dias menstruada?</span></p>
    </td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 7.64426%; text-align: center;">&nbsp;</td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 6.64173%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">Fluxo sangue CLARO?</span></p>
    </td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 7.64426%; text-align: center;">&nbsp;</td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 6.64173%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">Fluxo sangue ESCURO?</span></p>
    </td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 7.64426%; text-align: center;">&nbsp;</td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 6.64173%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">Tem C&Oacute;LICA menstrual ou Dor&nbsp;</span></p>
    </td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 7.64426%; text-align: center;">&nbsp;</td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 6.64173%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">CO&Aacute;GULOS &ldquo;bola de sangue&rdquo; no fluxo?</span></p>
    </td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 7.64426%; text-align: center;">&nbsp;</td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 6.64173%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">DOR DE CABE&Ccedil;A na menstrua&ccedil;&atilde;o?</span></p>
    </td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 7.64426%; text-align: center;">&nbsp;</td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 6.64173%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">Possui CORRIMENTO?</span></p>
    </td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 7.64426%; text-align: center;">&nbsp;</td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 6.64173%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">COCEIRA vaginal?</span></p>
    </td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 7.64426%; text-align: center;">&nbsp;</td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 6.64173%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">CALORES da menopausa?</span></p>
    </td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 7.64426%; text-align: center;">&nbsp;</td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 6.64173%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">SUDORESE com os calores</span></p>
    </td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 7.64426%; text-align: center;">&nbsp;</td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 6.64173%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    <tr>
    <td style="width: 64.7882%;">
    <p><span style="font-weight: 400;">INS&Ocirc;NIA com calores?</span></p>
    </td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 7.64426%; text-align: center;">&nbsp;</td>
    <td style="width: 7.143%; text-align: center;">&nbsp;</td>
    <td style="width: 6.64173%; text-align: center;">&nbsp;</td>
    <td style="width: 6.51642%; text-align: center;">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    <p><br /><br /></p> 
    <hr style="margin: 10px 0px; border: 0; border-top: 1px solid rgba(0,0,0,.1);"/>
    <div style="display: flex; flex-wrap: wrap; align-items: center; align-content: center; padding: 5px;">
      <div style="width: 100%; text-align: center;">
        <p style="margin: 0px;">Clínica `+business.name+`</p>
        <p style="margin: 0px;">`+business.phone+` - `+business.email+`</p>
        <p style="margin: 0px;">`+ address + `</p>
      </div>
    </div>
    `
}



module.exports = {
    templateWhite,
    templateAnamneseDefault,
};