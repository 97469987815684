//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState, useContext} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Api from '../../services/Api'
import Functions from '../../components/Functions'
import ListItemCall from '../../components/ListItemCall'
import EvaluateIris from '../../components/EvaluateIris'
import './styles.css';
import '../../App.css';
import UserContext from '../../contexts/UserContext';

export default function DashboardCalls({setLoading}) {
  const [allCalls, setAllCalls] = useState([]);
  const [calls, setCalls] = useState([]);
  const [call, setCall] = useState({});
  const [customers, setCustomers] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [openCall, setOpenCall] = useState(false);
  const [search, setSearch] = useState("");
  const [dateRange, setDateRange] = useState([new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)), new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000))]);
  const [startDate, endDate] = dateRange;
  const {user, setUser} = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  // For locale pt_BR React DatePicker
  const days = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
  const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  const locale = {
    localize: {
      day: n => days[n],
      month: n => months[n]
    },
    formatLong: {
      date: () => 'mm/dd/yyyy'
    }
  }

  // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadAllCalls();
  }, []);

  useEffect(() => {
    setLoading(true);
    loadCalls("*");
  }, [dateRange]);

  useEffect(() => {
    onSearch();
  }, [search]);

  useEffect(() => {
    if(history.location.pathname.indexOf('/calls/new') !== -1){
      newCall();
    } else if(history.location.pathname.indexOf('/calls/call') !== -1){
      if(history.location.search.indexOf('id=') !== -1){
        //console.log(new URLSearchParams(location.search).get("id"));
        loadCalls(new URLSearchParams(location.search).get("id"));
      }
    }
  }, [location]);

  async function loadAllCalls() {
    try {
      const response = await Api.get(`/get-calls-business/${user[0].business._id}`);
      console.log(response);
      if(response && response.length > 0) {
        setAllCalls(response);
      }
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  async function loadCalls(idCall) {
    try {
      var response = [];
      if(idCall === "*"){
        response = await Api.get(`/get-calls-business/${user[0].business._id}/${user[0]._id}/${startDate.toISOString().substr(0,10)}/${endDate.toISOString().substr(0,10)}`);
        console.log(response);
        if(response && response.length > 0) {
          setCalls(response);
        } else {
          setCalls([]);
        }
        loadCustomers();
      } else {
        var objCall = call;
        console.log(call)
        if(call && call?._id){
          setOpenCall(true);
        } else {
          response = await Api.get(`/get-call/${idCall}`);
          console.log(response);
          if(response && response?._id) {
            setCall(response);
            setOpenCall(true);
          } else {
            toast.error("Atendimento não encontrado!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function loadCustomers() {
    try {
      const response = await Api.get(`/get-customers-business/${user[0].business._id}`);
      setCustomers(response);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  function onSearch(){
    if(search !== ""){
      var arr = [...allCalls];
      var result = arr.filter(function(item){
        return item.customer.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 
        || item.customer.cpf.replaceAll(".", "").replaceAll("-", "").toLowerCase().indexOf(search.toLowerCase()) !== -1
        || item.dateStart.toLowerCase().indexOf(search.toLowerCase()) !== -1
        || item.dateStart.substr(0,10).split("-").reverse().join("/").toLowerCase().indexOf(search.toLowerCase()) !== -1;
      });
      setCalls(result);
    } else {
      setCalls([]);
      loadCalls("*");
    }
  }

  function onToggleSearch(){
    if(openSearch){
      setSearch("")
      setOpenSearch(!openSearch);
    } else {
      setOpenSearch(!openSearch);
    }
  }

  function newCall(){
    const callCopy = Array.from(calls);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor;
    callCopy.unshift({
      _id: callCopy.length +1,
      user: user[0],
      business: user[0].business,
      customer: { _id: "", name: "" },
      dateStart: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString(),
      iris: {type: "", irisLeft: { observations: "", images:[] }, irisRight: { observations: "", images:[] }},
      observations: "",
      active: true
    });
    setCalls(callCopy);
    //Gambiarra
    setTimeout(() => {
      if(document.getElementsByClassName("headList")[0]){
        document.getElementsByClassName("headList")[0].click();
      }
    }, 100);
    //childRef.current.getAlert(callCopy.length)*/
    //onOpenCall(callCopy[0]);
  }

  async function removeCall(id){
    setLoading(true);
    const businessCopy = Array.from(calls);
    var index = businessCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.post('/delete-call', {'id': id});
        console.log(response);
        if(Number(response.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          businessCopy.splice(index, 1);
          setCalls(businessCopy);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      businessCopy.splice(index, 1);
      setCalls(businessCopy);
    }
    setLoading(false);
  }

  function onOpenCall(item) {
    console.log(item);
    setCall(item);
    if(item._id.toString().match(/^[0-9a-fA-F]{24}$/)){
      history.push('/dashboard/calls/call?id='+item._id);
    } else {
      setOpenCall(true);
    }
  }
  return (
  <>
    {openCall ?
      <EvaluateIris 
        call={call}
        business={user[0].business}
        customers={customers}
        onClose={() => { 
          onSearch();
          setOpenCall(!openCall);
          //loadCalls("*"); 
        }}
        setLoading={setLoading}
      />
    :
      <div id="businessList" className="bodyDashboard">
        <br />
          <div className="v-center">
            {openSearch ? 
              <div className="col-md-8 col-7 order-1 order-md-1">
                <input
                  autoFocus
                  type="text" 
                  className="form-control" 
                  placeholder="Pesquise pelo nome ou CPF..."
                  style={{height: "auto", borderRadius: 30, padding: 15}}
                  value={search}
                  onChange={event => setSearch(event.target.value)}
                />
                <i className="fa fa-search fa-lg iconSearchInput"></i>
              </div>
            : <>
              <div className="col-md-4 col-7 order-1 order-md-1">
                <span className="title">Atendimentos</span>
              </div>
              <div className="col-md-4 col-12 text-center order-3 order-md-2">
                <DatePicker 
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  className="form-control text-center titleDate"
                  dateFormat={'dd/MM'}
                  locale={locale}
                  //customInput={({ value, onClick }, ref) => <button className="titleDate"onClick={onClick} ref={ref}>{value}</button>}
                  withPortal 
                />
                <span className={`fas fa-calendar-week fa-lg iconDate`}></span>
                <span onClick={()=> loadCalls("*")} className={`fas fa-sync-alt fa-lg iconRefresh`}></span>
              </div>
            </>  }
            <div className="col-md-4 col-5 text-right order-2 order-md-3">
              <button type="button" onClick={() => onToggleSearch() } className="btnNew" style={{marginRight: 8}}><i className={openSearch ? "fa fa-times fa-lg" : "fa fa-search fa-lg"}></i></button>
              <button type="button" onClick={() => history.push('/dashboard/calls/new')} id="btnNew" className="btnNew"><i className="fa fa-plus fa-lg"></i></button>
            </div>
          </div>
          <div className="col-12">
            <ul className="dashboardList">
            {
              calls.length > 0 ?
                calls.map(item => (
                  <ListItemCall
                    item={item}
                    key={item._id}
                    user={user[0]}
                    business={user[0].business}
                    _id={item._id}
                    customer={item?.customer ? item.customer : { _id: "", name: "" }}
                    customers={customers}
                    modality={item?.modality ? item.modality : "Presencial"} 
                    dateStart={item?.dateStart ? item.dateStart : ""} 
                    //hourStart={item?.dateStart ? item.dateStart.substr(11,5) : ""} 
                    iris={item?.iris ? item.iris : {type: "", irisLeft: { observations: "", images:[] }, irisRight: { observations: "", images:[] }}} 
                    observations={item?.observations ? item.observations : ""} 
                    active={item.active} 
                    openCall={(itemEdited) => onOpenCall(itemEdited)}
                    //onDelete={() => removeCall(item._id)}
                    loadItem={() => loadCalls("*")}
                    setLoading={setLoading}
                  />
                )) 
              :
                <div className="col-12 text-center">
                  <br /><br /><br />
                  {search !== "" ?
                    <span className="labelFilter">Atendimento não encontrado!</span>
                  :
                    <span className="labelFilter">Não há atendimentos no período selecionado!</span>
                  }
                </div>
              }
            </ul>
        </div>
      </div>
    }
  </>)
}