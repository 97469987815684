import React, { useEffect, useState, useMemo } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Functions from './Functions'
import { toast } from 'react-toastify';
import Api from '../services/Api'
import camera from '../assets/icons/camera.svg';

const ListItemCall = (props) => {
  //{ id, user, item, customer, description, CNPJ, state, city, address, razaoSocial, keyPIX, plan, plans, active, onDelete, loadItem, setEditCall, props.setLoading }
  //console.log(props.dateStart);
  const [toggleItem, setToggleItem] = useState([]);
  const [hoursAvailable, setHoursAvailable] = useState([]);
  const [formState, setFormState] = useState({
    business: props.business,
    customer: props.customer,
    user: props.user,
    modality: props.modality,
    dateStart: new Date(props.dateStart),
    hourStart: props.dateStart.substr(11, 5),
    iris: props.iris,
    observations: props.observations,
    active: props.active
  });

  // Executa assim q a página é aberta;
  useEffect(() => {
    //console.log(props)
    if(!props._id.toString().match(/^[0-9a-fA-F]{24}$/)){
      getAvailableTimes(new Date(props.dateStart));
    }
  }, []);

  function onChangeDate(date) {
    //setFormState({...formState, dateStart: date});
    getAvailableTimes(date);
  }

  async function getAvailableTimes(date, setHour) {
    props.setLoading(true);
    const response = await Api.get(`/get-available-times/${props.business._id}/${formState.user._id}/${date.toISOString().substr(0,10)}`);
    //console.log(response);
    if(response){
      if(response?.message){
        setHoursAvailable([]);
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else if(response.length > 0){
        //console.log(response)
        setHoursAvailable(response);
        setFormState({...formState, dateStart: date, hourStart: response[0].hour});
      } else {
        setHoursAvailable([]);
      }
    } else {
      setHoursAvailable([]);
    }
    props.setLoading(false);
  }

  function onChangeCustomer(e){
    console.log(formState);
    setFormState({...formState, customer: {_id: e.target.value.indexOf("-") !== -1 ? e.target.value.split("-")[0] : "", name: e.target.value.indexOf("-") !== -1 ? e.target.value.split("-")[1] : e.target.value } })
  }

  async function openCall(){
    var item = props.item;
    //arr._id = props._id;
    item.dateStart = formState.dateStart.toISOString().substr(0,11) + formState.hourStart;
    item.modality = formState.modality;
    item.hourStart = formState.hourStart;
    //console.log(item);
    props.openCall(item);
  }
  
  return (
      <li className={"itemDashboardList dashboardEditCall"} >
        <div className="row v-center headList" onClick={() => {toggleItem[0] !== props._id ? setToggleItem([props._id]) : setToggleItem([])}}>
          <div className="col-6 col-md-8">
            <span>{props.dateStart.substr(5, 5).split("-").reverse().join("/")} às {props.dateStart.substr(11,5)}{formState.customer?.name ? " - "+ formState.customer.name : ''}</span>
          </div>
          <div className="col-6 col-md-4 text-right">
            <span style={{marginRight: 5, fontSize: 12, textTransform: "uppercase", color: "dimgrey"}}>{props.modality}</span>
            <span className={`iconItem ${toggleItem[0] === props._id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
          </div>
        </div>
        {toggleItem[0] === props._id ?
          <>
            <hr/>
            <form>
              <div className="row v-center">
                <div className="col-12">
                  <div className="row">
                    {/*<div className="form-group col-md-8 col-12">
                      <label className="labelInput">Cliente: </label>
                      <input
                        disabled={"disabled"} 
                        type="text" 
                        className="form-control" 
                        placeholder="Nome do paciente"
                        defaultValue={formState.customer.name}
                        //onChange={event => setFormState({...formState, name: event.target.value}) }
                      />
                      {/*<input
                        required 
                        type="text" 
                        list="data"
                        className="form-control" 
                        placeholder="Selecione o cliente"
                        value={formState.customer?.name ? formState.customer.name : ''}
                        onChange={event => { onChangeCustomer(event) }}
                      />
                      <datalist id="data">
                        {props.customers.map((item, key) =>
                          <option id={item._id} key={key} value={item._id+"-"+item.name}>{item.name} - {item.cpf}</option>
                        )}
                      </datalist>
                      {formState.customer._id === "" ?
                        <div style={{marginLeft: 5}}>
                          <span>* Novo Cliente! (Será criado automaticamente um cadastro para este cliente);</span>
                        </div>
                      :<></>}
                    </div>
                    <div className="form-group col-md-4 col-12">
                      <label className="labelInput">Data: </label>
                      <input
                        disabled="disabled"
                        type="text" 
                        className="form-control" 
                        placeholder="Data da avalição"
                        value={formState.dateStart.substr(0,10).split("-").reverse().join("/") + " às " + formState.dateStart.substr(11,5) + "h"}
                      />
                    </div>*/}
                    <div className="col-md-4 col-12 form-group">
                      <label className="labelInput">Data: *</label>
                      <DatePicker 
                        disabled={props._id.toString().match(/^[0-9a-fA-F]{24}$/) ? "disabled" : ""} 
                        className="form-control"
                        selected={formState.dateStart} 
                        onChange={(date) => onChangeDate(date)} 
                        minDate={new Date()}
                        //maxDate={moment().add(180, "days")}
                        dateFormat={'dd/MM/yyyy'}
                        locale={Functions.getLocaleDatePicker()}
                      />
                    </div>
                    <div className="col-md-4 col-12 form-group">
                      <label className="labelInput">Horário: *</label>
                      <select className="form-control" value={formState.hourStart} onChange={event => setFormState({...formState, hourStart: event.target.value})} disabled={props._id.toString().match(/^[0-9a-fA-F]{24}$/) ? "disabled" : ""}>
                        {props._id.toString().match(/^[0-9a-fA-F]{24}$/) ?
                          <option value={formState.hourStart}>{formState.hourStart}</option>
                        : <>
                          {hoursAvailable.map((el) => (
                            <option key={el.id} value={el.hour}>{el.hour}</option>
                          ))}
                        </> }
                      </select>
                    </div>
                    <div className="form-group col-md-4 col-12">
                      <label className="labelInput">Modalidade: </label>
                      <select className="form-control" value={formState.modality} onChange={event => setFormState({...formState, modality: event.target.value})} disabled={props._id.toString().match(/^[0-9a-fA-F]{24}$/) ? "disabled" : ""}>
                        <option value="Presencial">Presencial</option>
                        <option value="Online">Online</option>
                      </select>
                    </div>
                    {props._id.toString().match(/^[0-9a-fA-F]{24}$/) ?
                      <div className="col-12">
                        <div className="row">
                          <div className="form-group col-12">
                            <label className="labelInput">Observações: </label>
                            <textarea 
                              disabled={props._id.toString().match(/^[0-9a-fA-F]{24}$/) ? "disabled" : ""}
                              className="form-control" 
                              rows="3" 
                              value={formState.observations}
                              onChange={event => setFormState({...formState, observations: event.target.value}) }
                            />
                          </div>
                        </div>
                      </div>
                    : <></> }
                  </div>     
                </div>     
                <div className="col-12 text-right">
                  {props._id.toString().match(/^[0-9a-fA-F]{24}$/) ?
                    <button type="button" className="btn btn-default" onClick={() => openCall()}> Ver Atendimento </button>
                  : 
                    <button type="button" className="btn btn-default" onClick={() => openCall()}> Iniciar Atendimento </button>
                  }
                  {/*<span>&nbsp;&nbsp;&nbsp;</span>
                  <button type="button" className="btn btn-delete" onClick={onDelete}> Excluir </button>
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  <button type="submit" className="btn btn-default"> Salvar </button>*/}
                </div>
              </div>
            </form>
          </>
        : '' }
      </li>
  );
};

export default ListItemCall;
