//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState, useContext} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import Functions from '../../components/Functions'
import iconBusiness from '../../assets/icons/business.png';
import ListItemBusiness from '../../components/ListItemBusiness'
import ListItemUser from '../../components/ListItemUser';
import iconProfileWhite from '../../assets/icons/icon-profile-white.png';
import './styles.css';
import '../../App.css';
import UserContext from '../../contexts/UserContext';

export default function DashboardBusiness(props) {
  const [business, setBusiness] = useState({});
  const [editBusiness, setEditBusiness] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [plans, setPlans] = useState([]);
  const {user, setUser} = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();

  // Executa assim q a página é aberta;
  useEffect(() => {
    props.setLoading(true);
    loadBusiness();
    if(history.location.search.indexOf('editBusiness') !== -1){
      setTimeout(() => {
        setEditBusiness(true);
      }, 500);
    } else if(history.location.search.indexOf('editUser') !== -1){
      setTimeout(() => {
        setEditUser(true);
      }, 500);
    }
    if(!user[0].business?.name || user[0].business.name === "") {
      setEditBusiness(true);
    }
  }, []);

  async function loadBusiness() {
    try {
      const response = await Api.get(`/get-business/${user[0].business._id}`);
      console.log(response);
      setBusiness(response);
      loadPlans();
    } catch (error) {
      console.log(error)
    }
  }

  async function loadPlans() {
    try {
      const response = await Api.get(`/get-plans-active/true`);
      console.log(response);
      setPlans(response);
      props.setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div id="businessList" className="bodyDashboard" style={{paddingTop: 15}}>
      <br />
      <div className="v-center">
        <div className="col-6">
          <span className="title">Minha Clínica</span>
        </div>
        <div className="col-6 text-right">
        </div>
      </div>
      <div className="col-12">
        <div className="itemHome" style={{padding: 0, cursor: "initial", margin: "15px 0px"}}>
          {editBusiness ?
            <div className="col-12">
              <div className="text-center" style={{paddingTop: 20}}>
                <h3>Informações da Clínica</h3>  
              </div>
              <ul className="dashboardLists"> 
                <ListItemBusiness
                  key={business._id}
                  item="editBusiness"
                  id={business._id}
                  user={user[0]._id}
                  name={business?.name ? business.name : ""}
                  CNPJ={business?.CNPJ ? business.CNPJ : ""}
                  description={business?.description ? business.description : ""}
                  thumbnail={business?.thumbnail ? business.thumbnail : ""} 
                  address={business?.address && business.address?.street ? business.address : {street: "", number:"", district: "", complement: "", city: "", state: ""}} 
                  maps={business?.maps ? business.maps : { irisLeft: [], irisRight: [] }} 
                  phone={business?.phone ? business.phone : ""} 
                  email={business?.email ? business.email : ""}
                  openingHours={business?.openingHours ? business.openingHours : []}
                  schedulingTime={business?.schedulingTime ? business.schedulingTime : 59}
                  //cities={cities}
                  //whatsapp={business.whatsapp} 
                  //instagram={business.instagram} 
                  //facebook={business.facebook} 
                  //website={business.website} 
                  active={business.active} 
                  //onDelete={() => removeBusiness(business._id)}
                  setEditBusiness={(value) => setEditBusiness(value)}
                  loadItem={() => loadBusiness()}
                  setLoading={props.setLoading}
                  //ref={childRef}
                />
              </ul>
            </div>
          : <>
            <div className="v-center"> 
              <div className="col-12 col-md-3 text-center"> 
                <label id="imgBusiness" style={{ backgroundImage: `url("${business?.thumbnail ? encodeURI(business.thumbnail) : iconBusiness}")` }}></label>
              </div>
              <div className="col-12 col-md-9" style={{paddingTop: 12, paddingBottom: 12}}>
                <div><b style={{fontSize: 20}}>{business?.name ? business.name : "Informe os dados da sua clínica!"}</b></div>
                <div>{business.address?.street && business.address.street !== "" ? business.address.street + ", " + business.address.number + ", " + business.address.district + " - " + business.address.city + ", " + business.address.state: ""}</div>
                <button type="button" onClick={() => setEditBusiness(!editBusiness)} className="btn btn-default" style={{marginTop: 15}}>Editar Minha Clínica</button>
              </div>
            </div>
          </>}
        </div>
      </div>
      <br />
      <div className="v-center">
        <div className="col-6">
          <span className="title">Meu Perfil</span>
        </div>
        <div className="col-6 text-right">
        </div>
      </div>
      <div className="col-12">
        <div className="itemHome" style={{padding: 0, cursor: "initial", margin: "15px 0px"}}>
          {editUser ?
            <div className="col-12">
              <div className="text-center" style={{paddingTop: 20}}>
                <h3>Informações Pessoais</h3>  
              </div>
              <ul className="dashboardLists"> 
                <ListItemUser
                  view="editUser"
                  _id={user[0]._id}
                  business={business}
                  name={user[0].name}
                  thumbnail={user[0]?.thumbnail ? user[0].thumbnail : ""} 
                  email={user[0]?.email ? user[0].email : ""}
                  phone={user[0]?.phone ? user[0].phone : ""}
                  cpf={user[0]?.cpf ? user[0].cpf : ""}
                  birthDate={user[0]?.birthDate ? user[0].birthDate : ""}
                  address={user[0]?.address ? user[0].address : {street: "", number:"", district: "", complement: "", city: "", state: ""}} 
                  active={user[0]?.active ? user[0].active : true} 
                  onChangeUser={(userSelected) => { console.log(userSelected); } }
                  setEditUser={(value) => setEditUser(value)}
                  //onDelete={() => removeUser(user[0]._id)}
                  loadItem={() => props.reloadUser()}
                  setLoading={props.setLoading}
                />
              </ul>
            </div>
          : <>
            <div className="v-center"> 
              <div className="col-12 col-md-3 text-center"> 
                <label id="imgUser" style={{ backgroundImage: `url("${user[0]?.thumbnail ? encodeURI(user[0].thumbnail) : iconProfileWhite}")` }}></label>
              </div>
              <div className="col-12 col-md-9" style={{paddingTop: 12, paddingBottom: 12}}>
                <div><b style={{fontSize: 20}}>{user[0].name}</b></div>
                <div>{user[0].email}</div>
                <div><button type="button" onClick={() => props.setModalPassword(!props.modalPassword)} className="btnLink">Alterar Senha?</button></div>
                <button type="button" onClick={() => setEditUser(!editUser)} className="btn btn-default" style={{marginTop: 15}}>Editar Meu Perfil</button>
              </div>
            </div>
          </>}
        </div>
      </div>
      <br />
      <div className="v-center">
        <div className="col-6">
          <span className="title">Meu Plano</span>
        </div>
        <div className="col-6 text-right">
        </div>
      </div>
      <div className="col-12">
        {business?.subscription ?
          <div className="itemHome" style={{cursor: "initial", margin: "15px 0px"}}>
            <div className="row">
              <div className="col-12" style={{marginBottom: 5}}>
                <span className="title">{business?.plan ? business.plan.name: ""}</span>
              </div>
              <div className="col-12 col-md-6">
                <div><span>Assinatura: </span><b>#{business.subscription.subs_key}</b></div>
                <div><span>Iniciado em: </span><b>{moment(business.subscription.subs_createdate).format("DD/MM/YYYY [às] HH:mm")}</b></div>
                <div><span>Periodicidade: </span><b>{business.plan.days} dias</b></div>
              </div>
              <div className="col-12 col-md-6"> {/*moment(business.subscription.expiration_date) >= moment(business.currentDate) ? "Ativo" : "Inativo" */}
                <div><span>status: </span><b>{business.subscription.subs_status}</b></div>
                <div><span>Válido até: </span><b>{moment(business.subscription.expiration_date).format("DD/MM/YYYY [às] HH:mm")}</b></div>
                <div><span>Próxima Cobrança: </span><b>{business.subscription.subs_status_code === 2 ? moment(business.subscription.subs_next_charge).format("DD/MM/YYYY") : " - "}</b></div>
              </div>
              <div className="col-12 col-md-6">
                <button type="button" onClick={() => {}} className="btn btn-default" style={{marginTop: 15, fontSize: 14}}>Aterar Plano</button>
              </div>
              <div className="col-12 col-md-6">
                <button type="button" onClick={() => window.open("https://compras.braip.com/?tab=assinaturas", "_blank")} className="btn btn-delete" style={{marginTop: 15, fontSize: 14}}>Gerenciar Assinatura</button>
              </div>
            </div>
          </div>  
        : <></> }
      </div>
      <br />
      {/*<div className="v-center">
        <div className="col-6">
          <span className="title">Faturas</span>
        </div>
        <div className="col-6 text-right">
        </div>
      </div>
      <div className="col-12">
        <hr />
        <p>Suas faturas aparecerão aqui...</p>
      </div>*/}
    </div>
  )
}