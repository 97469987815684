import React, { useEffect, useState, useMemo } from 'react';
import InputMask from "react-input-mask";
import DatalistInput from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';
import Functions from './Functions'
import { toast } from 'react-toastify';
import Api from '../services/Api'

const ListItemCustomer = (props) => {
  //{ id, user, view, name, description, thumbnail, CNPJ, state, city, address, razaoSocial, keyPIX, plan, plans, active, onDelete, loadItem, setEditCustomer, props.setLoading }
  const [toggleItem, setToggleItem] = useState([]);
  const [formState, setFormState] = useState({
    _id: `${props._id}`,
    name: `${props.name}`,
    birthDate: `${props.birthDate}`,
    thumbnail: `${props.thumbnail}`,
    gender: `${props.gender}`,
    bloodType: `${props.bloodType}`,
    phone: `${props.phone}`,
    cpf: `${props.cpf}`,
    email: `${props.email}`,
    height: `${props.height}`,
    weight: `${props.weight}`,
    address: props.address,
    observations: `${props.observations}`,
    active: `${props.active}`
  });

  // Executa assim q a página é aberta;
  useEffect(() => {
    console.log(props.observations);
    if(props.view === "EvaluateIris") {
      props.setCanEditCustomer(false);
    }
  }, []);

  const allCustumers = useMemo(() =>
    props?.customers && props.customers.map((item) => ({
        value: item.name,
        id: item._id,
        ...item,
      })),
    [props?.customers]
  );

  // useMemo: executa quando determinado estado for alterado, neste caso o estado 'formState.thumbnail'
  /*const preview = useMemo(() => {
    if(props.thumbnail !== "" && props.thumbnail === formState.thumbnail){
      return props.thumbnail;
    } else {
      return formState.thumbnail ? URL.createObjectURL(formState.thumbnail) : null;
    }
  }, [formState.thumbnail]);*/
  
  function onChangeThumbnailFile(e){
    if(e.target.files[0]){
      let file_size = e.target.files[0].size;
      //let file_name = e.target.files[0].name;
      //let file_type = e.target.files[0].type;
      //console.log('executou o file change: ' +  file_size);
      //Se maior que um 1mb(1048576)
      if(parseInt(file_size) >= 5240000) {
        toast.error("Imagem não suportada! A imagem deve ter no máximo 5MB", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }else{
        setStateThumbnail(e.target.files[0])
      }
    }
  };

  function onSelectCustomer(customerSelected){
    const data = {
      ...formState, 
      _id: customerSelected._id,
      name: customerSelected.name,
      birthDate: customerSelected.birthDate ? customerSelected.birthDate : "",
      thumbnail: customerSelected?.thumbnail ? customerSelected.thumbnail : "",
      gender: customerSelected?.gender ? customerSelected.gender : "",
      phone: customerSelected?.phone ? customerSelected.phone : "",
      cpf: customerSelected?.cpf ? customerSelected.cpf : "",
      email: customerSelected?.email ? customerSelected.email : "",
      height: customerSelected?.height ? customerSelected.height : "",
      weight: customerSelected?.weight ? customerSelected.weight : "",
      bloodType:  customerSelected?.bloodType ? customerSelected.bloodType : "",
      address: customerSelected?.address ? customerSelected.address : {street: "", number:"", district: "", complement: "", city: "", state: ""},
      observations: customerSelected?.observations ? customerSelected.observations : "",
      active: customerSelected.active,
      //id: e.target.value.indexOf("-") !== -1 ? e.target.value.split("-")[0] : "", 
      //name: e.target.value.indexOf("-") !== -1 ? e.target.value.split("-")[1] : e.target.value
    }
    setFormState(data);
    props.setCanEditCustomer(false);
    props.onChangeCustomer(data);
  }

  async function handleSubmit(e){
    e.preventDefault();
    if(formState.name !== ""){
      props.setLoading(true);
      var fileThumbnail = formState.thumbnail;
      //Cria um estrutura Multiform Data;
      const data = new FormData();
      data.append('id', formState._id);
      data.append('business', props.business);
      data.append('name', formState.name);
      data.append('birthDate', formState.birthDate);
      data.append('gender', formState.gender);
      data.append('phone', formState.phone);
      data.append('cpf', formState.cpf);
      data.append('email', formState.email);
      data.append('height', formState.height);
      data.append('weight', formState.weight);
      data.append('bloodType', formState.bloodType);
      data.append('observations', formState.observations);
      data.append('address', JSON.stringify(formState.address));
      /*if(typeof fileThumbnail === "object" && props.thumbnail !== fileThumbnail.name){
        data.append('thumbnail', props.thumbnail);
        data.append('file', fileThumbnail);
      }*/
      //data.append('active', props.active);
      try {
        const response = await Api.post("/update-customer", data);
        console.log(response);
        if(response.hasOwnProperty('_id') || response.hasOwnProperty('ok')){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          if(props.view === "EvaluateIris"){
            props.setCanEditCustomer(false);
            setFormState(response?._id ? response : formState)
            props.onChangeCustomer(response?._id ? response : formState);
          } else {
            setToggleItem([]);
            const reloadItem = props.loadItem;
            reloadItem();
          }
          props.setLoading(false);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          props.setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("(*) Preencha todos os campos obrigatórios!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }
  
  return (
      <li className={"itemDashboardList dashboardEditCustomer"} style={{listStyle: 'none'}}>
        {props.view === "DashboardCustomer" ?
          <div className="row v-center headList" onClick={() => {toggleItem[0] !== props._id ? setToggleItem([props._id]) : setToggleItem([])}}>
            <div className="col-10">
              <span>{formState.name}</span>
            </div>
            <div className="col-2 text-right">
              <span className={`iconItem ${toggleItem[0] === props._id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
            </div>
          </div>
        : <></>}
        {props.view !== "DashboardCustomer" || toggleItem[0] === props._id ?
          <>
            <hr/>
            <form onSubmit={handleSubmit}>
              <div className="row v-center">
                <div className="col-12">
                  <div className="row v-center">
                    {/*<div className="form-group col-md-4 col-12">
                      <label className="switch itemDashboardListSwitch">
                        <input type="checkbox" value={stateActive} checked={stateActive} onChange={() => setStateActive(!stateActive)} />
                        <div className="slprops.ider"></div>
                      </label>
                      <label className="labelImage">Imagem do Canal:</label>
                      <label id="thumbnailCustomer" style={{ backgroundImage: `url("${preview}")` }} className={formState.thumbnail ? 'has-thumbnail' : ''}>
                        <input type="file" onChange={onChangeThumbnailFile} />
                        <img src={camera} alt="Select img" />
                      </label>
                    </div>
                    */}
                    <div className="form-group col-md-6 col-12">
                      {props.view === "EvaluateIris" && !formState._id.toString().match(/^[0-9a-fA-F]{24}$/) ?
                      <>
                        <DatalistInput
                          required 
                          autoFocus
                          autocomplete="one-time-code"
                          placeholder="Informe ou selecione o nome do paciente"
                          label="Nome: *"
                          labelProps={{className:"labelInput"}}
                          inputProps={{className:"form-control"}}
                          onSelect={(item) => onSelectCustomer(item)}
                          items={allCustumers}
                          value={formState.name}
                          onChange={event => {
                            setFormState({...formState, name: event.target.value}); 
                            props.setCanEditCustomer(true);
                          }}
                        />
                        {/*<input
                          required 
                          autoFocus
                          //disabled={props.canEditCustomer ? "" : "disabled"}
                          type="text" 
                          list="data"
                          className="form-control" 
                          placeholder="Informe ou selecione o nome do paciente"
                          value={formState.name}
                          onChange={event => { onChangeCustomer(event) }}
                        />
                        <datalist id="data">
                          {props.customers.map((item, key) =>
                            <option id={item._id} key={key} value={JSON.stringify(item)}>{item.name} - {item.cpf}</option>
                          )}
                          </datalist>*/}
                        {/*formState.customer._id === "" ?
                          <div style={{marginLeft: 5}}>
                            <span>* Novo Paciente! (Será criado automaticamente um cadastro para este cliente);</span>
                          </div>
                          :<></>*/}
                      </>
                      :
                      <>
                        <label className="labelInput">Nome: *</label>
                        <input
                          required 
                          autoFocus
                          disabled={props.canEditCustomer ? "" : "disabled"}
                          type="text" 
                          className="form-control" 
                          placeholder="Informe o nome do paciente"
                          value={formState.name}
                          onChange={event => setFormState({...formState, name: event.target.value}) }
                        />
                      </>
                      }
                    </div>
                    <div className="form-group col-md-2 col-4">
                      <label className="labelInput">Gênero: </label>
                      <select className="form-control" disabled={props.canEditCustomer ? "" : "disabled"} value={formState.gender} onChange={event => setFormState({...formState, gender: event.target.value })} >
                        <option></option>
                        <option key={"M"}>M</option>
                        <option key={"F"}>F</option>
                      </select>
                    </div>
                    <div className="form-group col-md-4 col-8">
                      <label className="labelInput">Tipo Sanguíneo: </label>
                      <select className="form-control" disabled={props.canEditCustomer ? "" : "disabled"} value={formState.bloodType} onChange={event => setFormState({...formState, bloodType: event.target.value })} >
                        <option></option>
                        <option key={"A+"}>A+</option>
                        <option key={"A-"}>A-</option>
                        <option key={"B+"}>B+</option>
                        <option key={"B-"}>B-</option>
                        <option key={"AB+"}>AB+</option>
                        <option key={"AB-"}>AB-</option>
                        <option key={"O+"}>O+</option>
                        <option key={"O-"}>O-</option>
                      </select>
                    </div>
                    <div className="form-group col-md-4 col-12">
                      <label className="labelInput">Data Nascimento: </label>
                      <InputMask
                        disabled={props.canEditCustomer ? "" : "disabled"}
                        mask="99/99/9999" 
                        type="text" 
                        className="form-control" 
                        placeholder="Data de nascimento"
                        value={formState.birthDate}
                        onChange={event => setFormState({...formState, birthDate: event.target.value}) }
                      />
                    </div>
                    <div className="form-group col-md-4 col-6">
                      <label className="labelInput">CPF: </label>
                      <InputMask
                        disabled={props.canEditCustomer ? "" : "disabled"}
                        mask="999.999.999-99" 
                        type="text" 
                        className="form-control" 
                        placeholder="CPF"
                        //value={Functions.cpfMask(formState.cpf)}
                        value={formState.cpf}
                        onChange={event => setFormState({...formState, cpf: event.target.value}) }
                      />
                    </div>
                    <div className="form-group col-md-4 col-6">
                      <label className="labelInput">Telefone: </label>
                      <InputMask 
                        disabled={props.canEditCustomer ? "" : "disabled"}
                        mask="(99) 99999-9999" 
                        className="form-control" 
                        placeholder="Número de Telefone"
                        value={formState.phone}
                        onChange={event => setFormState({...formState, phone: event.target.value}) }
                      />
                    </div>
                    <div className="form-group col-md-4 col-6">
                      <label className="labelInput">Altura (m):</label>
                      <input
                        disabled={props.canEditCustomer ? "" : "disabled"}
                        type="number" 
                        className="form-control" 
                        placeholder="Altura do cliente"
                        value={formState.height}
                        onChange={event => setFormState({...formState, height: event.target.value}) }
                      />
                    </div>
                    <div className="form-group col-md-4 col-6">
                      <label className="labelInput">Peso (kg): </label>
                      <input
                        disabled={props.canEditCustomer ? "" : "disabled"}
                        type="number" 
                        className="form-control" 
                        placeholder="Peso do cliente"
                        value={formState.weight}
                        onChange={event => setFormState({...formState, weight: event.target.value}) }
                      />
                    </div>
                    <div className="form-group col-md-4 col-12">
                      <label className="labelInput">IMC: </label>
                      <input
                        disabled="disabled"
                        type="number" 
                        className="form-control" 
                        placeholder="IMC do cliente"
                        value={parseFloat(parseFloat(formState.weight) / (parseFloat(formState.height) * parseFloat(formState.height))).toFixed(2)}
                      />
                    </div>
                    <div className="form-group col-md-8 col-8">
                      <label className="labelInput">Logradouro: </label>
                      <input
                        disabled={props.canEditCustomer ? "" : "disabled"}
                        type="text" 
                        className="form-control" 
                        placeholder="Rua, avenida..."
                        value={formState.address.street}
                        onChange={event => setFormState({...formState, address: {...formState.address, street: event.target.value} }) }
                      />
                    </div>
                    <div className="form-group col-md-4 col-4">
                      <label className="labelInput">Número: </label>
                      <input
                        disabled={props.canEditCustomer ? "" : "disabled"}
                        type="number" 
                        className="form-control" 
                        placeholder="Número"
                        value={formState.address.number}
                        onChange={event => setFormState({...formState, address: {...formState.address, number: event.target.value} }) }
                      />
                    </div>
                    <div className="form-group col-md-6 col-12">
                      <label className="labelInput">Bairro: </label>
                      <input
                        disabled={props.canEditCustomer ? "" : "disabled"}
                        type="text" 
                        className="form-control" 
                        placeholder="Bairro"
                        value={formState.address.district}
                        onChange={event => setFormState({...formState, address: {...formState.address, district: event.target.value} }) }
                      />
                    </div>
                    <div className="form-group col-md-6 col-12">
                      <label className="labelInput">Complemento: </label>
                      <input
                        disabled={props.canEditCustomer ? "" : "disabled"}
                        type="text"
                        className="form-control" 
                        placeholder="Ap, ponto de referência..."
                        value={formState.address.complement}
                        onChange={event => setFormState({...formState, address: {...formState.address, complement: event.target.value} }) }
                      />
                    </div>
                    <div className="form-group col-md-6 col-12">
                      <label className="labelInput">Cidade: </label>
                      <input
                        disabled={props.canEditCustomer ? "" : "disabled"}
                        type="text" 
                        className="form-control" 
                        placeholder="Cidade"
                        value={formState.address.city}
                        onChange={event => setFormState({...formState, address: {...formState.address, city: event.target.value} }) }
                      />
                    </div>
                    <div className="form-group col-md-6 col-12">
                      <label className="labelInput">Estado: </label>
                      <select className="form-control" disabled={props.canEditCustomer ? "" : "disabled"} value={formState.address.state} onChange={event => setFormState({...formState, address: {...formState.address, state: event.target.value} })} >
                        <option></option>
                        {Functions.estadosBR.map((estado) => (
                          <option key={estado.uf} value={estado.uf}>{estado.nome}</option>
                        ))}
                      </select>
                    </div>
                    {/*<div className="form-group col-md-12 col-12">
                      <label className="labelInput">Meu Plano*</label>
                      <select className="form-control" value={JSON.stringify(statePlan)} style={{padding:'25px 15px 15px 10px'}} onChange={event => setStatePlan(JSON.parse(event.target.value))} disabled={transactionOpen ? "disabled" : ""}>
                        {plans.map((item, index) => (
                          <option key={index} value={JSON.stringify(item)}>{item.name} - {item.percentage}%</option>
                        ))}
                      </select>
                    </div>*/}
                    <div className="col-12">
                      <div className="row">
                        <div className="form-group col-12">
                          <label className="labelInput">Observações: </label>
                          <textarea 
                            disabled={props.canEditCustomer ? "" : "disabled"}
                            className="form-control" 
                            rows="3" 
                            value={formState.observations}
                            onChange={event => setFormState({...formState, observations: event.target.value}) }
                          />
                        </div>
                      </div>
                      {props.view === "EvaluateIris" && formState.name !== "" && !formState._id.toString().match(/^[0-9a-fA-F]{24}$/) ?
                        <div style={{marginLeft: 5}}>
                          <span style={{color: 'red'}}>* Novo Paciente! Não esqueça de salvar ao concluir o preenchimento dos dados;</span>
                        </div>
                      :<></>}
                    </div>
                  </div>     
                </div>     
                <div className="col-12 text-right">
                  {props?.onDelete && <button type="button" className="btn btn-delete" onClick={props.onDelete}> Excluir </button> }
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  {formState._id.toString().match(/^[0-9a-fA-F]{24}$/) ?
                    props.canEditCustomer ?
                      <button type="submit" className="btn btn-default"> Salvar Alterações </button>
                    : <></>
                  : 
                    <button type="submit" className="btn btn-default"> Salvar Cadastro </button>
                  }
                </div>
              </div>
            </form>
          </>
        : '' }
      </li>
  );
};

export default ListItemCustomer;
