import React, { useState, useEffect, useContext } from 'react';
import logo from '../../assets/logo-home.png';
import './styles.css';
import '../../App.css';
import Lottie from 'react-lottie';
import iconLoading from '../../assets/icons/loading.json';

function Component({ history }) {
  const [category, setCategory] = useState("");
  const [buttonVisible, setButtonVisible] = useState(true);

  //Ao abrir a tela
  useEffect(() => {
    /*setTimeout(() => {
      setButtonVisible(true);
      //history.push('/creator/dashboard');
    }, 10000);*/
  }, []);

  return (
  <>
    <div className="container">
      <div className="col-12 col-md-8 offset-md-2 bodyPage">
        <div className="text-center">
          <br/> <br/>
          <div className="col-12">
            <h1 id="logo">e-iris.</h1>  
          </div> 
          <br/>
          <h3>Veja como fotragrafar a sua íris!</h3>
          <p>Siga o passo a passo abaixo para fotragrafar a sua íris.</p>
        </div> 
        <div>
          <br/><br/>
          <p><b>1.</b> Peça a ajuda de um familiar ou de amigo para fotografar a sua íris;</p>
          <p><b>2.</b> Abra a câmera traseira do seu celular, coloque no modo "foto", aplique o zoom em 3x e deixe o "flash" ativado;</p>
          <p><b>3.</b> Aproxime a câmera e abra bem o seu olho (levante a sobrnacelha), fixe o seu olhar diretamente na câmera;</p>
          <p><b>4.</b> Ajuste o foco (para que a imagem fique nítida) e tire a foto;</p>
          <p><b>5.</b> Tire uma foto de um olho de cada vez. Você poderá anexar até 3 imagens de cada íris;</p>
          <br/>
          <p><b>Dicas Úteis:</b></p>
          <p>Se possível, tire também uma foto com o dedo e/ou polegar segurando a pálpebra aberta para obter uma visão mais completa do seu olho;</p>
          <p>Se tiver dificuldades em tirar com o "flash" ligado, utiliza uma lanterna, posicionando-a ao lado do olho;</p>
          <p>Mantenha a câmera o mais imóvel possível. Se tiver dificuldades em obter imagens nítidas, utilize um tripé e talvez diminua um pouco o zoom;</p>
          <p>Segure a câmera em uma posição em que não esteja projetando sombra no rosto;</p>
          <br/>
          <p><b>Bom Exemplos:</b></p>
          <div className="row examplesIris">
            <div className="col-12 col-sm-6 col-md-4 text-center itemExemploIris">
              <img src="https://storage.googleapis.com/e-iris-businesses/exemplos/exemplo-iris-1.JPG" />
              <p>Ótima foto, centralizada com boa iluminação</p>
            </div>
            <div className="col-12 col-sm-6 col-md-4 text-center itemExemploIris">
              <img src="https://storage.googleapis.com/e-iris-businesses/exemplos/exemplo-iris-2.JPG" />
              <p>Boa imagem, centralizada com boa nitidez</p>
            </div>
            <div className="col-12 col-sm-6 col-md-4 text-center itemExemploIris">
              <img src="https://storage.googleapis.com/e-iris-businesses/exemplos/exemplo-iris-3.JPG" />
              <p>Boa imagem, nítida e com boa iluminação</p>
            </div>
          </div>
          <p><b>Exemplos Ruins:</b></p>
          <div className="row examplesIris">
            <div className="col-12 col-sm-6 col-md-4 text-center itemExemploIris">
              <img src="https://storage.googleapis.com/e-iris-businesses/exemplos/exemplo-iris-4.JPG" />
              <p>Imagem ruim, nítida porém com muito reflexo</p>
            </div>
            <div className="col-12 col-sm-6 col-md-4 text-center itemExemploIris">
              <img src="https://storage.googleapis.com/e-iris-businesses/exemplos/exemplo-iris-5.JPG" />
              <p>Imagem ruim, escura e com pouca nitidez</p>
            </div>
            <div className="col-12 col-sm-6 col-md-4 text-center itemExemploIris">
              <img src="https://storage.googleapis.com/e-iris-businesses/exemplos/exemplo-iris-6.JPG" />
              <p>Imagem ruim, íris não centralizada e com pouca nitidez</p>
            </div>
          </div>
          <br /> <br />
          <div className="row">
            <div className="col-12 text-center">
                <p style={{fontSize: 14}}>Disponibilizado por <b style={{fontSize: 20}}><a href="https://e-iris.com.br" target="_Blank">e-iris.</a></b></p>
            </div>
          </div>
        </div>
      </div>
    </div>
</>);
}

export default Component;
