import React from 'react';
import Resizer from "react-image-file-resizer";
import Api from '../services/Api'

// Convert a time in hh:mm format to minutes
function timeToMins(time) {
  var b = time.split(':');
  return b[0]*60 + +b[1];
}

// Convert minutes to a time in format hh:mm
// Returned value is in range 00  to 24 hrs
function timeFromMins(mins) {
  function z(n){return (n<10? '0':'') + n;}
  var h = (mins/60 |0) % 24;
  var m = mins % 60;
  return z(h) + ':' + z(m);
}

function addTimes(time0, time1) {
  return timeFromMins(timeToMins(time0) + timeToMins(time1));
}

function validateEmail(email){
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
    return (true)
  } else{
    return (false)
  }
}

function checkMonth(month){
  var arrMonth=new Array();
  arrMonth[1]='Jan';
  arrMonth[2]='Fev';
  arrMonth[3]='Mar';
  arrMonth[4]='Abr';
  arrMonth[5]='Mai';
  arrMonth[6]='Jun';
  arrMonth[7]='Jul';
  arrMonth[8]='Ago';
  arrMonth[9]='Set';
  arrMonth[10]='Out';
  arrMonth[11]='Nov';
  arrMonth[12]='Dez';
  return arrMonth[parseInt(month)];
}

const formatMoney = (amount) => {
  var decimalCount = 2, decimal = ",", thousands = ".";
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
};

function currencyFormatter(value) {
  if (!Number(value)) return "";
  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value / 100);
  return `${amount}`;
}

const cpfMask = value => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

function checkMessageColor(value) {
  if(value <= 10)
    return 'bg-blue';
  if(value <= 20)
    return 'bg-pink'; 
  if(value <= 50)
    return 'bg-purple';
  if(value <= 100)
    return 'bg-yellow'; 
  if(value > 100)
    return 'bg-green';
}

function isJson(str) {
  try {
    if(parseInt(str)) {
      return false;
    } else {
      JSON.parse(str);
      return true;
    }
  } catch (e) {
    return false;
  }
}

const resizeFile = (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1920, //maxWidth
      3000, //maxHeight
      "JPEG", //compressFormat	Can be either JPEG, PNG or WEBP.
      100, //quality
      0, //rotation
      (uri) => {
        resolve(uri);
      },
      "file", //outputType	Can be either base64, blob or file
      200, //minWidth
      200, //minHeight
    );
  });
}

function getLocaleDatePicker(){
  // For locale pt_BR React DatePicker
  const days = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
  const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  const locale = {
    localize: {
      day: n => days[n],
      month: n => months[n]
    },
    formatLong: {
      date: () => 'mm/dd/yyyy'
    }
  }
  return locale;
}

function sendWhatsApp(phone, text) {
  var numberFormated = phone.replace("(", "").replace(")", "").replace(" ", "").replace("-", "");
  return 'http://api.whatsapp.com/send?phone=55'+numberFormated+'&text='+encodeURIComponent(text);
}

const firebaseConfig = {
  apiKey: "AIzaSyAQ6aHmdUEVH5dcU_znepp3YYyf4h6vXJ0",
  authDomain: "e-iris.firebaseapp.com",
  projectId: "e-iris",
  storageBucket: "e-iris.appspot.com",
  messagingSenderId: "5304940782",
  appId: "1:5304940782:web:c74074d7725904c7eedbff",
  measurementId: "G-WPY20N9TKD"
};
const vapidKey="BDP7RikffkElsYyJrXS2CeaO_JUajXZPKtghQaj8Yz989yNCkTRtG46MmVwKpUFWDy64jEJOuRdL4jfOMjqk3ys";

const estadosBR = [
  { uf: 'AC', nome: 'Acre' },
  { uf: 'AL', nome: 'Alagoas' },
  { uf: 'AP', nome: 'Amapá' },
  { uf: 'AM', nome: 'Amazonas' },
  { uf: 'BA', nome: 'Bahia' },
  { uf: 'CE', nome: 'Ceará' },
  { uf: 'DF', nome: 'Distrito Federal' },
  { uf: 'ES', nome: 'Espirito Santo' },
  { uf: 'GO', nome: 'Goiás' },
  { uf: 'MA', nome: 'Maranhão' },
  { uf: 'MS', nome: 'Mato Grosso do Sul' },
  { uf: 'MT', nome: 'Mato Grosso' },
  { uf: 'MG', nome: 'Minas Gerais' },
  { uf: 'PA', nome: 'Pará' },
  { uf: 'PB', nome: 'Paraíba' },
  { uf: 'PR', nome: 'Paraná' },
  { uf: 'PE', nome: 'Pernambuco' },
  { uf: 'PI', nome: 'Piauí' },
  { uf: 'RJ', nome: 'Rio de Janeiro' },
  { uf: 'RN', nome: 'Rio Grande do Norte' },
  { uf: 'RS', nome: 'Rio Grande do Sul' },
  { uf: 'RO', nome: 'Rondônia' },
  { uf: 'RR', nome: 'Roraima' },
  { uf: 'SC', nome: 'Santa Catarina' },
  { uf: 'SP', nome: 'São Paulo' },
  { uf: 'SE', nome: 'Sergipe' },
  { uf: 'TO', nome: 'Tocantins' }
];

const Functions = {
  validateEmail,
  checkMonth,
  addTimes,
  formatMoney,
  currencyFormatter,
  checkMessageColor,
  cpfMask,
  isJson,
  resizeFile,
  getLocaleDatePicker,
  sendWhatsApp,
  estadosBR,
  firebaseConfig,
  vapidKey
}
export default Functions;
  