//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState} from 'react';
import { browserName, browserVersion } from "react-device-detect";
import { FaUsers, FaClipboardList, FaCalendarDay, FaClock, FaTools, FaNotesMedical, FaScroll, FaFileAlt, FaCloudUploadAlt, FaDesktop, FaSpinner, FaWhatsapp, FaWindows } from "react-icons/fa";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import Mailchimp from 'react-mailchimp-form'
import Modal from 'react-modal';
//import homeAnimation from '../../assets/home-animation.gif';
import {ReactComponent as IconAbout} from '../../assets/icons/about.svg';
import {ReactComponent as IconNewFile} from '../../assets/icons/new-file.svg';
import {ReactComponent as IconFiles} from '../../assets/icons/files.svg';
import {ReactComponent as IconTools} from '../../assets/icons/tools.svg';
import {ReactComponent as IconBooking} from '../../assets/icons/booking.svg';
import imgHome from '../../assets/images/mac-mobile-home.png';
import atendimentos from '../../assets/images/atendimentos.JPG';
import share from '../../assets/icons/share.png';
import money from '../../assets/icons/money.png';
import iconPremium from '../../assets/icons/premium.png';
import off40 from '../../assets/icons/40off.png';
import off20 from '../../assets/icons/20off.png';
import iconGarantia from '../../assets/icons/garantia-7-dias.png';
import lineDivider from '../../assets/icons/line-divider.png';
import lineDividerRight from '../../assets/icons/line-divider-right.png';
//import pageComments from '../../assets/page-comments.JPG';
import Lottie from 'react-lottie';
import Api from '../../services/Api'
import Loading from '../../components/Loading';
import logo from '../../assets/logo-home.png';
import './styles.css';
import '../../App.css';

Modal.setAppElement(document.getElementById('root'));

export default function Index({ history }) {
  const [loading, setLoading] = useState(true);
  const [userLogado, setUserLogado] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [planModality, setPlanModality] = useState("Individual");
  const [modalNewsletter, setModalNewsletter] = useState(false);

  /*const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: homeAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };*/
  const customStylesModal = {
    overlay: {
      background: 'rgba(0,0,0,0.5)',
      zIndex: 999
    }, 
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '95%',
      maxWidth: '550px',
      border: 0,
      padding: '15px',
      borderRadius: '15px',
      boxShadow: '0px 0px 5px -2px #bbbbbb'
    }
  };
  const imagesGallery = [
    {
      original: require('../../assets/images/pacientes.JPG'),
      thumbnail: require('../../assets/images/pacientes.JPG'),
      description: 'Gestão dos Pacientes',
    },{
      original: require('../../assets/images/atendimentos.JPG'),
      thumbnail: require('../../assets/images/atendimentos.JPG'),
      description: 'Gestão de Atendimentos',
    },{
      original: require('../../assets/images/atendimento-info.JPG'),
      thumbnail: require('../../assets/images/atendimento-info.JPG'),
      description: 'Informações do Atendimento',
    },{
      original: require('../../assets/images/anamnese.JPG'),
      thumbnail: require('../../assets/images/anamnese.JPG'),
      description: 'Anamnese do Atendimento',
    },{
      original: require('../../assets/images/atendimento-iris.JPG'),
      thumbnail: require('../../assets/images/atendimento-iris.JPG'),
      description: 'Analíses das Íris do Atendimento',
    },{
      original: require('../../assets/images/atendimento-iris2.JPG'),
      thumbnail: require('../../assets/images/atendimento-iris2.JPG'),
      description: 'Analíses das Íris do Atendimento',
    },{
      original: require('../../assets/images/atendimento-iris-fullscreen.JPG'),
      thumbnail: require('../../assets/images/atendimento-iris-fullscreen.JPG'),
      description: 'Analíses da Íris em Full Screen',
    },{
      original: require('../../assets/images/recomendacoes.JPG'),
      thumbnail: require('../../assets/images/recomendacoes.JPG'),
      description: 'Recomendações do Atendimento',
    },{
      original: require('../../assets/images/recomendacoes.JPG'),
      thumbnail: require('../../assets/images/recomendacoes.JPG'),
      description: 'Recomendações do Atendimento',
    },{
      original: require('../../assets/images/agendamento.JPG'),
      thumbnail: require('../../assets/images/agendamento.JPG'),
      description: 'Agendamento Online',
    }
  ];

  useEffect(() => {
    const onScroll = e => {
      //console.log(`${browserName} ${browserVersion}`);
      if(browserName === "Mobile Safari"){
        setScrollTop(e.target.body.scrollTop);
        //console.log(e.target.body.scrollTop)
      } else {
        setScrollTop(e.target.documentElement.scrollTop);
        //console.log(e.target.documentElement.scrollTop);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);
  
  return (
    <div id="homeIndex">
      {scrollTop < 150 ? <></> : <></>}
      {toggleMenu ?
        <div id="menu">
          <ul className="text-center">
            <li><a href="#bodyKnow" onClick={() => setToggleMenu(!toggleMenu)}>
              <span> Conheça </span>
            </a></li>
            <li><a href="#bodyHowWorks" onClick={() => setToggleMenu(!toggleMenu)}>
              <span> Recursos </span>
            </a></li>
            <li><a href="#bodyPlans" onClick={() => setToggleMenu(!toggleMenu)}>
              <span> Planos </span>
            </a></li>
            <li><a href="/login">
              <span> Entrar </span>
            </a></li>
            <li><a href="#bodyPlans" className="btn btn-default" style={{fontSize: 25}}>
              <span> Criar Conta </span>
            </a></li>
          </ul>
        </div>
      : <></> }
      <div id="navbar" className={scrollTop > 150 ? "onScroll" : ""}>
        <a href="#" onClick={() => {setModalNewsletter(!modalNewsletter)}} className="text-center infoTop">
          <b>INSCREVA-SE</b> em nossa newsletter e <b>RECEBA</b> um <b>CUPOM</b> de <b>15% de DESCONTO</b>, aplicável em <b>QUALQUER PLANO</b>!
        </a>
        <div className="container">
          <div className="row v-center">
            <div className="col-6 col-md-4 col-lg-4 logo">
              <h1 onClick={() => window.location = './'}>e-iris.</h1>  
               {/*<a href="./">
               <img src={logo}/>
              </a>*/}
            </div>
            <div className="col-lg-8 text-right hidden-xs hidden-sm hidden-md" style={{marginTop: 5}}>
              <a href="#bodyKnow">
                <span> Conheça </span>
              </a>
              <a href="#bodyHowWorks">
                <span> Recursos </span>
              </a>
              <a href="#bodyPlans">
                <span> Planos </span>
              </a>
              <a href="/login">
                <span> Entrar </span>
              </a>
              <a href="#bodyPlans" className="btn btn-default" style={{marginTop: -5}}>
                <span> Criar Conta </span>
              </a>
            </div>
            <div className="col-6 col-md-8 text-right visible-xs visible-sm visible-md">
              <button type="button" className="btn btn-default btnMenu" onClick={() => setToggleMenu(!toggleMenu)}>
                {toggleMenu ?
                  <i className="fa fa-times fa-lg"> </i>
                : 
                  <i className="fa fa-bars fa-lg"> </i> 
                } 
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="bodyIndex v-center">
        <div className="container">
          <div className="row v-center">
            <div className="col-md-4 col-12">
              <h1>Faça suas análises de íris com uma solução completa, moderna e em nuvem!</h1>
              <p>Gerencie seus atendimentos, faça suas analises com ferramentas modernas e precisas, armazene todas imagens e informações na nuvem, sem ocupar espaço na sua máquina!</p>
              {/*<div className="hidden-xs">
                <br /><br />
                <div className="row">
                  <div className="col-md-6 col-12">
                    <a href="#bodyPlans" className="btn btn-default-outline col-12">
                      <i className="fa fa-rocket fa-lg"> </i>
                      <span style={{marginLeft: 5}}> Começar Agora </span>
                    </a>
                  </div>
                </div>
              </div>*/}
            </div>
            <div className="col-md-8 col-12 text-center">
              <div id="divVideo" className="embed-responsive embed-responsive-16by9">
                <iframe className="embed-responsive-item" width="100%" height="100%" src="https://www.youtube.com/embed/BGDGZJzuNx8?rel=0&amp;showinfo=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
              {/*<br /><br />
              <a href="#bodyPlans" className="btn btn-default-outline col-12 col-md-8">
                <i className="fa fa-rocket fa-lg"> </i>
                <span style={{marginLeft: 5}}> Começar Agora </span>
              </a>
              <div className="homeAnimation">
                <img src={imgHome} width="100%"/>
                  <Lottie options={defaultOptions}
                />
              </div>*/}
            </div>
            {/*<div className="col-12 text-center">
              <div className="visible-xs">
                <br /><br />
                <a href="#bodyPlans" target="_Blank" className="btn btn-default-outline col-12">
                  <i className="fa fa-rocket fa-lg"> </i>
                  <span style={{marginLeft: 5}}> Começar Agora </span>
                </a>
                <br /><br /><br />
              </div>
            </div>*/}
          </div>
          {/*<div style={{ backgroundImage: `url("${lineDivider}")` }} className="lineDivider"></div>*/}
        </div>
      </div>
      <div className="divider">
        <div className="container">
          <div className="row v-center">
            <div className="col-12 col-md-8 text-left">
              <h3>Escolha um plano e inicie gratuitamente!</h3>  
            </div>
            <div className="col-12 col-md-4 text-center">
              <a href="#bodyPlans" className="btn btn-default-outline col-12" style={{marginTop: 10, marginBottom: 10}}>
                <span> Escolher Plano </span>
              </a>  
            </div>
          </div>
        </div>
      </div>
      <div id="bodyKnow">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Conheça!</h1>
              <span>Veja o que podemos lhe oferecer!</span>  
              <br /><br /><br />
            </div>
          </div>
          <div className="row v-center" style={{marginBottom: 30}}>
            <div className="col-12 col-md-7 order-2 order-md-1 text-center">
              <h4 style={{marginTop: 15, marginBottom: 20}}>Afinal, o que é o e-iris?</h4>
              <p>O e-iris é uma plataforma web para iridólogos que disponibiliza ferramentas modernas para a realização de avaliações completas de análises de íris!</p>
              <p>Buscamos oferecer uma solução simples e robusta, que possa colocar a sua disposição as melhores tecnologias do mercado, para lhe auxiliar da melhor maneira no seu dia a dia! </p>
              <p>Confira na sequência tudo o que o e-iris pode oferecer a você e a sua clínica! </p>
              {/*<div className="itemKnow v-center">
                <div className="col-2 text-center" >
                  <i className="fa fa-comments fa-2x"> </i>
                </div>
                <div className="col-10 text-left">
                  <p className="title">Página exclusiva para os comentários!</p>
                  <span>Centralize todos os comentários pagos em um só lugar!</span>  
                </div>
              </div>*/}
            </div>
            <div className="col-12 col-md-5 order-1 order-md-2 text-center">
              <IconAbout width="100%" style={{padding: 30, height: "auto", transform: "rotateY(180deg)"}}/>
            </div>
          </div>
          <div className="row v-center" style={{marginBottom: 30}}>
            <div className="col-12 col-md-5 order-1 order-md-1 text-center">
              {/*<img src={require('../../assets/icons/files.png')} width="90%"/>*/}
              <IconFiles width="100%" style={{padding: 30, height: "auto"}}/>
            </div>
            <div className="col-12 col-md-7 order-2 order-md-2 text-center">
              <h4 style={{marginTop: 15, marginBottom: 20}}>Organização e Praticidade!</h4>
              <p>Tenha o prontuário de seus pacientes em um único lugar, atualizados e disponíveis a qualquer momento!</p>
              <p>Organize seus atendimentos, tenha acesso a todo o histórico de atendimentos, realize comparativos e acompanhe a evolução do seu paciente!</p>
              <p>Foque apenas em suas análises o restante deixa conosco! </p>
            </div>
          </div>
          <div className="row v-center" style={{marginBottom: 30}}>
            <div className="col-12 col-md-7 order-2 order-md-1 text-center">
              <h4 style={{marginTop: 15, marginBottom: 20}}>Anamnese e Recomendações Terapêuticas</h4>
              <p>Adicione seus formulários ou utilize os modelos padrões, salve novos modelos personalizados e torne a inserção dessas informações mais rápida e direta!</p>
              <p>Insira, imprima e compartilhe os pdf's gerados com agilidade e facilidade! </p>
              <p>Torne estes processos exaustivos em algo efíciente, prático e otimizado! </p>
            </div>
            <div className="col-12 col-md-5 order-1 order-md-2 text-center">
              <IconNewFile width="100%" style={{padding: 30, height: "auto"}}/>
            </div>
          </div>
          <div className="row v-center" style={{marginBottom: 30}}>
            <div className="col-12 col-md-5 order-1 order-md-1 text-center">
              <IconTools width="100%" style={{padding: 30, height: "auto"}}/>
            </div>
            <div className="col-12 col-md-7 order-2 order-md-2 text-center">
              <h4 style={{marginTop: 15, marginBottom: 20}}>Ferramentas para Análises</h4>
              <p>Faça suas análises de íris com o auxilio de ferramentas modernas e intuitivas! </p>
              <p>Adicione as imagens de cada íris e ajuste a visualização com ferramentas de proporção e ampliação (zoom)!</p>
              <p>Adicone anotação em cada uma das íris e utilize o modo Full Screen para aprimorar a visualização em suas análises!</p>
              <p>Adicione seus mapas de sobreposição e alinhe-os de acordo com cada íris. Além disso, você pode alterar a coloração e a opacidade de cada mapa, facilitando a análise em diferentes íris!</p>
            </div>
          </div>
          <div className="row v-center">
            <div className="col-12 col-md-7 order-2 order-md-1 text-center">
              <h4 style={{marginTop: 15, marginBottom: 20}}>Agendamento de Atendimentos</h4>
              <p>Possibilite que seus pacientes efetuem agendamentos online para atendimentos online e presencial!</p>
              <p>Defina seus horários de atendimento e tenha uma agenda organizada! </p>
              <p>Na modalidade de agendamento online, forneça aos seus pacientes um meio prático para o envio das informações e das imagens da íris! </p>
            </div>
            <div className="col-12 col-md-5 order-1 order-md-2 text-center">
              <IconBooking width="100%" style={{padding: 30, height: "auto"}}/>
            </div>
          </div>
        </div>
      </div>
      <div className="divider">
        <div className="container">
          <div className="row v-center">
            <div className="col-12 col-md-8 text-left">
              <h3>Que tal começar agora mesmo? </h3>  
            </div>
            <div className="col-12 col-md-4 text-center">
              <a href="#bodyPlans" className="btn btn-default-outline col-12" style={{marginTop: 10, marginBottom: 10}}>
                <span> Ver Planos </span>
              </a>  
            </div>
          </div>
        </div>
      </div>
      <div id="bodyGalery">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Galeria</h1>
              <span>Confira algumas algumas imagens da platafoma!</span>  
              <br /><br /><br /><br />
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <ImageGallery 
                items={imagesGallery} 
                //useBrowserFullscreen={false} 
                //showFullscreenButton
                showPlayButton={false}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="bodyHowWorks">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Recursos</h1>
              <span>Confira o que o e-iris pode lhe oferecer!</span>  
              <br /><br /><br />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-center">
              <div className="itemHowWorks">
                <FaUsers size="6em" className="iconItemHowWorks"/>
                <p className="title">Prontuário de Pacientes</p>
                <span>Tenha as informações de seus pacientes em um único lugar, atualizados e disponíveis a qualquer momento</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-center">
              <div className="itemHowWorks">
                <FaClipboardList size="6em" className="iconItemHowWorks"/>
                <p className="title">Gestão dos Atendimentos</p>
                <span>Organize seus atendimentos, tenha acesso à todo o histórico de atendimento dos seus pacientes</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-center">
              <div className="itemHowWorks">
                <FaCalendarDay size="6em" className="iconItemHowWorks"/>
                <p className="title">Agendamento Online</p>
                <span>Possibilite que seus pacientes efetuem agendamentos online para atendimentos online e presencial!</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-center">
              <div className="itemHowWorks">
                <FaTools size="6em" className="iconItemHowWorks"/>
                <p className="title">Ferramentas para Análises</p>
                <span>Faça suas análises de íris com o auxilio de ferramentas como: sobreposição de mapas e alinhamentos proporcionais</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-center">
              <div className="itemHowWorks">
                <FaNotesMedical size="6em" className="iconItemHowWorks"/>
                <p className="title">Formulários de Anamnese</p>
                <span>Adicione seus formulários de anamnese ou utilize os modelos padrões e mantenha organizados por cada atendimento realizado</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-center">
              <div className="itemHowWorks">
                <FaFileAlt size="6em" className="iconItemHowWorks"/>
                <p className="title">Recomendações Terapêuticas</p>
                <span>Insira e imprima suas recomendações terapêuticas, organizadas de acordo com cada atendimento</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-center">
              <div className="itemHowWorks">
                <FaScroll size="6em" className="iconItemHowWorks"/>
                <p className="title">Laudos</p>
                <span>Registre os laudos do seu paciente, mantenha-os atualizados e organizadas de acordo com cada atendimento realizado</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-center">
              <div className="itemHowWorks">
                <FaClock size="6em" className="iconItemHowWorks"/>
                <p className="title">Horários de Atendimento</p>
                <span>Defina seus horários de atendimento e tenha a sua agenda em um só lugar! Organizada, otimizada e sempre atualizada!</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-center">
              <div className="itemHowWorks">
                <FaCloudUploadAlt size="6em" className="iconItemHowWorks"/>
                <p className="title">Armazenamento em Nuvem</p>
                <span>Mantenha todos os dados (informações e imagens) seguros em um ambiente cloud (servidor em nuvem)</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-center">
              <div className="itemHowWorks">
                <FaDesktop size="6em" className="iconItemHowWorks"/>
                <p className="title">Acesso em Multiplos Dispositivos</p>
                <span>Tenha o acesso à todos os seus dados de qualquer lugar, à qualquer momento e em qualquer dispositivo</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-center">
              <div className="itemHowWorks">
                <FaSpinner size="6em" className="iconItemHowWorks"/>
                <p className="title">Atualizações Futuras</p>
                <span>Tenha acesso a toda e qualquer alteração, melhoria ou inclusão de funcionalidade no e-iris. Independente do plano, você terá todos os novos recusos</span>
              </div>
            </div>
          </div>
          {/* App, Agendamento, 
          <div className="col-12 text-center">
            <br /><br /><br />
            <a href="#bodyPlans" className="btn btn-default-outline col-12 col-md-4">
              <span style={{marginLeft: 5}}> Ver Planos </span>
            </a>
            <br />
          </div>*/}
          {/*<div style={{ backgroundImage: `url("${lineDivider}")` }} className="lineDivider"></div>*/}
        </div>
      </div>
      <div id="bodyPlans">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Planos!</h1>
              <span>Confira os nossos planos! Temos opções individuais e para equipes!</span>  
              <br /><br /><br />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 offset-md-3 text-center">
              <div className="divButtonSelect">
                <div className={planModality === "Individual" ? "itemButtonSelect active text-center" : "itemButtonSelect text-center" } onClick={() => setPlanModality("Individual")} > 
                  <span>Individual</span>
                </div>
                <div className={planModality === "Equipe" ? "itemButtonSelect active text-center" : "itemButtonSelect text-center" } onClick={() => setPlanModality("Equipe")}> 
                  <span>Equipe</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 text-center">
              <img src={off40} className="imgDiscount"/>
              <div className="itemPlan active text-center">
                <div className="text-center">
                  <img src={iconPremium} width="80"/>
                  <p className="title">{planModality === "Individual" ? "Plano Anual" : "Plano Anual - Equipe"}</p>
                </div>
                <hr />
                <ul className="list-unstyled text-left" style={{margin: 15}}>
                  <li><i>✔</i> Gestão de Pacientes;</li> 
                  <li><i>✔</i> Gestão de Atendimentos;</li> 
                  <li><i>✔</i> Agendamento Online;</li>
                  <li><i>✔</i> Funcionalidades de Análise da Íris;</li>
                  <li><i>✔</i> Armazenamento de Dados e Imagens na Nuvem;</li> 
                  <li><i>✔</i> Acesso em Multiplos Dispositivos;</li> 
                  <li><i>✔</i> Aplicativo Android e iOS (Em Breve);</li> 
                  <li><i>✔</i> Acesso a Atualizações Futuras</li>
                  <li><i>✔</i> {planModality === "Individual" ? "1 Usuário" : "5 Usuários"}</li>
                  <li><i>✔</i> <b>30 dias Grátis</b></li>
                  <div style={{marginTop: 10}}>
                    <span>✚ Bônus:</span>
                    <div className="text-center">
                      <a href="https://ilinks.com.br" target="_Blank">
                        <img src="https://ilinks.com.br/personalizar-texto/images/logo.png" width="80px"/>
                        </a>
                        <div>1 Ano de Acesso Premium</div>
                    </div>
                  </div>
                  <hr />
                  <li className="priceMouth">
                    <div className="text-center">
                      R$ <span className="oldPrice">{planModality === "Individual" ? "87,00" : "119,90"}</span>/mês
                    </div>
                    <div className="text-center">
                      R$<strong className="amount"><span className="big" id="valorMensal"> {planModality === "Individual" ? "47,00" : "67,00"}</span></strong>/mês 
                    </div>
                  </li>
                </ul>
                <button onClick={() => window.open(planModality === "Individual" ? "https://ev.braip.com/checkout/plargyjr/cheg0mod" : "https://ev.braip.com/checkout/pla14le8/cheg0mod", "_self")} className="btn btn-default col-12 col-md-10">
                  <span style={{marginLeft: 5}}> Obter Plano </span>
                </button>
                <div className="text-center" style={{paddingTop: 15, marginBottom: -18}}>
                  <span className="text-center obs">* Pagamento de R$ {planModality === "Individual" ? "564,00" : "804,00"} de forma anual</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 text-center">
              <img src={off20} className="imgDiscount"/>
              <div className="itemPlan text-center">
                <div className="text-center">
                  <img src={iconPremium} width="80"/>
                  <p className="title">{planModality === "Individual" ? "Plano Semestral" : "Plano Semestral - Equipe"}</p>
                </div>
                <hr />
                <ul className="list-unstyled text-left" style={{margin: 15}}>
                  <li><i>✔</i> Gestão de Pacientes;</li> 
                  <li><i>✔</i> Gestão de Atendimentos;</li> 
                  <li><i>✔</i> Agendamento Online;</li>
                  <li><i>✔</i> Funcionalidades de Análise da Íris;</li>
                  <li><i>✔</i> Armazenamento de Dados e Imagens na Nuvem;</li> 
                  <li><i>✔</i> Acesso em Multiplos Dispositivos;</li> 
                  <li><i>✔</i> Aplicativo Android e iOS (Em Breve);</li> 
                  <li><i>✔</i> Acesso a Atualizações Futuras</li>
                  <li><i>✔</i> {planModality === "Individual" ? "1 Usuário" : "5 Usuários"}</li>
                  <li><i>✔</i> <b>14 dias Grátis</b></li>
                  <div style={{marginTop: 10}}>
                    <span>✚ Bônus:</span>
                    <div className="text-center">
                      <a href="https://ilinks.com.br" target="_Blank">
                        <img src="https://ilinks.com.br/personalizar-texto/images/logo.png" width="80px"/>
                        </a>
                        <div>6 meses de Acesso Premium</div>
                    </div>
                  </div>
                  <hr />
                  <li className="priceMouth">
                    <div className="text-center">
                      R$ <span className="oldPrice">{planModality === "Individual" ? "117,00" : "159,90"}</span>/mês
                    </div>
                    <div className="text-center">
                      R$<strong className="amount"><span className="big" id="valorMensal"> {planModality === "Individual" ? "67,00" : "87,00"}</span></strong>/mês 
                    </div>
                  </li>
                </ul>
                <button onClick={() => window.open(planModality === "Individual" ? "https://ev.braip.com/checkout/plaor8j6/cheg0mod" : "https://ev.braip.com/checkout/plavv480/cheg0mod", "_self")} className="btn btn-default col-12 col-md-10">
                  <span style={{marginLeft: 5}}> Obter Plano </span>
                </button>
                <div className="text-center" style={{paddingTop: 15, marginBottom: -18}}>
                  <span className="text-center obs">* Pagamento de R$ {planModality === "Individual" ? "402,00" : "522,00"} de forma semestral</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 text-center">
              <div className="itemPlan text-center">
                <div className="text-center">
                  <img src={iconPremium} width="80"/>
                  <p className="title">{planModality === "Individual" ? "Plano Mensal" : "Plano Mensal - Equipe"}</p>
                </div>
                <hr />
                <ul className="list-unstyled text-left" style={{margin: 15}}>
                  <li><i>✔</i> Gestão de Pacientes;</li> 
                  <li><i>✔</i> Gestão de Atendimentos;</li> 
                  <li><i>✔</i> Agendamento Online;</li>
                  <li><i>✔</i> Funcionalidades de Análise da Íris;</li>
                  <li><i>✔</i> Armazenamento de Dados e Imagens na Nuvem;</li> 
                  <li><i>✔</i> Acesso em Multiplos Dispositivos;</li>
                  <li><i>✔</i> Aplicativo Android e iOS (Em Breve);</li> 
                  <li><i>✔</i> Acesso a Atualizações Futuras</li>
                  <li><i>✔</i> {planModality === "Individual" ? "1 Usuário" : "5 Usuários"}</li>
                  <li><i>✔</i> <b>7 dias Grátis</b></li>
                  <div style={{marginTop: 10}}>
                    <span>✚ Bônus:</span>
                    <div className="text-center">
                      <a href="https://ilinks.com.br" target="_Blank">
                        <img src="https://ilinks.com.br/personalizar-texto/images/logo.png" width="80px"/>
                        </a>
                        <div>30 Dias de Acesso Premium</div>
                    </div>
                  </div>
                  <hr />
                  <li className="priceMouth">
                    <div className="text-center">
                      R$ <span className="oldPrice">{planModality === "Individual" ? "149,00" : "197,00"}</span>/mês
                    </div>
                    <div className="text-center">
                      R$<strong className="amount"><span className="big" id="valorMensal"> {planModality === "Individual" ? "87,00" : "107,00"}</span></strong>/mês 
                    </div>
                  </li>
                </ul>
                <button onClick={() => window.open(planModality === "Individual" ? "https://ev.braip.com/checkout/plak9mol/cheg0mod" : "https://ev.braip.com/checkout/pla2rvq0/cheg0mod", "_self")} className="btn btn-default col-12 col-md-10">
                  <span style={{marginLeft: 5}}> Obter Plano </span>
                </button>
                <div className="text-center" style={{paddingTop: 15, marginBottom: -18}}>
                  <span className="text-center obs">* Pagamento de R$ {planModality === "Individual" ? "87,00" : "107,00"} de forma mensal</span>
                </div>
              </div>
            </div>
          </div>
          {/*<div className="row">
            <div className="col-12 text-center">
              <br />
              <h1> + Bônus!</h1>
              <span>Confira os nossos planos! Temos opções individuais e para equipes!</span>  
              <br /><br /><br />
            </div>
          </div>*/}
        </div>
      </div>
      <div id="bodyWarranty">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Garantia Incondicional de 7 Dias!</h1>
              <span>Independente do plano, você terá 7 dias para solicitar o seu dinheiro de volta!</span>  
              <br /><br />
            </div>
          </div>
          <div className="row v-center">
            <div className="col-12 col-md-3 text-center">
              <img src={iconGarantia} width="200"/>
            </div>
            <div className="col-12 col-md-6 text-center">
              <p>Ao adquirir qualquer um dos planos você tem a garantia incondicional de 7 dias para solicitar o reembolso do valor pago!</p> 
              <p>você pode solicitar o seu dinheiro de volta, por qualquer que seja o motivo!</p> 
              <p>O nosso compromisso é com a sua satisfação!</p>  
            </div>
            <div className="col-12 col-md-3 text-center hidden-xs">
              <img src={iconGarantia} width="200"/>
            </div>
          </div>
        </div>
      </div>
      <div id="footer" style={{paddingTop: 15}}>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <p>Todos os direitos reservados © <a href="https://tweezer.com.br" target="_Blank">Tweezer | Agência Digital</a></p>  
            </div>
          </div>
        </div>
      </div>

      <div className="divBtnWhatsApp">
        <button className="btnWhatsApp" onClick={() => window.open(encodeURI("https://api.whatsapp.com/send/?phone=5555997190882&text=Olá, gostaria de falar sobre o e-iris!"), "_Blank")}>
          <FaWhatsapp size="2.2em" color="#fff"/>
        </button>
      </div>

      <Modal
        isOpen={modalNewsletter}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => {setModalNewsletter(!modalNewsletter)}}
        style={customStylesModal}
        contentLabel="Enviar E-mail"
      >
        <button className="btnCloseModal" onClick={() => {setModalNewsletter(!modalNewsletter)}}>✘</button>
        <div className="form-group col-12 text-center">
          <br />
          <h4 style={{marginBottom: '1.5rem'}}><b>Inscreva-se em nossa newsletter</b></h4>
          <p>Receba AGORA em seu e-mail um CUPOM de 15% de DESCONTO aplicável em qualquer um dos nossos planos!</p>
        </div>
        <div id="formMailchimp" className="form-group col-12">
          <Mailchimp
            action='https://e-iris.us14.list-manage.com/subscribe/post?u=54a7d33b06ac8db70935b8aa6&amp;id=e78651b758'
            fields={[
              {
                name: 'FNAME',
                placeholder: 'Seu nome',
                type: 'text',
                required: true
              },
              {
                name: 'EMAIL',
                placeholder: 'Seu email',
                type: 'email',
                required: true
              }
            ]}
            messages = {
              {
                sending: "Enviando...",
                success: "Inscrição realizada com sucesso! Confire a sua caixa de entrada, seu cupom já deve estar a caminho!",
                error: "Ocorreu um erro interno inesperado.",
                empty: "Você deve informar um e-mail válido.",
                duplicate: "Este e-mail já esta cadastrado em nossa base de dados!",
                button: "Receber Cupom"
              }
            }
          />
        </div>
      </Modal>
    </div>
  );
 
}