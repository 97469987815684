//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState, useContext} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import Functions from '../../components/Functions'
import ListItemCustomer from '../../components/ListItemCustomer'
import './styles.css';
import '../../App.css';
import UserContext from '../../contexts/UserContext';

export default function DashboardCustomers({setLoading}) {
  const [allCustomers, setAllCustomers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [plans, setPlans] = useState([]);
  const {user, setUser} = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();

  // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadCustomers();
  }, []);

  useEffect(() => {
    onSearch();
  }, [search]);

  useEffect(() => {
    if(history.location.search.indexOf('add') !== -1){
      setTimeout(() => {
        addCustomer();
      }, 500);
    }
  }, [location]);

  async function loadCustomers() {
    try {
      const response = await Api.get(`/get-customers-business/${user[0].business._id}`);
      console.log(response);
      setCustomers(response);
      setAllCustomers(response);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  function onToggleSearch(){
    if(openSearch){
      setSearch("")
      setOpenSearch(!openSearch);
    } else {
      setOpenSearch(!openSearch);
    }
  }

  function onSearch(){
    var arr = [...allCustomers];
    var result = arr.filter(function(item){
      return item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 
      || item.cpf.replaceAll(".", "").replaceAll("-", "").toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });
    setCustomers(result);
  }

  function addCustomer(){
    const customerCopy = Array.from(customers);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    customerCopy.unshift({
      _id: customerCopy.length +1,
      user: user[0]._id,
      item: "Customer",
      name: "",
      birthDate: "",
      thumbnail: "",
      gender: "",
      phone: "",
      cpf: "",
      email: "",
      height: "",
      weight: "",
      bloodType: "",
      address: {street: "", number:"", district: "", complement: "", city: "", state: ""},
      observations: "",
      active: true
    });
    setCustomers(customerCopy);
    //Gambiarra
    setTimeout(() => {
      document.getElementsByClassName("headList")[0].click();
    }, 100);
    //childRef.current.getAlert(customerCopy.length)
  }

  async function removeCustomer(id){
    setLoading(true);
    const customerCopy = Array.from(customers);
    var index = customerCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.post('/delete-customer', {'id': id});
        console.log(response);
        if(Number(response.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          customerCopy.splice(index, 1);
          setCustomers(customerCopy);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      customerCopy.splice(index, 1);
      setCustomers(customerCopy);
    }
    setLoading(false);
  }

  return (
    <div id="customerList" className="bodyDashboard">
      <br />
        <div className="v-center">
          {openSearch ? 
            <div className="col-md-8 col-7">
              <input
                autoFocus
                type="text" 
                className="form-control" 
                placeholder="Pesquise pelo nome ou CPF..."
                style={{height: "auto", borderRadius: 30, padding: 15}}
                value={search}
                onChange={event => setSearch(event.target.value)}
              />
              <i className="fa fa-search fa-lg iconSearchInput"></i>
            </div>
          :
          <div className="col-md-8 col-7">
            <span className="title">Pacientes</span>
          </div>
          }
          <div className="col-md-4 col-5 text-right">
            <button type="button" onClick={() => onToggleSearch()} className="btnNew" style={{marginRight: 8}}><i className={openSearch ? "fa fa-times fa-lg" : "fa fa-search fa-lg"}></i></button>
            <button type="button" onClick={addCustomer} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>
            {/*<button type="button" onClick={addCustomer} className="btn btn-default"> Adicionar Cliente </button>*/}
          </div>
        </div>
        <div className="col-12">
          <ul className="dashboardList">
          {
            customers.length > 0 ?
            customers.map(item => (
              <ListItemCustomer
                key={item._id}
                view="DashboardCustomer"
                user={user[0]._id}
                business={user[0].business._id}
                _id={item._id}
                name={item.name}
                thumbnail={item.thumbnail} 
                email={item?.email ? item.email : ""}
                phone={item?.phone ? item.phone : ""}
                cpf={item?.cpf ? item.cpf : ""}
                birthDate={item?.birthDate ? item.birthDate : ""}
                height={item?.height ? item.height : ""}
                weight={item?.weight ? item.weight : ""}
                gender={item?.gender ? item.gender : ""}
                bloodType={item?.bloodType ? item.bloodType : ""}
                observations={item?.observations ? item.observations : ""}
                address={item?.address ? item.address : {street: "", number:"", district: "", complement: "", city: "", state: ""}} 
                active={item.active} 
                canEditCustomer={true}
                setCanEditCustomer={() => {}}
                onDelete={() => removeCustomer(item._id)}
                loadItem={() => loadCustomers()}
                setLoading={setLoading}
              />
            )) :
            <div className="col-12 text-center">
              <br /><br /><br />
              {search !== "" ?
                <span className="labelFilter">Paciente não encontrado!</span>
              :
                <span className="labelFilter">Você ainda não possui pacientes cadastrados!</span>
              }
            </div>
          }
          </ul>
      </div>
    </div>
  )
}