import axios from 'axios';

//var BASE_API = 'http://localhost:8080/api';
//var BASE_URL = 'http://localhost:8080';
var BASE_API = 'https://server-xygxjpiuea-rj.a.run.app/api';
var BASE_URL = 'https://server-xygxjpiuea-rj.a.run.app';
var URL_FILES = 'https://storage.googleapis.com/e-iris';
var URL_FILES_BUSINESS = 'https://storage.googleapis.com/e-iris-businesses';
const api = axios.create({
  baseURL: BASE_API
});

// CADASTRO E LOGIN USER
const login = async (email, pass) => {
  if(email && pass){
    try {
      const response = await api.post('/login/', {
        "email": email,
        "senha": pass
      });
      if(response){
        return response.data;
      } else {
        return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
      }
    } catch (error) {
      console.log(error);
      return {message: error.errmsg, success: false};
    }
  } else {
    return {message: "Por favor, informe seu e-mail e sua senha!", success: false};
  }
}

//GET USER
const getUser = async (user_id) => {
  try {
    const response = await api.get(`/get-users/${user_id}`);
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const getUsers = async () => {
  try {
    const response = await api.get(`/get-users`);
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const loadUserLogged = async () => {
  try {
    const userStorage = await localStorage.getItem('user') || '[]';
    console.log(userStorage);
    var user = JSON.parse(userStorage);
    if(user.length > 0){
      var response = [];
      if(user[0].hasOwnProperty("idServico") && user[0].idServico){
        response = await api.get(`/get-prestadores/${user[0]._id}`);
      } else {
        response = await api.get(`/get-users/${user[0]._id}`);
      }
      if(response){
        return response.data[0];
      } else {
        return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
      }
    } else {
      return {message: "Usuário não logado!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const updatePass = async (id, oldPass, newPass) => {
  if(oldPass && newPass){
    try {
      const response = await api.post('/update-user', {
        id: id,
        oldPass,
        newPass
      });
      if(response){
        return response.data;
      } else {
        return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
      }
    } catch (error) {
      console.log(error);
      return {message: error.errmsg, success: false};
    }
  } else {
    return {message: "Por favor, preencha todos os campos!", success: false};
  }
}
const resetPass = async (email) => {
  if(email){
    try {
      const response = await api.post('/reset-pass', {
        email
      });
      if(response){
        return response.data;
      } else {
        return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
      }
    } catch (error) {
      console.log(error);
      return {message: error.errmsg, success: false};
    }
  } else {
    return {message: "Por favor, informe o seu e-mail cadastrado!", success: false};
  }
}

const getBusiness = async (id) => {
  const response = await api.get(`/get-business/${id}`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

const getPublicBusiness = async (id) => {
  const response = await api.get(`/get-business-public/${id}`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

//Planos
const getPlanos = async () => {
  const response = await api.get(`/get-plans/`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getPlano = async (id) => {
  const response = await api.get(`/get-plans/${id}`);
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

//Notificações
const sendNotification = async (title, body, filter, token, user) => {
  const response = await api.post('/send-notification', {
    title,
    body,
    filter, 
    token,
    user
  });
  return response;
}
//Email
const sendEmail = async (formData) => {
  const response = await api.post('/send-email', formData);
  return response;
}

const loadDataAtual = async () => {
  const response = await api.get('/data-hora-atual');
  return response.data.dataHoraAtual;
}

//Métodos Universais
const post = async (route, data) => {
  try {
    const response = await api.post(route, data);
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const get = async (route) => {
  const response = await api.get(route);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

export default {
  api,
  BASE_API,
  BASE_URL,
  URL_FILES,
  URL_FILES_BUSINESS,
  login,
  loadUserLogged,
  updatePass,
  resetPass,
  getUser,
  getUsers,
  getBusiness,
  getPublicBusiness,
  getPlano,
  getPlanos,
  sendNotification,
  sendEmail,
  loadDataAtual,
  post,
  get
};